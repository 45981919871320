import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { addDays, addMonths, differenceInDays } from 'date-fns';
import { toast } from 'react-toastify';
import { ChaveDispositivo, ChaveVencimento, ClienteModel } from '../../util/Models';
import ClienteService from '../../services/ClienteService';
import LabelSp from '../../components/LabelSp';
import DropdownSp from '../../components/DropdownSp';
import InputCurrencySp from '../../components/InputCurrencySp';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';
import {
  errorHandle,
  formatDate,
  isDate,
  isScreenMobile,
  newDateOnly,
  strToDate,
} from '../../util/functions';

import ButtonSp from '../../components/ButtonSp';
import InputTextareaSp from '../../components/InputTextareaSp';
import InputTextSp from '../../components/InputTextSp';
import ChaveAdmService from '../../services/ChaveAdmService';
import ChaveService from '../../services/ChaveService';
import DispositivoService from '../../services/DispositivoService';
import { showMessage } from '../../components/MessageDialog';

function ChaveAdm(props) {
  ChaveAdm.propTypes = {
    numeroContrato: PropTypes.string,
    vencimentoChaveAtual: PropTypes.any,
    numeroDebitos: PropTypes.number,
    inativo: PropTypes.bool,
  };

  const { numeroContrato, vencimentoChaveAtual, numeroDebitos, inativo } = props;
  const [chaveVencimento, setChaveVencimento] = useState(new ChaveVencimento());
  const [descricaoModulos, setDescricaoModulos] = useState('');
  const [vencimentoOriginal, setVencimentoOriginal] = useState(new Date());
  const [chaveGerada, setChaveGerada] = useState(new ChaveVencimento());
  const [chaveDispositivo, setChaveDispositivo] = useState(new ChaveDispositivo());
  const qtdDebitos = numeroDebitos || 0;

  const prorrogarVencimento = useCallback(() => {
    if (vencimentoChaveAtual && differenceInDays(vencimentoChaveAtual, newDateOnly()) >= 0) {
      toast.error('Chave deve estar vencida para ser prorrogada');
      return;
    }

    if (differenceInDays(new Date(), vencimentoOriginal) > 5) {
      const dataProrrogar = addDays(new Date(), -5 + 2);
      setChaveVencimento({ ...chaveVencimento, vencimento: dataProrrogar });
    } else {
      const dataProrrogar = addDays(vencimentoOriginal, 2);
      setChaveVencimento({ ...chaveVencimento, vencimento: dataProrrogar });
    }
  }, [chaveVencimento, vencimentoChaveAtual, vencimentoOriginal]);

  const confirmaGerarChave = () => {
    if (qtdDebitos !== 0) {
      showMessage(
        'Confirmação',
        'Existe débitos para este cliente. Confirma os operaçao assim mesmo?',
        idx => {
          if (idx === 1) {
            gerarChave();
          }
        }
      );
    } else if (inativo) {
      showMessage('Confirmação', 'Cliente inativo. Confirma os operaçao assim mesmo?', idx => {
        if (idx === 1) {
          gerarChave();
        }
      });
    } else {
      gerarChave();
    }
  };
  const gerarChave = useCallback(async () => {
    if (!chaveVencimento.numeroContrato) {
      toast.error('Informe o contrato');
      return;
    }

    if (chaveVencimento.edicao === undefined) {
      toast.error('Informe a edição');
      return;
    }

    if (!chaveVencimento.qtdDispositivos) {
      toast.error('Informe o número de dispositivo');
      return;
    }

    if (isDate(chaveVencimento.vencimento)) {
      toast.error('Informe o vencimento');
      return;
    }

    try {
      const ret = await ChaveAdmService.chaveVencimento(chaveVencimento);
      setChaveGerada(ret);
    } catch (err) {
      errorHandle(err);
    }
  }, [chaveVencimento]);

  const gerarChaveDispositivo = useCallback(async () => {
    if (!chaveDispositivo.chaveDispositivo) {
      toast.error('Informe a chave do dispositivo');
      return;
    }

    try {
      const ret = await ChaveAdmService.contraChaveDispositivo({
        chaveDispositivo: chaveDispositivo.chaveDispositivo,
        justificativa: chaveDispositivo.justificativa,
      });
      setChaveDispositivo({ ...ret, justificativa: chaveDispositivo.justificativa });
    } catch (err) {
      errorHandle(err);
    }
  }, [chaveDispositivo.chaveDispositivo, chaveDispositivo.justificativa]);

  const salvaChaveVencimento = useCallback(async () => {
    if (!chaveGerada.numeroContrato || !chaveGerada.chaveLiberacao) {
      toast.error('Chave de liberação inválida');
      return;
    }

    if (isDate(chaveGerada.vencimento)) {
      toast.error('Informe o vencimento');
      return;
    }

    if (chaveGerada.numeroContrato === '180002') {
      toast.error('Não é permitido salvar chave para contrato demonstração');
      return;
    }

    try {
      await ChaveService.sincronizaChave(chaveGerada);
      toast.success('Processo concluído');
    } catch (err) {
      errorHandle(err);
    }
  }, [chaveGerada]);

  const salvaChaveDispositivo = useCallback(async () => {
    if (!chaveDispositivo.numeroContrato || !chaveDispositivo.md5) {
      toast.error('Chave de dispositovo e/ou contra chave inválida');
      return;
    }

    if (chaveDispositivo.numeroContrato === '180002') {
      toast.error('Não é permitido salvar chave para contrato demonstração');
      return;
    }

    try {
      await DispositivoService.sincronizaChave({
        numeroContrato: chaveDispositivo.numeroContrato,
        chaveDispositivo: chaveDispositivo.md5,
      });
      toast.success('Processo concluído');
    } catch (err) {
      errorHandle(err);
    }
  }, [chaveDispositivo.md5, chaveDispositivo.numeroContrato]);

  useEffect(() => {
    const loadAsync = async () => {
      let clienteRet = new ClienteModel();
      let chaveVencimentoRet = new ChaveVencimento();
      const mesAtual = formatDate(new Date(), 'yyyy-MM');

      if (numeroContrato) {
        try {
          setChaveGerada(new ChaveVencimento());
          clienteRet = await ClienteService.findByContrato(numeroContrato);
          const { edicao, numeroDispositivos, diaVencimento } = clienteRet;
          let vencimento = strToDate(`${mesAtual}-${diaVencimento}`);

          if (
            vencimentoChaveAtual &&
            differenceInDays(newDateOnly(), vencimentoChaveAtual) > 0
          ) {
            setVencimentoOriginal(vencimentoChaveAtual);
          } else {
            setVencimentoOriginal(vencimento);
          }

          if (vencimento.getDate() <= new Date().getDate()) {
            vencimento = addMonths(vencimento, 1);
          }

          const modulos = {};

          let descModulos = '';
          if (Array.isArray(clienteRet.modulos)) {
            clienteRet.modulos.forEach(e => {
              descModulos += `, ${e.modulo?.descricao}`;
            });
          }

          if (descModulos !== '') {
            descModulos = descModulos.substring(1);
          }
          descModulos = descModulos.trim();

          setDescricaoModulos(descModulos);

          Object.keys(clienteRet).forEach(e => {
            if (e.includes('modAdicional') || e.includes('modEscritaFiscal')) {
              modulos[e] = clienteRet[e];
            }
          });

          chaveVencimentoRet = {
            numeroContrato,
            vencimento,
            edicao,
            qtdDispositivos: numeroDispositivos,
            modulos,
          };
        } catch (error) {
          //
        }
      }

      setChaveVencimento(chaveVencimentoRet);
      // setCliente(clienteRet);
    };

    loadAsync();
  }, [numeroContrato, vencimentoChaveAtual]);

  return (
    <div className="p-grid">
      <div className="p-col-12 div-result">
        <span className="result">Chave ADM (Vencimento)</span>
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Edição</LabelSp>
        <DropdownSp
          options={[
            { label: 'Business', value: 0 },
            { label: 'Professional', value: 1 },
            { label: 'Lite', value: 2 },
            { label: 'Nfe', value: 3 },
          ]}
          required
          value={chaveVencimento.edicao}
          onChange={e => setChaveVencimento({ ...chaveVencimento, edicao: e.target.value })}
        />
      </div>

      <div className="p-col-3 p-md-2 p-lg-2 p-fluid">
        <LabelSp>Nr. Disp.</LabelSp>
        <InputCurrencySp
          digits={0}
          required
          name="qtdDispositivos"
          value={chaveVencimento.qtdDispositivos}
          onChange={(e, v) => {
            setChaveVencimento({
              ...chaveVencimento,
              qtdDispositivos: v,
            });
          }}
        />
      </div>

      <div className="p-col-5 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Vencimento</LabelSp>
        <CalendarSp
          showOnFocus={false}
          showIcon
          required
          locale={cfgPtBr}
          dateFormat="dd/mm/yy"
          value={chaveVencimento.vencimento}
          appendTo={window.body}
          onChange={e => {
            setChaveVencimento({
              ...chaveVencimento,
              vencimento: e.target.value,
            });
          }}
        />
      </div>
      <div className="p-col-4 p-md-2 p-lg-2" style={{ textAlign: 'start' }}>
        <LabelSp style={{ display: 'block' }}>&#160;</LabelSp>
        <ButtonSp
          icon="pi pi-refresh"
          label="Prorrogar"
          className="p-button-secondary buttons"
          onClick={() => prorrogarVencimento()}
        />
      </div>

      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Justificativa</LabelSp>
        <InputTextareaSp
          rows={isScreenMobile() ? 4 : 2}
          resize={false}
          value={chaveVencimento.justificativa}
          onChange={e => {
            setChaveVencimento({
              ...chaveVencimento,
              justificativa: e.target.value,
            });
          }}
        />
      </div>

      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Módulos</LabelSp>
        <InputTextareaSp
          rows={isScreenMobile() ? 8 : 4}
          resize={false}
          value={descricaoModulos || ''}
          disabled
        />
      </div>

      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <hr />
      </div>
      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Chave Gerada</LabelSp>
        <InputTextSp value={chaveGerada.chaveLiberacao || ''} disabled />
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        <ButtonSp
          className="p-button-success"
          icon="pi pi-cog"
          label="Gerar Chave"
          keyAccess="G"
          onClick={() => {
            confirmaGerarChave();
          }}
        />

        <ButtonSp
          className="p-button"
          label="Salvar no Server"
          showConfirmation
          icon="pi pi-save"
          onClick={() => {
            salvaChaveVencimento();
          }}
        />
      </div>

      <div className="p-col-12 div-result">
        <span className="result">Chave ADM (Dispositivos)</span>
      </div>

      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Chave Dispositivo</LabelSp>
        <InputTextSp
          required
          value={chaveDispositivo.chaveDispositivo || ''}
          onChange={e =>
            setChaveDispositivo({ ...chaveDispositivo, chaveDispositivo: e.target.value })
          }
        />
      </div>

      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Justificativa</LabelSp>
        <InputTextareaSp
          rows={isScreenMobile() ? 4 : 2}
          resize={false}
          value={chaveDispositivo.justificativa}
          onChange={e => {
            setChaveDispositivo({
              ...chaveDispositivo,
              justificativa: e.target.value,
            });
          }}
        />
      </div>

      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <hr />
      </div>
      <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Número Contrato</LabelSp>
        <InputTextSp value={chaveDispositivo.numeroContrato || ''} disabled />
      </div>

      <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Serial HD</LabelSp>
        <InputTextSp value={chaveDispositivo.hd || ''} disabled />
      </div>

      <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Processador</LabelSp>
        <InputTextSp value={chaveDispositivo.processador || ''} disabled />
      </div>

      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Contra chave Gerada</LabelSp>
        <InputTextSp value={chaveDispositivo.contraChaveDispositivo || ''} disabled />
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        <ButtonSp
          className="p-button-success"
          icon="pi pi-cog"
          label="Gerar Chave"
          keyAccess="G"
          onClick={() => {
            gerarChaveDispositivo();
          }}
        />

        <ButtonSp
          className="p-button"
          label="Salvar no Server"
          showConfirmation
          icon="pi pi-save"
          onClick={() => {
            salvaChaveDispositivo();
          }}
        />
      </div>
    </div>
  );
}

export default ChaveAdm;
