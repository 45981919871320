/* eslint-disable no-underscore-dangle */
import { formatFloat, isScreenMobile } from '../../util/functions';

export const optionsGraficoAtMeses = {
  // devicePixelRatio: 1,
  layout: {
    padding: {
      top: 15,
    },
  },
  plugins: {
    labels: {
      render: 'value',
      fontColor: '#013b01',
      fontStyle: 'bold',
      fontSize: 14,
    },
  },
  hover: {
    animationDuration: 1,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          callback: (_label, _index, _labels) => {
            // return `${formatFloat(label, 0)}`;
          },
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          min: 0,
          fontSize: 13,
          fontStyle: 'bold',
          fontColor: '#333333',

          callback: (_label, _index, _labels) => {
            return _label;
          },
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
  tooltips: {
    enabled: false,
    callbacks: {
      label: (tooltipItem, data) => {
        tooltipItem.yLabel = formatFloat(tooltipItem.yLabel);
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const quantidade = dataset.data[tooltipItem.index];
        const { label } = dataset;
        return `${label} (${formatFloat(quantidade, 0)})`;
      },
    },
  },
  animation: {
    duration: 500,
  },

  legend: {
    position: 'right',
    display: false,
    labels: {
      fontSize: 10,
      boxWidth: 10,
    },
  },
  title: {
    display: false,
    text: '',
    fontColor: '#333333',
    fontSize: 14,
    fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'",
  },
};

export const optionsGraficoAtendimentosRevenda = {
  layout: {
    padding: {
      left: isScreenMobile() ? 100 : 60,
      right: 50,
      bottom: 0,
    },
  },
  hover: {
    animationDuration: 0,
  },
  scales: {
    xAxes: [
      {
        beginAtZero: true,
        ticks: {
          min: 0,
          callback: (_label, _index, _labels) => {
            // return `${formatFloat(label, 0)}`;
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          fontSize: 13,
          fontStyle: 'bold',
          fontColor: '#333333',

          callback: (_label, _index, _labels) => {
            // if (!isScreenMobile()) {
            const labelMax = isScreenMobile() ? 33 : 40;
            if (_label.length > labelMax) {
              _label = `${_label.substr(0, labelMax - 3)}...`;
            }
            return _label;
            // }
          },
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
  tooltips: {
    enabled: isScreenMobile(),
    callbacks: {
      label: (tooltipItem, data) => {
        tooltipItem.yLabel = formatFloat(tooltipItem.yLabel);
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const quantidade = dataset.data[tooltipItem.index];
        return formatFloat(quantidade, 0);
      },
    },
  },
  animation: {
    duration: 500,
    onComplete(animation) {
      const chartInstance = animation.chart;
      const { ctx } = chartInstance;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillStyle = '#013b01';
      ctx.font = 'bold 14px sans-serif';

      chartInstance.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          const textWidth = ctx.measureText(data).width;
          ctx.fillText(formatFloat(data, 0), bar._model.x + textWidth, bar._model.y + 7);
        });
      });
    },
  },

  legend: {
    position: 'right',
    display: false,
    labels: {
      fontSize: 10,
      boxWidth: 10,
    },
  },
  title: {
    display: false,
    text: '',
    fontColor: '#333333',
    fontSize: 14,
    fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'",
  },
};

export const optionsGraficoAtendimentosResponsavel = {
  layout: {
    padding: {
      left: isScreenMobile() ? 60 : 50,
      right: 60,
      bottom: 0,
    },
  },

  hover: {
    animationDuration: 0,
  },
  scales: {
    xAxes: [
      {
        beginAtZero: true,
        ticks: {
          min: 0,
          callback: (_label, _index, _labels) => {
            // return `${formatFloat(label, 0)}`;
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          fontSize: 13,
          fontStyle: 'bold',
          fontColor: '#333333',

          callback: (_label, _index, _labels) => {
            return _label;
          },
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
  tooltips: {
    enabled: false,
    callbacks: {
      label: (tooltipItem, data) => {
        tooltipItem.yLabel = formatFloat(tooltipItem.yLabel);
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const quantidade = dataset.data[tooltipItem.index];
        const { label } = dataset;
        return `${label} (${formatFloat(quantidade, 0)})`;
      },
    },
  },
  animation: {
    duration: 500,
    onComplete(animation) {
      const chartInstance = animation.chart;
      const { ctx } = chartInstance;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillStyle = '#013b01';
      // ctx.font = 'bold 14px sans';
      ctx.font = 'bold 14px sans-serif';

      chartInstance.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          const textWidth = ctx.measureText(data).width;
          ctx.fillText(formatFloat(data, 0), bar._model.x + textWidth, bar._model.y + 7);
        });
      });
    },
  },
  legend: {
    position: 'right',
    display: false,
    labels: {
      fontSize: 10,
      boxWidth: 10,
    },
  },
  title: {
    display: false,
    text: '',
    fontColor: '#333333',
    fontSize: 14,
    fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'",
  },
};

export const optionsGraficoAtendimentosCliente = {
  layout: {
    padding: {
      left: isScreenMobile() ? 100 : 50,
      right: 60,
      bottom: 0,
    },
  },

  hover: {
    animationDuration: 0,
  },
  scales: {
    xAxes: [
      {
        beginAtZero: true,
        ticks: {
          min: 0,
          callback: (_label, _index, _labels) => {
            // return `${formatFloat(label, 0)}`;
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          fontSize: isScreenMobile() ? 11 : 12,
          fontStyle: 'bold',
          fontColor: '#333333',

          callback: (_label, _index, _labels) => {
            return _label;
          },
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
  tooltips: {
    enabled: false,
    callbacks: {
      label: (tooltipItem, data) => {
        tooltipItem.yLabel = formatFloat(tooltipItem.yLabel);
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const quantidade = dataset.data[tooltipItem.index];
        const { label } = dataset;
        return `${label} (${formatFloat(quantidade, 0)})`;
      },
    },
  },
  animation: {
    duration: 500,
    onComplete(animation) {
      const chartInstance = animation.chart;
      const { ctx } = chartInstance;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillStyle = '#013b01';
      // ctx.font = 'bold 14px sans';
      ctx.font = 'bold 14px sans-serif';

      chartInstance.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          const textWidth = ctx.measureText(data).width;
          ctx.fillText(formatFloat(data, 0), bar._model.x + textWidth, bar._model.y + 7);
        });
      });
    },
  },
  legend: {
    position: 'right',
    display: false,
    labels: {
      fontSize: 10,
      boxWidth: 10,
    },
  },
  title: {
    display: false,
    text: '',
    fontColor: '#333333',
    fontSize: 14,
    fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'",
  },
};

export const optionsGraficoAtendimentosMunicipio = {
  layout: {
    padding: {
      left: isScreenMobile() ? 80 : 50,
      right: 60,
      bottom: 0,
    },
  },

  hover: {
    animationDuration: 0,
  },
  scales: {
    xAxes: [
      {
        beginAtZero: true,
        ticks: {
          min: 0,
          callback: (_label, _index, _labels) => {
            // return `${formatFloat(label, 0)}`;
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          fontSize: 12,
          fontStyle: 'bold',
          fontColor: '#333333',

          callback: (_label, _index, _labels) => {
            return _label;
          },
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
  tooltips: {
    enabled: false,
    callbacks: {
      label: (tooltipItem, data) => {
        tooltipItem.yLabel = formatFloat(tooltipItem.yLabel);
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const quantidade = dataset.data[tooltipItem.index];
        const { label } = dataset;
        return `${label} (${formatFloat(quantidade, 0)})`;
      },
    },
  },
  animation: {
    duration: 500,
    onComplete(animation) {
      const chartInstance = animation.chart;
      const { ctx } = chartInstance;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillStyle = '#013b01';
      // ctx.font = 'bold 14px sans';
      ctx.font = 'bold 14px sans-serif';

      chartInstance.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          const textWidth = ctx.measureText(data).width;
          ctx.fillText(formatFloat(data, 0), bar._model.x + textWidth, bar._model.y + 7);
        });
      });
    },
  },
  legend: {
    position: 'right',
    display: false,
    labels: {
      fontSize: 10,
      boxWidth: 10,
    },
  },
  title: {
    display: false,
    text: '',
    fontColor: '#333333',
    fontSize: 14,
    fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'",
  },
};

export const optionsGraficoTeleorientacoesCategoria = {
  layout: {
    padding: {
      left: 20,
    },
  },

  hover: {
    animationDuration: 0,
  },
  scales: {
    xAxes: [
      {
        beginAtZero: true,
        ticks: {
          min: 0,
          callback: (_label, _index, _labels) => {
            // return `${formatFloat(label, 0)}`;
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          fontSize: 14,
          fontStyle: 'bold',
          fontColor: '#333333',

          callback: (_label, _index, _labels) => {
            return _label;
          },
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
  tooltips: {
    enabled: false,
    callbacks: {
      label: (tooltipItem, data) => {
        tooltipItem.yLabel = formatFloat(tooltipItem.yLabel);
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const quantidade = dataset.data[tooltipItem.index];
        const { label } = dataset;
        return `${label} (${formatFloat(quantidade, 0)})`;
      },
    },
  },
  animation: {
    duration: 500,
    onComplete(animation) {
      const chartInstance = animation.chart;
      const { ctx } = chartInstance;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillStyle = '#013b01';
      // ctx.font = 'bold 14px sans';
      ctx.font = 'bold 14px sans-serif';

      chartInstance.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          const textWidth = ctx.measureText(data).width;
          ctx.fillText(formatFloat(data, 0), bar._model.x + textWidth, bar._model.y + 7);
        });
      });
    },
  },
  legend: {
    position: 'right',
    display: false,
    labels: {
      fontSize: 10,
      boxWidth: 10,
    },
  },
  title: {
    display: false,
    text: '',
    fontColor: '#333333',
    fontSize: 14,
    fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'",
  },
};
export const optionsGraficoTeleorientacoesPercCategoria = {
  layout: {
    padding: {
      left: 30,
    },
  },
  plugins: {
    labels: {
      render: 'label',
      // render: data => {
      //   const { label, value } = data;
      //   if (value >= 2) {
      //     return label;
      //   }
      // },
      fontColor: '#013b01',
      fontStyle: 'bold',
      fontSize: 13,
      position: 'outside',
    },
  },
  hover: {
    animationDuration: 0,
  },

  animation: {
    duration: 500,
  },

  tooltips: {
    enabled: true,
    callbacks: {
      label: (tooltipItem, data) => {
        tooltipItem.yLabel = formatFloat(tooltipItem.yLabel);

        const label = data.labels[tooltipItem.index];
        return label;
      },
    },
  },
  legend: {
    position: 'right',
    display: false,
    labels: {
      fontSize: 12,
      fontStyle: 'bold',
      fontColor: '#333333',
      boxWidth: 10,
    },
  },
  title: {
    display: false,
    text: '',
    fontColor: '#333333',
    fontSize: 14,
    fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'",
  },
};

export const optionsGraficoAtendimentosComunidade = {
  layout: {
    padding: {
      left: isScreenMobile() ? 140 : 190,
      bottom: 0,
    },
  },
  hover: {
    animationDuration: 0,
  },
  scales: {
    xAxes: [
      {
        beginAtZero: true,
        ticks: {
          min: 0,
          callback: (_label, _index, _labels) => {
            // return `${formatFloat(label, 0)}`;
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          fontSize: 14,
          fontStyle: 'bold',
          fontColor: '#333333',

          callback: (_label, _index, _labels) => {
            // if (!isScreenMobile()) {
            const labelMax = isScreenMobile() ? 33 : 50;
            if (_label.length > labelMax) {
              _label = `${_label.substr(0, labelMax - 3)}...`;
            }
            return _label;
            // }
          },
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
  tooltips: {
    enabled: isScreenMobile(),
    callbacks: {
      label: (tooltipItem, data) => {
        tooltipItem.yLabel = formatFloat(tooltipItem.yLabel);
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const quantidade = dataset.data[tooltipItem.index];
        return formatFloat(quantidade, 0);
      },
    },
  },
  animation: {
    duration: 500,
    onComplete(animation) {
      const chartInstance = animation.chart;
      const { ctx } = chartInstance;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillStyle = '#013b01';
      ctx.font = 'bold 14px sans-serif';

      chartInstance.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          const textWidth = ctx.measureText(data).width;
          ctx.fillText(formatFloat(data, 0), bar._model.x + textWidth, bar._model.y + 7);
        });
      });
    },
  },

  legend: {
    position: 'right',
    display: false,
    labels: {
      fontSize: 10,
      boxWidth: 10,
    },
  },
  title: {
    display: false,
    text: '',
    fontColor: '#333333',
    fontSize: 14,
    fontFamily: "'Open Sans', 'Helvetica Neue', 'sans-serif'",
  },
};
