import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import ChamadoTipoService from '../../services/ChamadoTipoService';

import { errorHandle, validateFields } from '../../util/functions';
import { ChamadoTipoModel } from '../../util/Models';
import { StateScreen } from '../constants';
import DropdownSp from '../../components/DropdownSp';

function ChamadoTipoCrud(props) {
  ChamadoTipoCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  ChamadoTipoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [chamadoTipo, setChamadoTipo] = useState(new ChamadoTipoModel());

  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  // useCallbacks

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await ChamadoTipoService.findById(_id);

      setChamadoTipo(retorno);

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await ChamadoTipoService.insert(chamadoTipo);
      } else {
        retorno = await ChamadoTipoService.update(chamadoTipo);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new ChamadoTipoModel();

      setChamadoTipo(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            autoFocus
            value={chamadoTipo.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setChamadoTipo({ ...chamadoTipo, nome: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
          <LabelSp>Tipo</LabelSp>
          <DropdownSp
            options={[
              { label: 'Atendimento', value: 0 },
              { label: 'Sugestão', value: 1 },
              { label: 'Reclamação', value: 2 },
              { label: 'Outros', value: 3 },
            ]}
            disabled={viewMode()}
            required
            onChange={e => {
              setChamadoTipo({ ...chamadoTipo, tipo: e.target.value });
            }}
            value={chamadoTipo.tipo}
          />
        </div>

        <div className="p-col-6 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Prazo em Dias</LabelSp>
          <InputTextSp
            type="tel"
            value={chamadoTipo.prazoDias}
            required
            disabled={viewMode()}
            onChange={e => {
              setChamadoTipo({ ...chamadoTipo, prazoDias: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              keyAccess="S"
              disabled={!validateFields(chamadoTipo, ['nome'])}
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            keyAccess="V"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default ChamadoTipoCrud;
