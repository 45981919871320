import React, { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Container } from './styles';
import { store } from '../../store';
import { showDialog } from '../../store/modules/dialog/actions';

export default function MessageInputText(props) {
  const { title, caption, messageVisible, handleOk, handleClose } = props;
  const [text, setText] = useState('');

  const footer = (
    <div>
      <Button
        label="Confirmar"
        icon="pi pi-check"
        className="p-button-success"
        disabled={!text}
        onClick={() => {
          if (handleOk) {
            handleOk(text);
          }
        }}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          handleClose();
        }}
        className="p-button-danger"
      />
    </div>
  );
  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header={title || 'Atenção'}
        visible={messageVisible}
        style={{ minWidth: '250px', maxWidth: '600px', width: '95%' }}
        modal
        onHide={() => {
          handleClose();
        }}
      >
        <form>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <InputText
                name={caption || ''}
                placeholder={caption || ''}
                required
                value={text}
                autoFocus
                onChange={e => setText(e.target.value)}
              />
            </div>
          </div>
        </form>
      </Dialog>
    </Container>
  );
}

export function showMessage(title, header, handleClose) {
  const { dispatch } = store;
  dispatch(showDialog(title, header, handleClose));
}
