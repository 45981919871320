import AuthService from '../services/AuthService';
import history from '../services/history';

export default function createMenu() {
  const usuario = AuthService.getUsuario();
  const { idUsuarioPerfil } = usuario;

  const menuReturn = [
    {
      label: 'Home',
      title: 'Home',
      icon: 'pi pi-fw pi-home',
      to: '/home',
    },
  ];

  let menuCadastros = { items: [] };
  const menuCadastroPessoal = {
    label: 'Pessoal',
    title: 'Pessoal',
    icon: 'pi pi-fw pi-users',
    items: [],
  };

  const menuCadastroOutros = {
    label: 'Outros',
    title: 'Outros',
    icon: 'pi pi-fw pi-clone',
    items: [],
  };
  const menuCadastroProdutos = {
    label: 'Produto',
    title: 'Produtos',
    icon: 'pi pi-fw pi-briefcase',
    items: [],
  };

  let menuDashboard;
  if (AuthService.checkRoles(['DASHBOARD_ACESSAR'])) {
    menuDashboard = {
      label: 'Dashboard',
      title: 'Dashboard',
      icon: 'pi pi-fw pi-chart-bar',
      to: '/dashboard',
    };
  }

  let menuLancamentos = { items: [] };
  // let menuRelatorios = { items: [] };
  let menuUtilitarios = { items: [] };

  if (idUsuarioPerfil === !1) {
    menuCadastros = null;
  } else {
    menuLancamentos = {
      title: 'Lançamentos',
      label: 'Lançamentos',
      icon: 'pi pi-fw pi-list',
      expanded: true,
      items: [],
    };

    menuCadastros = {
      title: 'Cadastros',
      label: 'Cadastros',
      icon: 'pi pi-fw pi-folder',
      expanded: true,
      items: [],
    };

    menuUtilitarios = {
      title: 'Utilitários',
      label: 'Utilitários',
      icon: 'pi pi-fw pi-cog',
      expanded: true,
      items: [],
    };

    // menu cadastros
    if (
      AuthService.checkRoles(['USUARIO_ACESSAR', 'USUARIO_INSERIR', 'USUARIO_ALTERAR']) &&
      AuthService.isPerfilGerente()
    ) {
      menuCadastroPessoal.items.push({
        title: 'Usuário',
        label: 'Usuário',
        icon: 'pi pi-user',

        command: () => history.push('/usuarios'),
      });
    }

    if (
      AuthService.checkRoles([
        'COLABORADOR_ACESSAR',
        'COLABORADOR_INSERIR',
        'COLABORADOR_ALTERAR',
      ])
    ) {
      menuCadastroPessoal.items.push({
        title: 'Colaborador',
        label: 'Colaborador',
        icon: 'far fa-user-circle',

        command: () => history.push('/colaboradores'),
      });
    }

    if (
      AuthService.checkRoles([
        'COLABORADOR_ACESSAR',
        'COLABORADOR_INSERIR',
        'COLABORADOR_ALTERAR',
      ])
    ) {
      menuCadastroPessoal.items.push({
        title: 'Cliente',
        label: 'Cliente',
        icon: 'far fa-user',

        command: () => history.push('/clientes'),
      });
    }

    if (
      AuthService.checkRoles(['REVENDA_ACESSAR', 'REVENDA_INSERIR', 'REVENDA_ALTERAR']) &&
      AuthService.isPerfilGerente()
    ) {
      menuCadastroPessoal.items.push({
        title: 'Revenda',
        label: 'Revenda',
        icon: 'pi pi-sitemap',

        command: () => history.push('/revendas'),
      });
    }

    if (
      AuthService.checkRoles([
        'CHAMADO_TIPO_ACESSAR',
        'CHAMADO_TIPO_INSERIR',
        'CHAMADO_TIPO_ALTERAR',
      ]) &&
      AuthService.isPerfilGerente()
    ) {
      menuCadastroOutros.items.push({
        title: 'Tipo de Chamado',
        label: 'Tipo de Chamado',
        icon: 'far fa-list-alt',

        command: () => history.push('/chamadosTipo'),
      });
    }

    if (
      AuthService.checkRoles([
        'ATEND_TOPICO_CLASSIFICADOR_LER',
        'ATEND_TOPICO_CLASSIFICADOR_INSERIR',
        'ATEND_TOPICO_CLASSIFICADOR_ALTERAR',
      ]) &&
      AuthService.isPerfilGerente()
    ) {
      menuCadastroOutros.items.push({
        title: 'Tópico Atendimento',
        label: 'Tópico Atendimento',
        icon: 'pi pi-sitemap',

        command: () => history.push('/atendimentoTopicos'),
      });
    }

    if (
      AuthService.checkRoles([
        'ATEND_CLASSIFICACAO_LER',
        'ATEND_CLASSIFICACAO_INSERIR',
        'ATEND_CLASSIFICACAO_ALTERAR',
      ]) &&
      AuthService.isPerfilGerente()
    ) {
      menuCadastroOutros.items.push({
        title: 'Classificação Atend.',
        label: 'Classificação Atend.',
        icon: 'pi pi-bars',

        command: () => history.push('/atendimentoClassificacoes'),
      });
    }

    if (
      AuthService.checkRoles(['CAMPANHA_LER', 'CAMPANHA_INSERIR', 'CAMPANHA_ALTERAR']) &&
      AuthService.isPerfilGerente()
    ) {
      menuCadastroOutros.items.push({
        title: 'Campanha de Venda',
        label: 'Campanha de Venda',
        icon: 'pi pi-briefcase',

        command: () => history.push('/campanhas'),
      });
    }

    if (
      AuthService.checkRoles([
        'FAMILIA_GRUPO_ACESSAR',
        'FAMILIA_GRUPO_INSERIR',
        'FAMILIA_GRUPO_ALTERAR',
      ])
    ) {
      menuCadastroProdutos.items.push({
        title: 'Grupo de Família',
        label: 'Grupo de Família',
        icon: 'far fa-object-ungroup',

        command: () => history.push('/familiaGrupos'),
      });
    }

    if (AuthService.checkRoles(['FAMILIA_ACESSAR', 'FAMILIA_INSERIR', 'FAMILIA_ALTERAR'])) {
      menuCadastroProdutos.items.push({
        title: 'Família',
        label: 'Família',
        icon: 'pi pi-desktop',

        command: () => history.push('/familias'),
      });
    }

    if (AuthService.checkRoles(['PRODUTO_ACESSAR', 'PRODUTO_INSERIR', 'PRODUTO_ALTERAR'])) {
      menuCadastroProdutos.items.push({
        title: 'Produtos',
        label: 'Produtos',
        icon: 'pi pi-credit-card',

        command: () => history.push('/produtos'),
      });
    }

    if (AuthService.checkRoles(['USUARIO_PERFIL_ACESSAR'])) {
      menuCadastroOutros.items.push({
        title: 'Perfil de Acesso',
        label: 'Perfil de Acesso',
        icon: 'pi pi-key',
        command: () => history.push('/usuarioPerfis'),
      });
    }

    // menu Lançamentos
    // menuLancamentos.items.push({
    //   title: 'Agenda',
    //   label: 'Agenda',
    //   icon: 'pi pi-calendar',
    //   command: () => history.push('/agendas'),
    // });
    menuLancamentos.items.push({
      title: 'Chamado',
      label: 'Chamado',
      icon: 'far fa-newspaper',
      command: () => history.push('/chamados'),
    });

    menuLancamentos.items.push({
      title: 'Desenvolvimento',
      label: 'Desenvolvimento',
      icon: 'pi pi-book',
      command: () => history.push('/desenvolvimentos'),
    });

    menuLancamentos.items.push({
      title: 'Acompanhamento Vendas',
      label: 'Acompanhamento Vendas',
      icon: 'pi pi-table',
      command: () => history.push('/acompanhamentoVendas'),
    });
  }

  if (menuCadastroPessoal.items.length > 0) {
    menuCadastros.items.push(menuCadastroPessoal);
  }

  // if (menuCadastroProdutos.items.length > 0) {
  //   menuCadastros.items.push(menuCadastroProdutos);
  // }

  if (menuCadastroOutros.items.length > 0) {
    menuCadastros.items.push(menuCadastroOutros);
  }

  if (menuDashboard) {
    menuReturn.push(menuDashboard);
  }

  if (menuCadastros && menuCadastros.items.length > 0) {
    menuReturn.push(menuCadastros);
  }

  if (menuLancamentos) {
    menuReturn.push(menuLancamentos);
  }

  // if (menuRelatorios) {
  //   menuReturn.push(menuRelatorios);
  // }

  if (menuUtilitarios && menuUtilitarios.items.length > 0) {
    menuReturn.push(menuUtilitarios);
  }

  menuReturn.push({
    label: 'Chave Liberação',
    title: 'Chave Liberação',
    icon: 'pi pi-unlock',
    to: '/chaves',
  });

  menuReturn.push({
    label: 'Backup Cliente',
    title: 'Backup Cliente',
    icon: 'pi pi-cloud',
    to: '/clientesBackup',
  });

  if (AuthService.checkRoles(['AUDITORIA_ACESSAR'])) {
    menuReturn.push({
      title: 'Auditoria',
      label: 'Auditoria',
      icon: 'pi pi-bars',
      command: () => history.push('/auditoria'),
    });
  }

  return menuReturn;
}
