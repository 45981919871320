import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class DesenvolvimentoTesteService extends BaseService {
  constructor() {
    super(`${ConfigApi.desenvolvimentoTesteURL}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.idDesenvolvimento = undefined;

    this.limit = 150;
    this.page = 0;
  }
}
export default new DesenvolvimentoTesteService();
