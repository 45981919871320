import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { Column } from 'primereact/column';

import PropTypes from 'prop-types';
import { Container } from './styles';
import { store } from '../../store';

import LabelSp from '../LabelSp';
import DataTableSp from '../DataTableSp';
import {
  errorHandle,
  isDate,
  padLeft,
  strNumBrToNumber,
  strToDate,
} from '../../util/functions';
import { hiddenLookup } from '../../store/modules/dialog/actions';
import InputTextSp from '../InputTextSp';

import DropdownSp from '../DropdownSp';
import { filterType } from '../../config/Constantes';

export default function LookupGeneric(props) {
  LookupGeneric.propTypes = {
    clickOnConfirm: PropTypes.bool,
  };

  LookupGeneric.defaultProps = {
    clickOnConfirm: false,
  };

  const { dispatch } = store;
  const { clickOnConfirm } = props;

  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const { lookupVisible, lookupConfig } = useSelector(state => {
    return state.dialog.config;
  });

  const [dropDownList, setDropDownList] = useState([]);
  const [dropDownSelectedValue, setDropDownSelectedValue] = useState('');
  const [records, setRecords] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [funcTimeOut, setFuncTimeOut] = useState();

  const inputRef = useMemo(() => {
    return React.createRef();
  }, []);

  function close() {
    if (lookupConfig.handleCancel) {
      lookupConfig.handleCancel();
    }
    dispatch(hiddenLookup(null));
  }

  function handleConfirm(lookupReturn) {
    if (lookupConfig.handleConfirm) {
      lookupConfig.handleConfirm(lookupReturn);
    }
    dispatch(hiddenLookup());
  }

  const getFilterAndValue = useCallback(
    _filterValue => {
      if (dropDownSelectedValue && lookupConfig && lookupConfig.filters) {
        const dropDownSelectedItem = lookupConfig.filters.find(
          e => e.name === dropDownSelectedValue
        );
        let value = '';
        if (dropDownSelectedItem) {
          switch (dropDownSelectedItem.type) {
            case filterType.INTEGER:
              value = parseInt(_filterValue, 10);
              break;
            case filterType.TEXT:
              value = _filterValue;
              break;

            case filterType.DECIMAL:
              value = strNumBrToNumber(_filterValue);
              break;

            case filterType.DATE:
              if (isDate(_filterValue, 'dd/MM/yyyy')) {
                value = strToDate(_filterValue, 'dd/MM/yyyy');
              }
              break;
            default:
              value = _filterValue;
              break;
          }
          return { name: dropDownSelectedItem.name, value };
        }
      }
      return null;
    },
    [dropDownSelectedValue, lookupConfig]
  );

  const handleSearch = useCallback(
    async (_filterValue, _page, resetPage = true) => {
      const filter = {};
      filter.page = _page || 0;
      filter.limit = 10;
      const filterAndValue = getFilterAndValue(_filterValue);
      if (filterAndValue) {
        filter[filterAndValue.name] = filterAndValue.value;
      }

      try {
        const result = await lookupConfig?.serviceInstance?.findAll(filter);
        setRecords(result.items);
        setSelectedRecord(null);
        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
        // inputRef.current.element.select();
      } catch (err) {
        errorHandle(err);
      }
    },
    [getFilterAndValue, lookupConfig]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleSearch('', pagina, false);
  }

  const autoSearch = useCallback(
    async (_filterValue, _page, resetPage = true) => {
      if (lookupConfig) {
        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleSearch(_filterValue, _page, resetPage);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [funcTimeOut, handleSearch, lookupConfig]
  );
  // useEffects
  useEffect(() => {
    if (lookupConfig && lookupConfig.filters) {
      const list = lookupConfig.filters.map(e => {
        return { label: e.caption, value: e.name };
      });

      setDropDownList(list);
      setDropDownSelectedValue(lookupConfig.defaultFilter);
      setRecords([]);
      setSelectedRecord(null);

      if (lookupConfig.serviceInstance) {
        handleSearch('', 0, true);
      }
      setFilterValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupConfig, lookupVisible]);

  // renders
  const footer = (
    <div style={{ textAlign: 'center' }}>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          close();
        }}
        className="p-button-danger"
      />
      <Button
        label="Confirmar"
        icon="pi pi-check"
        disabled={!selectedRecord}
        onClick={() => {
          handleConfirm(selectedRecord);
        }}
      />
    </div>
  );

  if (!lookupVisible) {
    return null;
  }

  const renderColums = () => {
    if (lookupConfig && lookupConfig.columns) {
      const { columns } = lookupConfig;
      const retorno = columns.map(e => {
        const objStyle = {};
        if (e.width) {
          objStyle.width = e.width;
        }
        return (
          <Column
            key={e.name}
            field={e.name}
            style={objStyle}
            body={rowData => {
              if (e.handleFormatValue) {
                return e.handleFormatValue(rowData[e.name]);
              }
              return rowData[e.name];
            }}
            header={e.caption}
            className={e.cssClassName}
          />
        );
      });

      return retorno;
    }

    return (
      <>
        <Column
          field="id"
          body={rowData => padLeft(rowData.id, 6)}
          header="Id"
          className="grid-col-id"
        />
        <Column field="nome" className="grid-col" header="Nome" />
      </>
    );
  };

  return (
    <Container>
      <Dialog
        closeOnEscape
        closable
        footer={!clickOnConfirm ? footer : null}
        header={lookupConfig?.title}
        visible={lookupVisible}
        contentStyle={{ minHeight: '400px' }}
        style={{ minWidth: '380px', maxWidth: '1024px', width: '95%' }}
        modal
        onHide={() => {
          close();
        }}
      >
        <form>
          <div className="p-grid">
            <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
              <LabelSp>Campo Filtro</LabelSp>
              <DropdownSp
                placeholder="Selecione"
                options={dropDownList}
                value={dropDownSelectedValue}
                onChange={e => {
                  setDropDownSelectedValue(e.value);
                  autoSearch(filterValue);
                }}
              />
            </div>
            <div className="p-col-12 p-sm-8 p-lg-8 p-fluid">
              <LabelSp>Valor a ser filtrado</LabelSp>

              <div className="p-inputgroup">
                <InputTextSp
                  ref={inputRef}
                  value={filterValue}
                  maxLength={40}
                  onChange={e => {
                    setFilterValue(e.target.value);
                    autoSearch(e.target.value);
                  }}
                  autoFocus
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      handleSearch(filterValue);
                    }
                  }}
                />
                <Button
                  className="p-button-secondary buttons"
                  title="Buscar"
                  icon="pi pi-search"
                  type="button"
                  onClick={() => {
                    handleSearch(filterValue);
                  }}
                />
              </div>
            </div>
            <div className="p-col-12 p-fluid datatable-doc-demo">
              <DataTableSp
                value={records}
                style={{ marginBottom: '2px' }}
                paginator
                rows={10}
                lazy
                selectionMode="single"
                responsive
                selection={selectedRecord}
                onSelectionChange={e => {
                  setSelectedRecord(e.value);
                  if (clickOnConfirm) {
                    handleConfirm(e.value);
                  }
                }}
                totalRecords={totalRecords}
                first={first}
                onPage={onPage}
              >
                {renderColums()}
              </DataTableSp>
            </div>
          </div>
        </form>
      </Dialog>
    </Container>
  );
}

export function showLookup(lookupConfig) {
  const { dispatch } = store;
  dispatch(showLookup(lookupConfig));
}
