import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class UsuarioPerfilService extends BaseService {
  constructor() {
    super(`${ConfigApi.usuarioPerfil}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.id = undefined;
    this.nome = '';

    this.limit = 11;
    this.page = 0;
  }
}
export default new UsuarioPerfilService();
