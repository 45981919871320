import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100% !important;
  .p-dialog {
    max-height: 99% !important;
  }
  .p-dialog-titlebar {
    margin: 0 !important;
    padding: 2px !important;
  }
  embed {
    height: calc(100vh - 40px);
  }
`;
