import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class AtendClassificacaoService extends BaseService {
  constructor() {
    super(`${ConfigApi.atendClassificacaoURL}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.descricao = '';
    this.idAtendTopicoClassificador = undefined;

    this.resumo = '';
    this.limit = 9999;
    this.page = 0;
  }
}
export default new AtendClassificacaoService();
