import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';
import DropdownSp from '../../components/DropdownSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import AuthService from '../../services/AuthService';

import UsuarioService from '../../services/UsuarioService';
import ColaboradorService from '../../services/ColaboradorService';

import { errorHandle, validateFields } from '../../util/functions';
import { UsuarioModel } from '../../util/Models';
import { StateScreen } from '../constants';
import UsuarioPerfilService from '../../services/UsuarioPerfilService';

function UsuarioCrud(props) {
  UsuarioCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  UsuarioCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // useMemos
  const usuarioLogado = useMemo(() => AuthService.getUsuario(), []);

  // states
  const [usuario, setUsuario] = useState(new UsuarioModel());
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(null);

  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [perfils, setPerfils] = useState();

  // useCallbacks
  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadPerfils = useCallback(async () => {
    const r = await UsuarioPerfilService.findAll({ limit: 50 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });
    setPerfils(retorno);
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await UsuarioService.findById(_id);

      setUsuario(retorno);
      const colaborador = retorno.colaborador
        ? {
            label: retorno.colaborador.nome,
            value: retorno.colaborador.id,
          }
        : null;

      setColaboradorSelecionado(colaborador);

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    if (senha || confirmSenha) {
      if (senha !== confirmSenha) {
        toast.warn('Senha e confirmação não conferem.');
        return;
      }

      usuario.senha = senha;
      usuario.confirmSenha = confirmSenha;
    }

    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await UsuarioService.insert(usuario);
      } else {
        retorno = await UsuarioService.update(usuario);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    loadPerfils();
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new UsuarioModel();
      setColaboradorSelecionado(null);

      setUsuario(novo);
    }
    setSenha('');
    setConfirmSenha('');
  }, [loadRecord, idSelected, stateScreen, loadPerfils]);

  const perfilAdministrador = AuthService.isPerfilGerente();

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Login</LabelSp>
          <InputTextSp
            autoFocus
            value={usuario.login}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setUsuario({ ...usuario, login: (e.target.value || '').toLowerCase() });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Perfil</LabelSp>
          <DropdownSp
            placeholder="Selecione"
            required
            disabled={viewMode() || !perfilAdministrador}
            value={usuario.idUsuarioPerfil}
            options={perfils}
            onChange={e => {
              setUsuario({ ...usuario, idUsuarioPerfil: e.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Colaborador</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode() || !perfilAdministrador}
            placeholder="Selecione"
            onChange={e => {
              const colaborador = e ? { id: e.value, nome: e.label } : null;
              setUsuario({
                ...usuario,
                colaborador,
                idColaborador: e?.value,
              });
            }}
            value={colaboradorSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaborador(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Inativo?</LabelSp>
          <DropdownSp
            required
            value={usuario.inativo}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            disabled={viewMode() || !perfilAdministrador}
            filterInputAutoFocus={false}
            onChange={e => {
              setUsuario({ ...usuario, inativo: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Senha</LabelSp>
          <InputTextSp
            type="password"
            name="txtSenha"
            maxLength={16}
            disabled={
              viewMode() ||
              (usuarioLogado.id !== 1 &&
                usuario.id !== usuarioLogado.id &&
                !perfilAdministrador)
            }
            required={stateScreen === StateScreen.stInsert || !!confirmSenha}
            value={senha}
            onChange={e => {
              setSenha(e.target.value);
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Confirmação</LabelSp>
          <InputTextSp
            type="password"
            name="txtSenhaConfirmacao"
            maxLength={16}
            disabled={
              viewMode() ||
              (usuarioLogado.id !== 1 &&
                usuario.id !== usuarioLogado.id &&
                !perfilAdministrador)
            }
            required={stateScreen === StateScreen.stInsert || !!senha}
            value={confirmSenha}
            onChange={e => {
              setConfirmSenha(e.target.value);
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              keyAccess="S"
              disabled={
                !validateFields(usuario, ['login', 'idColaborador']) ||
                (stateScreen === StateScreen.stInsert && (!senha || !confirmSenha))
              }
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            keyAccess="V"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default UsuarioCrud;
