/* eslint-disable no-nested-ternary */
import React from 'react';

// import PropTypes from 'prop-types';

import Panel from '../../components/Panel';
import { isScreenMobile } from '../../util/functions';

function Page3(props) {
  Page3.propTypes = {
    // dataGraficoAtMeses: PropTypes.object,
  };

  const { clienteNovo } = props;

  Page3.defaultProps = {
    required: false,
    autoFocus: false,
  };

  const pCol = 'p-col-12 p-lg-12 p-xl-12';

  const strQtdClienteNovo =
    Array.isArray(clienteNovo) && clienteNovo.length > 0 ? `(${clienteNovo.length})` : '';

  const renderClienteSemAt = () => {
    if (Array.isArray(clienteNovo)) {
      return clienteNovo.map((e, idx) => {
        return (
          <div
            className="div-listagem"
            key={e.id}
            style={{ background: idx % 2 === 0 ? '#edf0f5' : undefined }}
          >
            <span
              className="grid-col-nowrap "
              style={{
                fontSize: 14,
                display: 'block',
                fontStyle: 'bold',
                fontColor: '#333333',
                flex: 1,
              }}
            >
              {`${e.numeroContrato}  ${e.nome}`}
            </span>

            <div style={{ minWidth: 80 }}>
              <span
                className="grid-col-nowrap "
                style={{
                  fontSize: 14,
                  display: 'block',
                  fontStyle: 'bold',
                  fontColor: '#333333',
                  textAlign: 'end',
                }}
              >
                {e.quantidade}
              </span>
            </div>
          </div>
        );
      });
    }
    return null;
  };

  return (
    <>
      <div className={pCol}>
        <Panel
          header={`Clientes novos últ. 45 dias${strQtdClienteNovo}`}
          style={{ height: '100%' }}
        >
          <div className="div-listagem titulo-listagem">
            <span>Cliente</span>
            {isScreenMobile() ? <span>Qtd. At.</span> : <span>Qtd Atend.</span>}
          </div>
          {renderClienteSemAt()}
        </Panel>
      </div>
    </>
  );
}

export default Page3;
