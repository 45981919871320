/**
 * Página Auditoria
 * @module Auditoria
 * @category Pages
 */

import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';

import { useSelector } from 'react-redux';
import { Container } from './styles';

import AuditoriaService from '../../services/AuditoriaService';

import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  formatDate,
} from '../../util/functions';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import AuditoriaCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import LabelSp from '../../components/LabelSp';
import CalendarSp from '../../components/CalendarSp';
import DropDownLazy from '../../components/DropdownLazy';
import UsuarioService from '../../services/UsuarioService';
import { cfgPtBr } from '../../config/Constantes';
import DropdownSp from '../../components/DropdownSp';
import AuthService from '../../services/AuthService';

export default function Auditoria(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  const filterService = useMemo(() => AuditoriaService.getFilter(), []);

  // useStates
  const toBack = pageParams.toBack || '/auditoria';
  const [filter, setFilter] = useState(filterService);
  const [auditorias, setAuditoriaes] = useState([]);
  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [usuario, setUsuario] = useState(null);
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [listaAcoes, setListaAcoes] = useState([]);
  const [listaPaginas, setListaPaginas] = useState([]);
  const [pageSelected, setPageSelected] = useState(0);

  // calbacks
  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(10, 14, 19);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const loadUsuario = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await UsuarioService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.login,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();
      try {
        const result = await AuditoriaService.findAll(_filter);
        setAuditoriaes(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  const criaListaAcoes = useCallback(async () => {
    let r = await AuditoriaService.findAllAcoes();
    r = [{ acao: 'Todas' }].concat(r);
    const retorno = r.map((e, i) => {
      return {
        label: e.acao,
        value: i === 0 ? '' : e.acao,
      };
    });

    setListaAcoes(retorno);
  }, []);

  const criaListaPaginas = useCallback(async () => {
    let r = await AuditoriaService.findAllPaginas();
    r = [{ pagina: 'Todas' }].concat(r);
    const retorno = r.map((e, i) => {
      return {
        label: e.pagina,
        value: i === 0 ? '' : e.pagina,
      };
    });

    setListaPaginas(retorno);
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  const doExportCSV = useCallback(async _filter => {
    try {
      await AuditoriaService.exportCSV(_filter);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  // functions
  function getTitle() {
    const titleDefault = 'Auditoria';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }

    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
      criaListaAcoes();
      criaListaPaginas();
    }
    // desativado para evitar que a cada vez que o financiador digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert={false}
          showButtonSearch
          titleFilterDefault="Buscar por Conteúdo"
          handleFilterDefault={text => {
            filter.conteudo = text;
            setFilter({ ...filter, conteudo: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              acao: '',
              idUsuario: 0,
            });
            setUsuario(null);
          }}
          handleButtonInsert={() => history.push('/auditoria/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
          optionsButtonMenu={{
            handles: [() => doExportCSV(filter)],
            labels: ['Exportar para CSV'],
            icons: ['pi pi-cloud-download'],
            disableds: [!AuthService.checkRoles('AUDITORIA_EXPORT')],
          }}
        >
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp htmlFor="dataInicial">De: </LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              dateFormat="dd/mm/yy"
              yearNavigator
              locale={cfgPtBr}
              id="dataInicial"
              value={filter.dataInicio}
              yearRange="2010:2040"
              onChange={e => setFilterAndSearch({ ...filter, dataInicio: e.value })}
            />
          </div>
          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp htmlFor="dataFinal">Até: </LabelSp>
            <CalendarSp
              appendTo={document.body}
              readOnlyInput
              locale={cfgPtBr}
              id="dataFinal"
              dateFormat="dd/mm/yy"
              value={filter.dataFim}
              yearNavigator
              yearRange="2010:2040"
              onChange={e => setFilterAndSearch({ ...filter, dataFim: e.value })}
            />
          </div>
          <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>Ação</LabelSp>
            <DropdownSp
              value={filter.acao}
              options={listaAcoes}
              filterInputAutoFocus={false}
              onChange={e => {
                setFilterAndSearch({ ...filter, acao: e.target.value });
              }}
            />
          </div>
          <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>Página</LabelSp>
            <DropdownSp
              value={filter.pagina}
              options={listaPaginas}
              filterInputAutoFocus={false}
              onChange={e => {
                setFilterAndSearch({ ...filter, pagina: e.target.value });
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Usuario</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idUsuario: e?.value });
                setUsuario(e);
              }}
              value={usuario}
              onFilter={async txtFilter => {
                const retorno = await loadUsuario(txtFilter);
                return retorno;
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid datatable-doc-demo">
          <DataTableSp
            value={auditorias}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="dataRegistro"
              className="grid-col-data"
              header="Data"
              body={rowData => formatDate(rowData.dataRegistro, 'dd/MM/yyyy')}
            />

            <Column
              field="horaRegistro"
              className="grid-col-hora"
              header="Hora"
              body={rowData => rowData.horaRegistro}
            />
            <Column
              field="usuario.login"
              className="grid-col grid-col-nowrap"
              style={{ width: 200 }}
              body={rowData => rowData.usuario?.login.toUpperCase()}
              header="Usuário"
            />
            <Column
              field="acao"
              className="grid-col p-p-6"
              style={{ width: 120 }}
              header="Ação"
            />
            <Column
              field="pagina"
              className="grid-col p-p-6"
              style={{ width: 180 }}
              header="Página"
            />
            <Column field="conteudo" className="grid-col grid-col-nowrap" header="Conteúdo" />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[() => history.push(`/auditoria/${rowData.id}?view`), null, null]}
        disableds={[false, true, true]}
      />
    );
  }

  function renderCrud() {
    return (
      <AuditoriaCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        onClose={() => {
          history.push(toBack);
          handleBuscar(filter, pageSelected, false);
        }}
      />
    );
  }
}
