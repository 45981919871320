import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Column } from 'primereact/column';
import { differenceInDays } from 'date-fns';
import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import InputCurrencySp from '../../components/InputCurrencySp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import ClienteService from '../../services/ClienteService';

import {
  cloneObj,
  errorHandle,
  isDate,
  isScreenMobile,
  newDateOnly,
  strToDate,
  validateFields,
  validCPF,
} from '../../util/functions';
import {
  ClienteContatoEndereco,
  ClienteModel,
  ClienteModuloModel,
  ClienteSocioModel,
} from '../../util/Models';
import { StateScreen } from '../constants';
import DropdownSp from '../../components/DropdownSp';
import InputMaskSp from '../../components/InputMaskSp';
import InputTextareaSp from '../../components/InputTextareaSp';
import DataTableSp from '../../components/DataTableSp';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import { cfgPtBr, listaUfs } from '../../config/Constantes';
import DropDownLazy from '../../components/DropdownLazy';
import CalendarSp from '../../components/CalendarSp';
import AuthService from '../../services/AuthService';
import RevendaService from '../../services/RevendaService';
import MunicipioService from '../../services/MunicipioService';
import ModulosService from '../../services/ModulosService';
import DispositivoService from '../../services/DispositivoService';

function ClienteCrud(props) {
  ClienteCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  ClienteCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  const revendaUsuario = AuthService.getUsuario().revenda
    ? {
        label: AuthService.getUsuario().revenda.nome,
        value: AuthService.getUsuario().revenda.id,
      }
    : null;

  // states
  const [cliente, setCliente] = useState(new ClienteModel());
  const [ufSelecionada, setUfSelecionada] = useState('MG');

  const [revendaSelecionada, setRevendaSelecionada] = useState();
  const [municipioSelecionado, setMunicipioSelecionado] = useState();
  const [clienteSocioEdicao, setClienteSocioEdicao] = useState();
  const [clienteModuloEdicao, setClienteModuloEdicao] = useState();
  const [editandoClienteModulo, setEditandoClienteModulo] = useState(false);
  const [editandoClienteSocio, setEditandoClienteSocio] = useState(false);
  const [modulos, setModulos] = useState([]);

  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');

  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [dispositivosUsados, setDispositivosUsados] = useState('');

  // useCallbacks

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await ClienteService.findById(_id);

      const municipio = retorno.clienteContatoEndereco
        ? {
            label: retorno.clienteContatoEndereco.municipio.nome,
            value: retorno.clienteContatoEndereco.municipio.id,
          }
        : null;

      if (!retorno.clienteContatoEndereco) {
        retorno.clienteContatoEndereco = new ClienteContatoEndereco();
      }

      const revenda = retorno.revenda
        ? {
            label: retorno.revenda.nome,
            value: retorno.revenda.id,
          }
        : null;

      if (isDate(retorno.dataInicioContrato)) {
        retorno.dataInicioContrato = strToDate(retorno.dataInicioContrato);
      }

      if (isDate(retorno.dataFimContrato)) {
        retorno.dataFimContrato = strToDate(retorno.dataFimContrato);
      }

      if (retorno.numeroContrato) {
        const chaveDispositivos = await DispositivoService.findAll({
          numeroContrato: retorno.numeroContrato,
        });

        setDispositivosUsados(chaveDispositivos.items.length);
      }

      setMunicipioSelecionado(municipio);
      setRevendaSelecionada(revenda);
      setCliente(retorno);

      if (retorno.tipoPessoa === 'F') {
        setCpf(retorno.cpfCnpj);
        setCnpj('');
      } else {
        setCpf('');
        setCnpj(retorno.cpfCnpj);
      }

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  const loadModulos = useCallback(async () => {
    const retorno = await ModulosService.findAll();

    const lista = retorno.items.map(c => {
      return { label: c.descricao, value: c };
    });
    setModulos(lista);
  }, []);

  const loadRevenda = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RevendaService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function permissaoAdminAlteracao() {
    if (AuthService.isPerfilGerente()) {
      return true;
    }
    const dataAtual = newDateOnly();
    const dataInclusao = new Date(cliente.dataInclusao) || new Date();
    const dias = differenceInDays(dataInclusao, dataAtual);

    return dias < 1;
  }

  function bloqueiaRevenda() {
    return AuthService.getUsuario().idRevenda && !AuthService.isPerfilGerente();
  }

  function permissaoModulos() {
    if (cliente && cliente.chave && cliente.id && !AuthService.isPerfilGerente()) {
      return false;
    }
    return true;
  }

  function novoModulo() {
    setClienteModuloEdicao(new ClienteModuloModel());
    setEditandoClienteModulo(false);
  }

  function alterarModulo(clienteModulo) {
    setEditandoClienteModulo(true);
    setClienteModuloEdicao(cloneObj(clienteModulo));
  }

  function novoSocio() {
    setClienteSocioEdicao(new ClienteSocioModel());
    setEditandoClienteSocio(false);
  }

  function alterarSocio(clienteSocio) {
    setClienteSocioEdicao(cloneObj(clienteSocio));
    setEditandoClienteSocio(true);
  }

  function excluirSocio(clienteSocio) {
    showMessage('Confirmação', 'Confirma Exclusão do Sócio?', idx => {
      if (idx === 1) {
        let idArray = -1;
        const { socios } = cliente;
        socios.forEach((e, i) => {
          if (clienteSocio.cpf === e.cpf) {
            idArray = i;
          }
        });

        if (idArray >= 0) {
          socios.splice(idArray, 1);
          setCliente({ ...cliente, socios });
        }
      }
    });
  }

  function excluirModulo(clienteModulo) {
    showMessage('Confirmação', 'Confirma Exclusão do Módulo?', idx => {
      if (idx === 1) {
        let idArray = -1;
        const cliModulos = cliente.modulos;
        cliModulos.forEach((e, i) => {
          if (clienteModulo.modulo.id === e.modulo.id) {
            idArray = i;
          }
        });

        if (idArray >= 0) {
          cliModulos.splice(idArray, 1);
          setCliente({ ...cliente, modulos: cliModulos });
        }
      }
    });
  }

  function salvarModulo() {
    if (
      !clienteModuloEdicao ||
      !clienteModuloEdicao.modulo ||
      !clienteModuloEdicao.modulo.id
    ) {
      toast.error('Informe o módulo');
      return;
    }

    if (!clienteModuloEdicao.quantidade) {
      toast.error('Informe a quantidade do módulo.');
      return;
    }

    let idArray = -1;
    cliente.modulos.forEach((e, idx) => {
      if (clienteModuloEdicao.modulo.id === e.modulo.id) {
        idArray = idx;
      }
    });

    if (!editandoClienteModulo && idArray >= 0) {
      toast.error('Módulo já foi lançado.');
    } else {
      clienteModuloEdicao.idModulo = clienteModuloEdicao.modulo.id;
      if (idArray >= 0) {
        cliente.modulos[idArray] = clienteModuloEdicao;
      } else {
        cliente.modulos.push(clienteModuloEdicao);
      }

      novoModulo();
    }
  }

  function salvarSocio() {
    if (!clienteSocioEdicao || !clienteSocioEdicao.nome) {
      toast.error('Informe o nome do sócio.');
      return;
    }

    if (!clienteSocioEdicao.email) {
      toast.error('Informe o e-mail do sócio.');
      return;
    }

    if (!validCPF(clienteSocioEdicao.cpf)) {
      toast.error('Informe o CPF do sócio inválido.');
      return;
    }

    let idArray = -1;
    cliente.socios.forEach((e, idx) => {
      if (clienteSocioEdicao.cpf === e.cpf) {
        idArray = idx;
      }
    });

    if (!editandoClienteSocio && idArray >= 0) {
      toast.error('Sócio já foi lançado.');
    } else {
      if (idArray >= 0) {
        cliente.socios[idArray] = clienteSocioEdicao;
      } else {
        cliente.socios.push(clienteSocioEdicao);
      }

      novoSocio();
    }
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      if (cliente.tipoPessoa === 'F') {
        cliente.cpfCnpj = cpf;
      } else {
        cliente.cpfCnpj = cnpj;
      }
      if (stateScreen === StateScreen.stInsert) {
        retorno = await ClienteService.insert(cliente);
      } else {
        retorno = await ClienteService.update(cliente);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    loadModulos();
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new ClienteModel();
      novo.valorSalarioBase = AuthService.getConfiguracao()?.valorSalarioVigente;
      if (AuthService.getUsuario().idRevenda) {
        novo.idRevenda = AuthService.getUsuario().idRevenda;
        novo.revenda = AuthService.getUsuario().revenda;
      }

      setCpf('');
      setCnpj('');

      setCliente(novo);
      setRevendaSelecionada(revendaUsuario);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRecord, idSelected, stateScreen, loadModulos]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-3 p-md-2 p-lg-2 p-fluid">
          <LabelSp>Contrato</LabelSp>
          <InputTextSp disabled value={cliente.numeroContrato} />
        </div>

        <div className="p-col-9 p-md-4 p-lg-4 p-fluid" style={{ margin: 0, padding: 0 }}>
          <div className="p-grid" style={{ margin: 0, padding: 0 }}>
            <div className="p-col-5 p-fluid">
              <LabelSp>Tp. Pessoa</LabelSp>
              <DropdownSp
                autoFocus
                value={cliente.tipoPessoa}
                name="cmbTipoPessoa"
                disabled={viewMode() || !permissaoAdminAlteracao()}
                options={[
                  { label: 'Física', value: 'F' },
                  { label: 'Jurídica', value: 'J' },
                ]}
                onChange={e => {
                  setCliente({ ...cliente, tipoPessoa: e.target.value });
                }}
              />
            </div>

            {cliente.tipoPessoa === 'F' ? (
              <div className="p-col-7 p-fluid">
                <LabelSp>CPF</LabelSp>
                <InputMaskSp
                  required
                  name="txtCPF"
                  appAutofocus
                  mask="999.999.999-99"
                  value={cpf}
                  disabled={viewMode() || !permissaoAdminAlteracao()}
                  onChange={e => {
                    setCpf(e.target.value);
                  }}
                />
              </div>
            ) : (
              <div className="p-col-7 p-fluid">
                <LabelSp>CNPJ</LabelSp>
                <InputMaskSp
                  mask="99.999.999/9999-99"
                  required
                  value={cnpj}
                  disabled={viewMode() || !permissaoAdminAlteracao()}
                  onChange={e => {
                    setCnpj(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="p-col-12 p-md-2 p-lg-2 p-fluid">
          <LabelSp>Revenda</LabelSp>
          <DropDownLazy
            autoLoad
            placeholder="Selecione"
            required
            value={revendaSelecionada}
            onChange={e => {
              const revenda = e ? { id: e.value, nome: e.label } : null;
              setCliente({
                ...cliente,
                revenda,
                idRevenda: e?.value,
              });
            }}
            onFilter={async txtFilter => {
              const retorno = await loadRevenda(txtFilter);
              return retorno;
            }}
            disabled={viewMode() || bloqueiaRevenda() || !permissaoAdminAlteracao()}
          />
        </div>

        <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
          <LabelSp>Razão Social</LabelSp>
          <InputTextSp
            value={cliente.nome}
            disabled={viewMode() || !permissaoAdminAlteracao()}
            maxLength={100}
            required
            onChange={e => {
              setCliente({ ...cliente, nome: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
          <LabelSp>Fantasia</LabelSp>
          <InputTextSp
            value={cliente.nomeFantasia}
            maxLength={100}
            required
            disabled={viewMode() || !permissaoAdminAlteracao()}
            onChange={e => {
              setCliente({ ...cliente, nomeFantasia: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Insc. Estadual</LabelSp>
          <InputTextSp
            value={cliente.inscricaoEstadual}
            disabled={viewMode() || !permissaoAdminAlteracao()}
            maxLength={20}
            onChange={e => {
              setCliente({ ...cliente, inscricaoEstadual: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-md-5 p-lg-4 p-fluid">
          <LabelSp>Endereço</LabelSp>
          <InputTextSp
            required
            maxLength={100}
            value={cliente.clienteContatoEndereco?.logradouro}
            disabled={viewMode() || !permissaoAdminAlteracao()}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.logradouro = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
          />
        </div>

        <div className="p-col-12 p-md-2 p-lg-1 p-fluid">
          <LabelSp>Nr.</LabelSp>
          <InputTextSp
            maxLength={10}
            required
            value={cliente.clienteContatoEndereco?.numero}
            disabled={viewMode() || !permissaoAdminAlteracao()}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.numero = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
          />
        </div>

        <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Bairro</LabelSp>
          <InputTextSp
            name="txtBairro"
            required
            value={cliente.clienteContatoEndereco?.bairro}
            disabled={viewMode() || !permissaoAdminAlteracao()}
            maxLength={100}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.bairro = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
          />
        </div>

        <div className="p-col-6 p-md-2 p-lg-1 p-fluid">
          <LabelSp>CEP</LabelSp>
          <InputMaskSp
            name="txtCep"
            mask="99999-999"
            disabled={viewMode() || !permissaoAdminAlteracao()}
            value={cliente.clienteContatoEndereco?.cep}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.cep = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
          />
        </div>

        <div className="p-col-6 p-md-2 p-lg-1 p-fluid">
          <LabelSp>UF</LabelSp>
          <DropdownSp
            options={listaUfs}
            disabled={viewMode() || !permissaoAdminAlteracao()}
            value={ufSelecionada}
            onChange={e => {
              setUfSelecionada(e.value);
            }}
          />
        </div>

        <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Município</LabelSp>
          <DropDownLazy
            autoLoad
            placeholder="Selecione"
            required
            value={municipioSelecionado}
            onChange={e => {
              const municipio = e ? { id: e.value, nome: e.label } : null;

              const { clienteContatoEndereco } = cliente;

              clienteContatoEndereco.idMunicipio = e?.value || undefined;
              clienteContatoEndereco.municipio = municipio;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
            onFilter={async txtFilter => {
              const retorno = await loadMunicipio(txtFilter, ufSelecionada);
              return retorno;
            }}
            disabled={viewMode() || !permissaoAdminAlteracao()}
          />
        </div>

        <div className="p-col-12 p-md-3 p-lg-4 p-fluid">
          <LabelSp>e-mail</LabelSp>
          <InputTextSp
            name="txtEmail"
            value={cliente.clienteContatoEndereco?.email}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.email = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
            required
            disabled={viewMode() || !permissaoAdminAlteracao()}
          />
        </div>

        <div className="p-col-4 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Telefone</LabelSp>
          <InputMaskSp
            mask="(99)9999-9999"
            value={cliente.clienteContatoEndereco?.fone}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.fone = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
            disabled={viewMode() || !permissaoAdminAlteracao()}
          />
        </div>

        <div className="p-col-4 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Celular</LabelSp>
          <InputMaskSp
            mask="(99)99999-9999"
            value={cliente.clienteContatoEndereco?.celular}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.celular = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
            disabled={viewMode()}
          />
        </div>

        <div className="p-col-4 p-md-3 p-lg-3 p-fluid">
          <LabelSp>WhatsApp Finc.</LabelSp>
          <InputMaskSp
            mask="(99)99999-9999"
            value={cliente.clienteContatoEndereco?.whatsappFinanceiro}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.whatsappFinanceiro = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
            disabled={viewMode() || !permissaoAdminAlteracao()}
          />
        </div>

        <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Nome Contato</LabelSp>
          <InputTextSp
            required
            value={cliente.clienteContatoEndereco?.nomeContato}
            onChange={e => {
              const { clienteContatoEndereco } = cliente;
              clienteContatoEndereco.nomeContato = e.target.value;
              setCliente({
                ...cliente,
                clienteContatoEndereco: { ...clienteContatoEndereco },
              });
            }}
            disabled={viewMode() || !permissaoAdminAlteracao()}
          />
        </div>

        <div className="p-col-4 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Inicio Contrato</LabelSp>
          <CalendarSp
            showOnFocus={false}
            showIcon
            required
            locale={cfgPtBr}
            dateFormat="dd/mm/yy"
            disabled={viewMode() || !permissaoAdminAlteracao()}
            value={cliente.dataInicioContrato}
            appendTo={window.body}
            onChange={e => {
              setCliente({
                ...cliente,
                dataInicioContrato: e.target.value,
              });
            }}
          />
        </div>

        <div className="p-col-4 p-md-2 p-lg-2 p-fluid">
          <LabelSp>Inativo?</LabelSp>
          <DropdownSp
            disabled={viewMode() || !permissaoAdminAlteracao()}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            value={cliente.inativo}
            onChange={e => {
              setCliente({
                ...cliente,
                inativo: e.target.value,
                dataFimContrato: !e.target.value ? null : cliente.dataFimContrato,
              });
            }}
          />
        </div>

        <div className="p-col-4 p-md-4 p-lg-3 p-fluid">
          <LabelSp>Fim Contrato</LabelSp>
          <CalendarSp
            showIcon
            required
            dateFormat="dd/mm/yy"
            disabled={viewMode() || !cliente.inativo || !permissaoAdminAlteracao()}
            value={cliente.dataFimContrato}
            appendTo={window.body}
            onChange={e => {
              setCliente({
                ...cliente,
                dataFimContrato: e.target.value,
              });
            }}
          />
        </div>

        <div className="p-col-12 p-md-6 p-lg-5 p-fluid" style={{ margin: 0, padding: 0 }}>
          <div className="p-grid" style={{ margin: 0, padding: 0 }}>
            <div className="p-col-4 p-fluid">
              <LabelSp>Sálario Base</LabelSp>
              <InputCurrencySp
                digits={2}
                disabled
                name="txtValorImplantacao"
                value={cliente.valorSalarioBase}
                onChange={(e, v) => {
                  setCliente({
                    ...cliente,
                    valorSalarioBase: v,
                  });
                }}
              />
            </div>

            <div className="p-col-4 p-fluid">
              <LabelSp>%Salário</LabelSp>
              <InputCurrencySp
                digits={4}
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
                value={cliente.percentualSalario}
                onChange={(e, v) => {
                  let { valorMensal } = cliente;
                  if (v !== cliente.percentualSalario && cliente.valorSalarioBase > 0) {
                    valorMensal = (v / 100) * cliente.valorSalarioBase;
                  }

                  setCliente({
                    ...cliente,
                    percentualSalario: v,
                    valorMensal,
                  });
                }}
              />
            </div>

            <div className="p-col-4 p-fluid">
              <LabelSp>Vr. Mensal</LabelSp>
              <InputCurrencySp
                digits={2}
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
                value={cliente.valorMensal}
                onChange={(e, v) => {
                  let { percentualSalario } = cliente;
                  if (v !== cliente.valorMensal && cliente.valorSalarioBase > 0) {
                    if (v > 0) {
                      percentualSalario = (v / cliente.valorSalarioBase) * 100;
                    } else {
                      percentualSalario = 0;
                    }
                  }

                  setCliente({
                    ...cliente,
                    percentualSalario,
                    valorMensal: v,
                  });
                }}
              />
            </div>

            <div className="p-col-6 p-fluid">
              <LabelSp>Vencto</LabelSp>
              <DropdownSp
                options={[
                  { value: 10, label: 'Dia 10' },
                  { value: 20, label: 'Dia 20' },
                ]}
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
                placeholder="Selecione"
                required
                value={cliente.diaVencimento}
                onChange={e => {
                  setCliente({
                    ...cliente,
                    diaVencimento: e.target.value,
                  });
                }}
              />
            </div>

            <div className="p-col-6 p-fluid">
              <LabelSp>Vr. Implant.</LabelSp>
              <InputCurrencySp
                digits={2}
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
                name="txtValorImplantacao"
                value={cliente.valorImplantacao}
                onChange={(e, v) => {
                  setCliente({
                    ...cliente,
                    valorImplantacao: v,
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="p-col-12 p-md-6 p-lg-7 p-fluid">
          <LabelSp>Observação</LabelSp>
          <InputTextareaSp
            autoResize={false}
            value={cliente.observacao}
            maxLength={2000}
            rows={4}
            resize={false}
            onChange={e => {
              setCliente({
                ...cliente,
                observacao: e.target.value,
              });
            }}
            style={{ minHeight: 93 }}
            disabled={viewMode() || !permissaoAdminAlteracao}
          />
        </div>
        {(!isScreenMobile() && renderSociosAndLicencas()) || !permissaoAdminAlteracao()}

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              keyAccess="S"
              disabled={
                !validateFields(cliente, [
                  'nome',
                  'idRevenda',
                  'nomeFantasia',
                  'dataInicioContrato',
                  'numeroDispositivos',
                  'diaVencimento',
                ]) ||
                cliente.edicao === undefined ||
                cliente.socios.length === 0 ||
                (cliente.tipoPessoa === 'F' && !cpf) ||
                (cliente.tipoPessoa === 'J' && !cnpj) ||
                (cliente.inativo && !cliente.dataFimContrato) ||
                !validateFields(cliente.clienteContatoEndereco, [
                  'logradouro',
                  'numero',
                  'bairro',
                  'idMunicipio',
                  'nomeContato',
                  'email',
                ])
              }
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            keyAccess="V"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );

  function renderSociosAndLicencas() {
    return (
      <>
        <div className="p-col-12 p-md-12 p-lg-4 p-fluid" style={{ margin: 0, padding: 0 }}>
          <div className="p-grid" style={{ margin: 0, padding: 0 }}>
            <div className="p-col-12 p-fluid">
              <LabelSp>Dados da licença</LabelSp>
              <hr style={{ marginTop: 3 }} />
            </div>

            <div className="p-col-5 p-md-6 p-lg-6 p-fluid">
              <LabelSp>Edição</LabelSp>
              <DropdownSp
                options={[
                  { label: 'Business', value: 0 },
                  { label: 'Professional', value: 1 },
                  { label: 'Lite', value: 2 },
                  { label: 'Nfe', value: 3 },
                ]}
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
                required
                onChange={e => {
                  setCliente({ ...cliente, edicao: e.target.value });
                }}
                value={cliente.edicao}
              />
            </div>

            <div className="p-col-4 p-md-3 p-lg-3 p-fluid">
              <LabelSp>Qt. Disp.</LabelSp>
              <InputTextSp
                type="tel"
                value={cliente.numeroDispositivos}
                required
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
                onChange={e => {
                  setCliente({ ...cliente, numeroDispositivos: e.target.value });
                }}
              />
            </div>

            <div className="p-col-4 p-md-3 p-lg-3 p-fluid">
              <LabelSp>Usados</LabelSp>
              <InputTextSp type="tel" value={dispositivosUsados} required disabled />
            </div>

            <div className="p-col-6 p-md-7 p-lg-7 p-fluid" style={{ display: 'block' }}>
              <LabelSp>Módulos</LabelSp>
              <DropdownSp
                options={modulos}
                filter
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
                value={clienteModuloEdicao?.modulo}
                onChange={e => {
                  setClienteModuloEdicao({ ...clienteModuloEdicao, modulo: e.target.value });
                }}
                placeholder="Selecione"
              />
            </div>
            <div className="p-col-3 p-md-2 p-lg-2 p-fluid">
              <LabelSp>Qtde.</LabelSp>

              <InputCurrencySp
                digits={0}
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
                value={clienteModuloEdicao?.quantidade}
                onChange={e => {
                  setClienteModuloEdicao({
                    ...clienteModuloEdicao,
                    quantidade: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-3 p-md-3 p-lg-3" style={{ textAlign: 'start' }}>
              <LabelSp style={{ display: 'block' }}>&#160;</LabelSp>
              <ButtonSp
                icon="pi pi-save"
                title="Salvar"
                onClick={salvarModulo}
                type="button"
                className="p-button-success"
                disabled={viewMode() || !permissaoModulos() || !permissaoAdminAlteracao()}
              />

              <ButtonSp
                onClick={novoModulo}
                type="button"
                disabled={viewMode() || !permissaoAdminAlteracao()}
                icon="pi pi-file"
                title="Novo"
              />
            </div>
          </div>

          <div className="p-col-12 p-fluid">
            <DataTableSp value={cliente.modulos} style={{ marginBottom: '2px' }} responsive>
              <Column
                className="grid-col"
                header="Módulos"
                body={rowData => rowData.modulo.descricao}
              />

              <Column
                className="grid-col-val"
                style={{ width: 100 }}
                header="Quantidade"
                body={rowData => rowData.quantidade}
              />

              <Column
                className="gid-col-acoes-35"
                bodyStyle={{ textAlign: 'end' }}
                body={renderButtonOpModulo}
              />
            </DataTableSp>
          </div>
        </div>

        <div className="p-col-12 p-md-12 p-lg-8 p-fluid" style={{ margin: 0, padding: 0 }}>
          <div className="p-grid" style={{ margin: 0, padding: 0 }}>
            <div className="p-col-12 p-fluid">
              <LabelSp>Sócio</LabelSp>
              <hr style={{ marginTop: 3 }} />
            </div>

            <div className="p-col-12 p-md-8 p-lg-8 p-fluid">
              <LabelSp>Nome do Sócio</LabelSp>
              <InputTextSp
                text="text"
                value={clienteSocioEdicao?.nome}
                onChange={e => {
                  setClienteSocioEdicao({ ...clienteSocioEdicao, nome: e.target.value });
                }}
                disabled={viewMode() || !permissaoAdminAlteracao()}
              />
            </div>

            <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
              <LabelSp>CPF</LabelSp>
              <InputMaskSp
                mask="999.999.999-99"
                value={clienteSocioEdicao?.cpf}
                onChange={e => {
                  setClienteSocioEdicao({ ...clienteSocioEdicao, cpf: e.target.value });
                }}
                disabled={viewMode() || !permissaoAdminAlteracao()}
              />
            </div>

            <div className="p-col-6 p-md-3 p-lg-3 p-fluid">
              <LabelSp>Celular</LabelSp>
              <InputMaskSp
                mask="(99)99999-9999"
                value={clienteSocioEdicao?.celular1}
                onChange={e => {
                  setClienteSocioEdicao({ ...clienteSocioEdicao, celular1: e.target.value });
                }}
                disabled={viewMode() || !permissaoAdminAlteracao()}
              />
            </div>

            <div className="p-col-6 p-md-3 p-lg-3 p-fluid">
              <LabelSp>Whatsapp</LabelSp>
              <InputMaskSp
                mask="(99)99999-9999"
                value={clienteSocioEdicao?.celular2}
                onChange={e => {
                  setClienteSocioEdicao({ ...clienteSocioEdicao, celular2: e.target.value });
                }}
                disabled={viewMode() || !permissaoAdminAlteracao()}
              />
            </div>
            <div className="p-col-9 p-md-4 p-lg-4 p-fluid">
              <LabelSp>e-mail</LabelSp>
              <InputTextSp
                value={clienteSocioEdicao?.email}
                disabled={viewMode() || !permissaoAdminAlteracao()}
                onChange={e => {
                  setClienteSocioEdicao({ ...clienteSocioEdicao, email: e.target.value });
                }}
              />
            </div>
            <div className="p-col-3 p-md-2 p-lg-2" style={{ textAlign: 'start' }}>
              <LabelSp style={{ display: 'block' }}>&#160;</LabelSp>
              <ButtonSp
                icon="pi pi-save"
                title="Salvar"
                onClick={salvarSocio}
                disabled={viewMode() || !permissaoAdminAlteracao()}
                type="button"
                className="p-button-success"
              />

              <ButtonSp
                onClick={novoSocio}
                type="button"
                disabled={viewMode() || !permissaoAdminAlteracao()}
                icon="pi pi-file"
                title="Novo"
              />
            </div>
          </div>

          <div className="p-col-12 p-fluid">
            <DataTableSp value={cliente.socios} style={{ marginBottom: '2px' }} responsive>
              <Column className="grid-col" header="Sócio" body={rowData => rowData.nome} />
              <Column className="grid-col" header="e-mail" body={rowData => rowData.email} />

              <Column
                className="grid-col-val"
                style={{ width: 105 }}
                header="CPF"
                body={rowData => rowData.cpf}
              />

              <Column
                className="grid-col-val"
                style={{ width: 108 }}
                header="Celular"
                body={rowData => rowData.celular1}
              />

              <Column
                className="grid-col-val"
                style={{ width: 108 }}
                header="Whatsapp"
                body={rowData => rowData.celular2}
              />

              <Column
                className="gid-col-acoes-35"
                bodyStyle={{ textAlign: 'end' }}
                body={renderButtonOpSocio}
              />
            </DataTableSp>
          </div>
        </div>

        <div className="p-col-12 p-fluid">
          <hr style={{ marginTop: 3 }} />
        </div>
      </>
    );
  }

  function renderButtonOpModulo(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            alterarModulo(rowData);
          },
          () => {
            excluirModulo(rowData);
          },
        ]}
        labels={['Alterar', 'Excluir']}
        icons={['pi pi-pencil', 'pi pi-trash']}
        disableds={[
          !permissaoModulos() || viewMode() || !permissaoAdminAlteracao(),
          !permissaoModulos() || viewMode() || !permissaoAdminAlteracao(),
        ]}
      />
    );
  }
  function renderButtonOpSocio(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            alterarSocio(rowData);
          },
          () => {
            excluirSocio(rowData);
          },
        ]}
        labels={['Alterar', 'Excluir']}
        icons={['pi pi-pencil', 'pi pi-trash']}
        disableds={[
          viewMode() || !permissaoAdminAlteracao(),
          viewMode() || !permissaoAdminAlteracao(),
        ]}
      />
    );
  }
}

export default ClienteCrud;
