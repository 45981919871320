/* eslint-disable no-restricted-syntax */
import { call, put, all, takeLatest } from 'redux-saga/effects';
import jwt from 'jsonwebtoken';
// eslint-disable-next-line import/no-cycle
import api from '../../../services/api';
import { loginSuccess } from './actions';
import { loadingSuccess, loading } from '../global/actions';
import { hiddenDialogLogin } from '../dialog/actions';
import authConfig from '../../../config/auth';
// eslint-disable-next-line import/no-cycle
import { errorHandle } from '../../../util/functions';

export function* doLogin({ payload, redirectTo }) {
  yield put(loading());
  const { login, senha } = payload;
  try {
    const authorization = Buffer.from(`${login}:${senha}`).toString('base64');

    const response = yield call(
      api.post,
      'sessions',
      {
        grant_type: 'password',
        login,
        senha,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${authorization}`,
        },
      }
    );

    const { token, apiInfo } = response.data;

    const decodedToken = jwt.verify(token, authConfig.secret);

    const { usuario } = decodedToken;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    localStorage.setItem(
      'apiInfo',
      JSON.stringify({
        apiVersion: apiInfo.versionNumber,
        dateLastUpdate: new Date(),
      })
    );

    yield put(loginSuccess(token, decodedToken, usuario));
    yield put(loadingSuccess());
    yield put(hiddenDialogLogin());

    if (redirectTo && redirectTo !== '') {
      window.location = redirectTo; // força recarregar a pagina
    }
  } catch (err) {
    yield put(loadingSuccess());
    // toast.error('Falha na autenticação, verifique seus dados');
    errorHandle(err);
  }
}

export function* doLoginFornecedor({ accessToken, redirectTo }) {
  yield put(loading());
  try {
    const decodedToken = jwt.verify(accessToken, authConfig.secret);

    const { usuario } = decodedToken;
    const { apiInfo } = decodedToken;

    api.defaults.headers.Authorization = `Bearer ${accessToken}`;

    localStorage.setItem(
      'apiInfo',
      JSON.stringify({
        apiVersion: apiInfo.versionNumber,
        dateLastUpdate: new Date(),
      })
    );

    yield put(loginSuccess(accessToken, decodedToken, usuario));
    yield put(loadingSuccess());
    setTimeout(() => {
      if (redirectTo && redirectTo !== '' && usuario && usuario.idFornecedorGerencial) {
        window.location = redirectTo; // força recarregar a pagina
      } else {
        window.location = '/home';
      }
    }, 800);
  } catch (err) {
    yield put(loadingSuccess());
    errorHandle('Autorização inválida.');
  }
}
export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function doLogout() {
  // if (caches) {
  //   caches.keys().then(names => {
  //     for (const name of names) {
  //       // caches.delete(name);
  //     }
  //   });
  // }
  // setTimeout(() => window.location.reload(), 600);
  setTimeout(() => (window.location = '/login'), 400);
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', doLogin),
  takeLatest('@auth/LOGIN_FORNECEDOR_REQUEST', doLoginFornecedor),
  takeLatest('@auth/LOGOUT', doLogout),
]);
