import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';

import { Container } from './styles';

import ClienteBackupService from '../../services/ClienteBackupService';

import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  formatDate,
} from '../../util/functions';

import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import LabelSp from '../../components/LabelSp';
import InputMaskSp from '../../components/InputMaskSp';
import DropDownLazy from '../../components/DropdownLazy';
import RevendaService from '../../services/RevendaService';
import DropdownSp from '../../components/DropdownSp';

export default function BackupCliente(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  const filterService = useMemo(() => ClienteBackupService.getFilter(), []);

  // useStates

  const [filter, setFilter] = useState(filterService);
  const [backupsCliente, setBackupClientees] = useState([]);

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [revendaSelecionada, setRevendaSelecionada] = useState(null);
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [tipoPessoaSelecionada, setTipoPessoaSelecionada] = useState('J');

  // useCallbacks

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(10, 14, 19);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const loadRevenda = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RevendaService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();

      try {
        const result = await ClienteBackupService.findAll({ ..._filter, resumo: 'resumo' });

        result.items.forEach(element => {
          element.dataHora = new Date(element.dataHora) || null;
          if (element.dataHora) {
            element.dataHora = formatDate(element.dataHora, 'dd/MM/yyyy hh:mm');
          }
        });

        setBackupClientees(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // functions
  function getTitle() {
    const titleDefault = 'Consulta Backup do Cliente';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o backupCliente digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  // renders
  return (
    <Container className="container-page page-enter-tab">
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert={false}
          showButtonSearch
          titleFilterDefault="Buscar por Nome"
          handleFilterDefault={text => {
            filter.nome = text;
            setFilter({ ...filter, nome: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              nome: '',
              numeroContrato: '',
              idRevenda: null,
              cpfCnpj: '',
              diasUltimoBackup: '',
              inativo: 'false',
            });
            setRevendaSelecionada(null);
          }}
          handleButtonInsert={() => history.push('/backupsCliente/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="p-col-5 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>Contrato</LabelSp>
            <InputMaskSp
              mask="999999"
              value={filter.numeroContrato}
              onChange={e => {
                setFilterAndSearch({ ...filter, numeroContrato: e?.value });
              }}
            />
          </div>
          <div className="p-col-7 p-sm-3 p-lg-3 p-fluid">
            <LabelSp>Revenda</LabelSp>
            <DropDownLazy
              autoLoad
              placeholder="Todos"
              showClear
              onChange={e => {
                setRevendaSelecionada(e);
                setFilterAndSearch({ ...filter, idRevenda: e?.value });
              }}
              value={revendaSelecionada}
              onFilter={async txtFilter => {
                const retorno = await loadRevenda(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-5 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>Tp. Pessoa</LabelSp>
            <DropdownSp
              value={tipoPessoaSelecionada}
              options={[
                { value: 'J', label: 'Jurídica' },
                { value: 'F', label: 'Física' },
              ]}
              onChange={e => {
                setTipoPessoaSelecionada(e?.value);
              }}
            />
          </div>
          {tipoPessoaSelecionada === 'F' ? (
            <div className="p-col-7 p-sm-1 p-lg-1 p-fluid">
              <LabelSp>CPF</LabelSp>
              <InputMaskSp
                mask="999.999.999-99"
                value={filter.cpfCnpj}
                onChange={e => {
                  setFilterAndSearch({ ...filter, cpfCnpj: e?.value });
                }}
              />
            </div>
          ) : (
            <div className="p-col-7 p-sm-2 p-lg-2 p-fluid">
              <LabelSp>CNPJ</LabelSp>
              <InputMaskSp
                mask="99.999.999/9999-99"
                value={filter.cpfCnpj}
                onChange={e => {
                  setFilterAndSearch({ ...filter, cpfCnpj: e?.value });
                }}
              />
            </div>
          )}
          <div className="p-col-6 p-sm-1 p-lg-1 p-fluid">
            <LabelSp>Inativo?</LabelSp>
            <DropdownSp
              value={filter.inativo}
              options={[
                { value: 'false', label: 'Ativos' },
                { value: 'true', label: 'Inativos' },
              ]}
              onChange={e => {
                setFilterAndSearch({ ...filter, inativo: e?.value });
              }}
            />
          </div>

          <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>Último Backup</LabelSp>
            <DropdownSp
              value={filter.diasUltimoBackup}
              options={[
                { value: '', label: 'Todos' },
                { value: '0', label: 'Hoje' },
                { value: '1', label: '1 ou mais dias' },
                { value: '2', label: '2 ou mais dias' },
                { value: '3', label: '3 ou mais dias' },
                { value: '5', label: '5 ou mais dias' },
                { value: '10', label: '10 ou mais dias' },
                { value: '15', label: '15 ou mais dias' },
                { value: '30', label: '30 ou mais dias' },
                { value: '60', label: '60 ou mais dias' },
                { value: '120', label: '120 ou mais dias' },
                { value: '9999', label: 'Nunca' },
              ]}
              onChange={e => {
                setFilterAndSearch({ ...filter, diasUltimoBackup: e?.value });
              }}
            />
          </div>
        </CrudHeader>
        {renderSearch()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid datatable-doc-demo">
          <DataTableSp
            value={backupsCliente}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column field="numeroContrato" header="Contrato" className="grid-col-contrato" />
            <Column field="nome" className="grid-col" header="Cliente" />
            <Column field="arquivos" className="grid-col p-p-6" header="Arquivos" />
            <Column field="dataHora" className="grid-col-data-hora " header="Últ. Backup" />
            <Column
              field="nomeRevenda"
              className="grid-col"
              style={{ width: 150 }}
              header="Revenda"
            />
            <Column
              field="diasUltimoBackup"
              className="grid-col"
              style={{ width: 84 }}
              body={rowData => {
                if (rowData.diasUltimoBackup >= 9999) {
                  return 'Nunca';
                }
                return `${rowData.diasUltimoBackup} Dias(s)`;
              }}
              header="Dias Últ.Bk"
            />
          </DataTableSp>
        </div>
      </>
    );
  }
}
