/* eslint-disable no-await-in-loop */

/**
 * Página Dashboard
 * @module Dashboard
 * @category Pages
 */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import Gradient from 'javascript-color-gradient';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
// import * as html2canvas from 'html2canvas';
// import { toast } from 'react-toastify';
import { getYear } from 'date-fns';

import { Container, ContainerTitle } from './styles';

import { errorHandle, formatDate, isScreenMobile, strToDate } from '../../util/functions';

import { cfgPtBr, listaUfs } from '../../config/Constantes';

import { showFilter, hideFilter } from '../../store/modules/global/actions';
import LabelSp from '../../components/LabelSp';
import DropDownLazy from '../../components/DropdownLazy';
import CalendarSp from '../../components/CalendarSp';

import DropdownSp from '../../components/DropdownSp';

import PanelFilter from '../../components/PanelFilter';
import Page1 from './page1';

import SelectButtons from '../../components/SelectButtons';

import MunicipioService from '../../services/MunicipioService';
import DashboardService from '../../services/DashboardService';
import UsuarioService from '../../services/UsuarioService';
import RevendaService from '../../services/RevendaService';
import Page2 from './page2';
import Page3 from './page3';
import AuthService from '../../services/AuthService';

export default function Dashboard() {
  const [revenda, setRevenda] = useState(null);
  const filterService = useMemo(() => {
    DashboardService.getFilter();
    const f = DashboardService.getFilter();

    const revendaUsuario = AuthService.getUsuario().revenda;
    if (revendaUsuario) {
      f.idRevenda = AuthService.getUsuario().idRevenda;
      setRevenda({
        value: revendaUsuario.id,
        label: revendaUsuario.nome,
      });
    }
    return f;
  }, []);

  const [filter, setFilter] = useState(filterService);
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [usuarioResponsavel, setUsuarioResponsavel] = useState(null);

  const [municipio, setMunicipio] = useState(null);
  const [ufSelecionada, setUfSelecionada] = useState('MG');

  const [dataGraficoAtMeses, setDataGraficoAtMeses] = useState();
  const [dataGraficoAtRevendas, setDataGraficoAtRevendas] = useState();
  const [dataGraficoAtUsuarios, setDataGraficoAtUsuarios] = useState();
  const [dataGraficoAtMunicipios, setDataGraficoAtMunicipios] = useState();
  const [dataGraficoAtClientes, setDataGraficoAtClientes] = useState();

  const [quantidadeAtendimentos, setQuantidadeAtendimentos] = useState(0);
  const [clientesSemAt, setClientesSemAt] = useState([]);
  const [clienteNovo, setClienteNovo] = useState([]);

  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const filterVisible = useSelector(state => state.global.filterVisible);
  function handleFilterUpdate() {
    if (filterVisible) {
      dispatch(hideFilter());
    } else {
      dispatch(showFilter());
    }
  }

  // useCallbacks
  const loadUsuario = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await UsuarioService.findAll({
        nome: _nome,
        limit: 50,
        inativo: false,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.login,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRevenda = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RevendaService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const listaAnos = useCallback(() => {
    const anosInicio = 2015;

    const anoFim = getYear(new Date());
    const lista = [];
    // const mes = formatDate(new Date(), 'MM');
    for (let i = anosInicio; i <= anoFim; i++) {
      const date = strToDate(`${i}-01-01`);
      lista.push({ label: `${i}`, value: date });
    }

    return lista;
  }, []);

  const handleBuscar = useCallback(
    async _filter => {
      try {
        const dashboard = await DashboardService.getDashBoard(_filter);

        const datasetAtMeses = [];
        const datasetAtRevenda = [];
        const datasetAtUsuario = [];
        const datasetAtMunicipio = [];
        const datasetAtCliente = [];

        let data = [];
        let labels = [];
        let backgroundColors = [];
        setClientesSemAt(dashboard.clientesSemAt);
        setClienteNovo(dashboard.clientesNovos);

        // atendimentos por mes
        dashboard.atendimentoMeses.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nomeMesAno);
          let cor =
            _filter.tipoPeriodo === 2 ? backgroundGrafico5[index] : backgroundGrafico12[index];

          if (_filter.tipoPeriodo === 2) {
            const mesAnoFiltro = _filter.mesAnoApuracao
              .toISOString()
              .substring(0, 7)
              .replace('-', '/');

            if (dado.mesAno === mesAnoFiltro) {
              cor = '#5aa853';
            }
          }
          backgroundColors.push(cor);
        });

        datasetAtMeses.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });
        const pageSelected = page;
        setPage(undefined);

        setDataGraficoAtMeses({
          labels,
          datasets: datasetAtMeses,
          records: data.length,
        });

        // por revenda
        let qtAt = 0;
        data = [];
        labels = [];
        backgroundColors = [];
        dashboard.atendimentoRevenda.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nome?.toUpperCase() || 'Não Identificado');

          backgroundColors.push(backgroundGrafico12[index]);

          qtAt += dado.quantidade;
        });

        setQuantidadeAtendimentos(qtAt);

        datasetAtRevenda.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtRevendas({
          labels,
          datasets: datasetAtRevenda,
          records: data.length,
        });

        // por usuario
        data = [];
        labels = [];
        backgroundColors = [];
        dashboard.atendimentoResponsavel.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nome?.toUpperCase() || 'Não Identificado');

          backgroundColors.push(backgroundGrafico12[index]);
        });

        datasetAtUsuario.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtUsuarios({
          labels,
          datasets: datasetAtUsuario,
          records: data.length,
        });

        // por município
        data = [];
        labels = [];
        backgroundColors = [];
        dashboard.atendimentoMunicipio.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nome?.toUpperCase() || 'Não Identificado');

          backgroundColors.push(backgroundGrafico21[index]);
        });

        datasetAtMunicipio.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtMunicipios({
          labels,
          datasets: datasetAtMunicipio,
          records: data.length,
        });

        // por cliente
        data = [];
        labels = [];
        backgroundColors = [];
        dashboard.atendimentoClienteTop20.forEach((dado, index) => {
          data.push(dado.quantidade);
          labels.push(dado.nome?.toUpperCase() || 'Não Identificado');

          backgroundColors.push(backgroundGrafico21[index]);
        });

        datasetAtCliente.push({
          fillColor: '#79D1CF',
          strokeColor: '#79D1CF',
          data,
          backgroundColor: backgroundColors,
        });

        setDataGraficoAtClientes({
          labels,
          datasets: datasetAtCliente,
          records: data.length,
        });

        setTimeout(() => setPage(pageSelected), [100]); // força render em todas na pagina atual para que o tamanho dos graficos sejam ajustados
      } catch (err) {
        errorHandle(err);
      }
    },
    [page]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  useEffect(() => {
    handleBuscar(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const buttons = isScreenMobile()
    ? ['Pág 1', 'Pág 2', 'Pág 3']
    : ['Página 1', 'Página 2', 'Página 3'];
  const colors = [1, 1, 1];

  return (
    <Container className="container-page">
      <div className="p-grid">
        <ContainerTitle className="p-col-12">
          <Button
            style={{ float: 'right' }}
            className="p-button-success buttons"
            title="Filtros Adicionais"
            icon="pi pi-filter"
            onClick={handleFilterUpdate}
          />
          <Button
            style={{ float: 'right', marginRight: 5 }}
            className="p-button-secondary buttons"
            title="Buscar"
            icon="pi pi-search"
            type="button"
            onClick={() => handleBuscar(filter)}
          />

          <SelectButtons
            className="noprint"
            style={{ float: 'right', marginRight: 5 }}
            widthButton={isScreenMobile() ? 45 : 90}
            buttons={buttons}
            colors={colors}
            selectedIndex={page}
            onClick={itemIndex => {
              setPage(itemIndex);
            }}
          />

          <div style={{ flex: 1 }}>
            <span className="title">Dashboard</span>
          </div>

          <hr />
        </ContainerTitle>
      </div>
      <div className="p-grid">
        <PanelFilter className="p-grid p-col-12 noprint">
          <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
            <LabelSp>Tipo Período</LabelSp>
            <DropdownSp
              value={filter.tipoPeriodo}
              options={[
                { label: 'Ano', value: 1 },
                { label: 'Mês/Ano', value: 2 },
              ]}
              filterInputAutoFocus={false}
              onChange={e => {
                let { mesAnoApuracao } = filter;
                if (e?.value === 1) {
                  const ano = formatDate(mesAnoApuracao, 'yyyy');
                  mesAnoApuracao = strToDate(`${ano}-01-01`);
                }
                setFilterAndSearch({ ...filter, mesAnoApuracao, tipoPeriodo: e?.value });
              }}
            />
          </div>

          {filter.tipoPeriodo === 2 && (
            <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
              <LabelSp htmlFor="dataBase">Mês Apuração</LabelSp>
              <CalendarSp
                view="month"
                appendTo={document.body}
                readOnlyInput
                locale={cfgPtBr}
                dateFormat="mm/yy"
                value={filter.mesAnoApuracao}
                yearNavigator
                yearRange="2010:2040"
                onChange={e => setFilterAndSearch({ ...filter, mesAnoApuracao: e.value })}
              />
            </div>
          )}

          {filter.tipoPeriodo === 1 && (
            <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
              <LabelSp>Ano Apuração</LabelSp>
              <DropdownSp
                value={filter.mesAnoApuracao}
                options={listaAnos()}
                filterInputAutoFocus={false}
                onChange={e => {
                  setFilterAndSearch({ ...filter, mesAnoApuracao: e?.value });
                }}
              />
            </div>
          )}

          <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Revenda do Cliente</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idRevenda: e?.value });
                setRevenda(e);
              }}
              value={revenda}
              onFilter={async txtFilter => {
                const retorno = await loadRevenda(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Responsável</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idUsuarioResponsavel: e?.value });
                setUsuarioResponsavel(e);
              }}
              value={usuarioResponsavel}
              onFilter={async txtFilter => {
                const retorno = await loadUsuario(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
            <LabelSp>UF</LabelSp>
            <DropdownSp
              placeholder="Selecione"
              value={ufSelecionada}
              options={listaUfs}
              filter
              disabled
              onChange={e => setUfSelecionada(e.target.value)}
            />
          </div>

          <div className="p-col-8 p-sm-5 p-lg-5 p-fluid">
            <LabelSp>Município</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idMunicipio: e?.value });
                setMunicipio(e);
              }}
              value={municipio}
              onFilter={async txtFilter => {
                const retorno = await loadMunicipio(txtFilter, ufSelecionada);
                return retorno;
              }}
            />
          </div>
        </PanelFilter>

        <div id="pagina" className="p-grid" style={{ margin: 0, padding: 0, width: '100%' }}>
          {page === 0 ? (
            <Page1
              quantidadeAtendimentos={quantidadeAtendimentos}
              dataGraficoAtMeses={dataGraficoAtMeses}
              dataGraficoAtRevendas={dataGraficoAtRevendas}
              dataGraficoAtUsuarios={dataGraficoAtUsuarios}
              dataGraficoAtMunicipios={dataGraficoAtMunicipios}
              dataGraficoAtClientes={dataGraficoAtClientes}
              tipoApuracao={filter.tipoApuracao}
              tipoPeriodo={filter.tipoPeriodo}
              tipoTeleEducacao={filter.teleEducacaoTipo}
            />
          ) : null}
          {page === 1 ? <Page2 clientesSemAt={clientesSemAt} /> : null}
          {page === 2 ? <Page3 clienteNovo={clienteNovo} /> : null}
        </div>
      </div>
    </Container>
  );
}

const colorGradient = new Gradient();

// backgroundGrafico5
// colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setGradient('#0470bd', '#018aec');
colorGradient.setMidpoint(4);
const backgroundGrafico5 = colorGradient.getArray();
backgroundGrafico5.push(backgroundGrafico5[backgroundGrafico5.length - 1]);

// backgroundGrafico12
colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setMidpoint(12);
const backgroundGrafico12 = colorGradient.getArray();

colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setMidpoint(10);
const backgroundGrafico2 = colorGradient.getArray();
// complementando 12 cores repetindo as duas ultimas
backgroundGrafico2.push(backgroundGrafico2[backgroundGrafico2.length - 1]);
backgroundGrafico2.push(backgroundGrafico2[backgroundGrafico2.length - 1]);

colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setMidpoint(4);

// backgroundGrafico12
colorGradient.setGradient('#013b01', '#6ecc65');
colorGradient.setMidpoint(21);
const backgroundGrafico21 = colorGradient.getArray();

// 013b01
// 015001
