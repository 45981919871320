/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-extend-native */
import React from 'react';

import 'primereact/resources/themes/nova-light/theme.css';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

// import '@fortawesome/fontawesome-free/js/all';

import './pages/_layouts/Default/css/prime-react.scss';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import './config/ReactotronConfig';

import history from './services/history';
import Routes from './routes';
import GlobalStyle from './styles/global';

import { store, persistor } from './store';
import MessageDialog from './components/MessageDialog';
import MessageDialogLogin from './components/MessageDialogLogin';
import LookupGeneric from './components/LookupGeneric';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <MessageDialog />
          <MessageDialogLogin />
          <LookupGeneric clickOnConfirm />
          <ToastContainer autoClose={2500} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
