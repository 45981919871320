import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .div-result {
    margin-top: 30px;
  }
  .result {
    font-size: 20px;
    font-weight: bold;
  }
`;
