/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { Chart } from 'primereact/chart';

import {
  optionsGraficoAtMeses,
  optionsGraficoAtendimentosRevenda,
  optionsGraficoAtendimentosResponsavel,
  optionsGraficoAtendimentosMunicipio,
  optionsGraficoAtendimentosCliente,
} from './chartConfig';

import 'chartjs-plugin-labels';
import { Card } from './styles';
import Panel from '../../components/Panel';

import GraficoView from '../../components/GraficoView';
import { calcHeightGraphiFromRecords } from '../../util/functions';

function Page1(props) {
  Page1.propTypes = {
    dataGraficoAtMeses: PropTypes.any,
    dataGraficoAtUsuarios: PropTypes.any,
    dataGraficoAtRevendas: PropTypes.any,
    dataGraficoAtClientes: PropTypes.any,
    quantidadeAtendimentos: PropTypes.number,
  };

  Page1.defaultProps = {
    required: false,
    autoFocus: false,
  };
  const {
    dataGraficoAtMeses,
    dataGraficoAtUsuarios,
    dataGraficoAtRevendas,
    dataGraficoAtMunicipios,
    dataGraficoAtClientes,
    quantidadeAtendimentos,
  } = props;

  const chartAtendimentoMes = useRef();
  const chartAtendimentoRevenda = useRef();
  const chartAtendimentoResponsavel = useRef();
  const chartAtendimentoMunicipio = useRef();
  const chartAtendimentoCliente = useRef();

  const [graficoRefSelecionado, setGraficoRefSelecionado] = useState();
  const [graficoSelecionadoOptions, setGraficoSelecionadoOptions] = useState();
  const [graficoViewVisible, setGraficoViewVisible] = useState(false);

  const pCol = 'p-col-12 p-lg-6';

  return (
    <>
      {graficoViewVisible && (
        <GraficoView
          chartRef={graficoRefSelecionado}
          visible={graficoViewVisible}
          setVisible={setGraficoViewVisible}
          options={graficoSelecionadoOptions}
        />
      )}
      <div className="p-col-12">
        <Card>
          <div className="div-title">
            <span className="title">Nr. de Atendimentos: {quantidadeAtendimentos}</span>
          </div>
          <span className="result-icon red">
            <i className="far fa-list-alt" style={{ fontSize: 32 }} />
          </span>
        </Card>
      </div>
      <div className={pCol}>
        <Panel
          header="Atendimentos por Mês"
          style={{ height: '100%' }}
          handleView={() => {
            setGraficoViewVisible(true);
            setGraficoRefSelecionado(chartAtendimentoMes);
            setGraficoSelecionadoOptions({
              ...optionsGraficoAtMeses,
              title: {
                ...optionsGraficoAtMeses.title,
                text: 'Atendimentos por Mês',
              },
            });
          }}
        >
          <Chart
            ref={chartAtendimentoMes}
            id="atendimento-mes"
            type="bar"
            data={dataGraficoAtMeses}
            options={{
              ...optionsGraficoAtMeses,
              title: {
                ...optionsGraficoAtMeses.title,
                text: 'Atendimentos por Mês',
              },
            }}
            height="180"
          />
        </Panel>
      </div>

      <div className={pCol}>
        <Panel
          header="Atendimentos por Responsável"
          style={{ height: '100%' }}
          handleView={() => {
            setGraficoViewVisible(true);
            setGraficoRefSelecionado(chartAtendimentoResponsavel);
            setGraficoSelecionadoOptions({
              ...optionsGraficoAtendimentosResponsavel,
              title: {
                ...optionsGraficoAtendimentosResponsavel.title,
                text: 'Atendimentos por Responsável',
              },
            });
          }}
        >
          <Chart
            ref={chartAtendimentoRevenda}
            id="atendimento-responsavel "
            type="horizontalBar"
            data={dataGraficoAtUsuarios}
            options={{
              ...optionsGraficoAtendimentosResponsavel,
              title: {
                ...optionsGraficoAtendimentosResponsavel.title,
                text: 'Atendimentos por Responsável',
              },
            }}
            height="180"
          />
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          header="Atendimentos por Revenda"
          style={{ height: '100%' }}
          handleView={() => {
            setGraficoViewVisible(true);
            setGraficoRefSelecionado(chartAtendimentoRevenda);
            setGraficoSelecionadoOptions({
              ...optionsGraficoAtendimentosRevenda,
              title: {
                ...optionsGraficoAtendimentosRevenda.title,
                text: 'Atendimentos por Revenda',
              },
            });
          }}
        >
          <Chart
            ref={chartAtendimentoRevenda}
            id="atendimento-revenda "
            type="horizontalBar"
            data={dataGraficoAtRevendas}
            options={{
              ...optionsGraficoAtendimentosRevenda,
              title: {
                ...optionsGraficoAtendimentosRevenda.title,
                text: 'Atendimentos por Revenda',
              },
            }}
            height={String(calcHeightGraphiFromRecords(dataGraficoAtRevendas?.records || 0))}
          />
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          header="Atendimentos por Cliente(Top 20)"
          style={{ height: '100%' }}
          handleView={() => {
            setGraficoViewVisible(true);
            setGraficoRefSelecionado(chartAtendimentoCliente);
            setGraficoSelecionadoOptions({
              ...optionsGraficoAtendimentosCliente,
              title: {
                ...optionsGraficoAtendimentosCliente.title,
                text: 'Atendimentos por Cliente',
              },
            });
          }}
        >
          <Chart
            ref={chartAtendimentoCliente}
            id="atendimento-cliente "
            type="horizontalBar"
            data={dataGraficoAtClientes}
            options={{
              ...optionsGraficoAtendimentosCliente,
              title: {
                ...optionsGraficoAtendimentosCliente.title,
                text: 'Atendimentos por Cliente',
              },
            }}
            height={String(calcHeightGraphiFromRecords(dataGraficoAtClientes?.records || 0))}
          />
        </Panel>
      </div>

      <div className="p-col-12">
        <Panel
          header="Atendimentos por Município"
          style={{ height: '100%' }}
          handleView={() => {
            setGraficoViewVisible(true);
            setGraficoRefSelecionado(chartAtendimentoMunicipio);
            setGraficoSelecionadoOptions({
              ...optionsGraficoAtendimentosMunicipio,
              title: {
                ...optionsGraficoAtendimentosMunicipio.title,
                text: 'Atendimentos por Município',
              },
            });
          }}
        >
          <Chart
            ref={chartAtendimentoMunicipio}
            id="atendimento-revenda "
            type="horizontalBar"
            data={dataGraficoAtMunicipios}
            options={{
              ...optionsGraficoAtendimentosMunicipio,
              title: {
                ...optionsGraficoAtendimentosMunicipio.title,
                text: 'Atendimentos por Município',
              },
            }}
            height={String(calcHeightGraphiFromRecords(dataGraficoAtMunicipios?.records || 0))}
          />
        </Panel>
      </div>
    </>
  );
}

export default Page1;
