import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class ChaveAdmService extends BaseService {
  constructor() {
    super(`${ConfigApi.chaveAdmURL}`);
  }

  async chaveVencimento(body) {
    delete body.id;
    this.setLoading(true);
    try {
      const response = await this.api.post(`${this.url}/chaveVencimento`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async contraChaveDispositivo(body) {
    delete body.id;
    this.setLoading(true);
    try {
      const response = await this.api.post(`${this.url}/contraChaveDispositivo`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.limit = 11;
    this.page = 0;
  }
}
export default new ChaveAdmService();
