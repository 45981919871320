/**
 * Componente conainer customizado usado nas páginas da plataforma
 * @module Panel
 * @category Componentes
 */

import React from 'react';
import PropTypes from 'prop-types';
import ButtonSp from '../ButtonSp';
import { Container } from './styles';

/**
 * @typedef PanelProps
 * @property {string} header Título do panel
 * @property {function} handleView Handle que se definido cria um botão para visualizar imagens
 */

/**
 * Componente Panel
 * @func Panel
 * @param {PanelProps} props Propriedades
 */
export default function Panel(props) {
  Panel.propTypes = {
    header: PropTypes.string,
    handleView: PropTypes.func,
  };

  const { header, handleView } = props;
  return (
    <Container className="p-panel p-component" {...props}>
      <div className="p-panel-titlebar titulo">
        <span className="p-panel-title" aria-label="pr_id_3_header">
          {header}
        </span>
        {handleView ? (
          <ButtonSp
            style={{ margin: 0, padding: 0, width: 26, height: 26 }}
            className="p-button-warning buttons"
            title="Visualizar"
            icon="pi pi-image"
            onClick={() => handleView()}
          />
        ) : null}
      </div>
      <div
        className="p-toggleable-content"
        aria-hidden="false"
        role="region"
        id="pr_id_3_content"
        aria-labelledby="pr_id_3_header"
      >
        <div className="p-panel-content">{props.children}</div>
      </div>
    </Container>
  );
}
