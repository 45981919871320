export function loginRequest(login, senha, redirectTo) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: { login, senha },
    redirectTo,
  };
}

export function loginSuccess(token, decodedToken, usuario) {
  const restrictMenu = decodedToken.usuario.idFornecedorGerencial;
  return {
    type: '@auth/LOGIN_SUCCESS',
    payload: { token, decodedToken, usuario, restrictMenu },
  };
}

export function loginFailure() {
  return {
    type: '@auth/LOGIN_FAILURE',
  };
}

export function logout() {
  return {
    type: '@auth/LOGOUT',
  };
}
