import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import RevendaService from '../../services/RevendaService';

import { errorHandle, validateFields } from '../../util/functions';
import { RevendaModel } from '../../util/Models';
import { StateScreen } from '../constants';

function RevendaCrud(props) {
  RevendaCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  RevendaCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [revenda, setRevenda] = useState(new RevendaModel());

  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  // useCallbacks

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await RevendaService.findById(_id);

      setRevenda(retorno);

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await RevendaService.insert(revenda);
      } else {
        retorno = await RevendaService.update(revenda);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new RevendaModel();

      setRevenda(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={revenda.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setRevenda({ ...revenda, nome: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              keyAccess="S"
              disabled={!validateFields(revenda, ['nome'])}
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            keyAccess="V"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default RevendaCrud;
