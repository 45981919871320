/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import { Container } from './styles';

export default function Home() {
  const paddingTop = (window.innerHeight - 300) / 2 - 20;
  const logo = require('../../assets/images/logo.png');

  return (
    <Container>
      <div className="p-grid" style={{ margin: 0, padding: 0, paddingTop }}>
        <div className="p-col-12">
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <img alt="Logo" src={logo} style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </div>
        </div>
      </div>

      {/* <div className="logo-speedy">
        <span>Desenvolvido por:</span>
        <img alt="Logo Speedy" src={logoSpeedy} />

        <a
          href="http://www.speedysistemas.com.br"
          target="_blank"
          title="Especialida em desenvolvimento Web/Mobile e Desktop"
        >
          www.speedysistemas.com.br
        </a>
      </div> */}
    </Container>
  );
}
