import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import AtendClassificacaoService from '../../services/AtendClassificacaoService';
import InputCurrencySp from '../../components/InputCurrencySp';
import { errorHandle, validateFields } from '../../util/functions';
import { AtendimentoClassificacaoModel } from '../../util/Models';
import { StateScreen } from '../constants';
import DropDownLazy from '../../components/DropdownLazy';
import AtendTopicoClassificadorService from '../../services/AtendTopicoClassificadorService';
import DropdownSp from '../../components/DropdownSp';

function AtendimentoClassificacaoCrud(props) {
  AtendimentoClassificacaoCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  AtendimentoClassificacaoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [atendimentoClassificacao, setAtendimentoClassificacao] = useState(
    new AtendimentoClassificacaoModel()
  );

  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [topicoSelecionado, setTopicoSelecionado] = useState();

  // useCallbacks
  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await AtendClassificacaoService.findById(_id);

      setAtendimentoClassificacao({ ...retorno, atendTopicoClassificador: retorno.topico });

      const topico = retorno.topico
        ? { value: retorno.topico.id, label: retorno.topico.descricao }
        : null;

      setTopicoSelecionado(topico);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  const loadTopico = useCallback(async descricao => {
    if (descricao !== undefined) {
      const r = await AtendTopicoClassificadorService.findAll({
        descricao,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.descricao,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await AtendClassificacaoService.insert(atendimentoClassificacao);
      } else {
        retorno = await AtendClassificacaoService.update(atendimentoClassificacao);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new AtendimentoClassificacaoModel();
      setTopicoSelecionado(null);

      setAtendimentoClassificacao(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
        <LabelSp>Tópico</LabelSp>
        <DropDownLazy
          autoLoad
          disabled={viewMode()}
          required
          placeholder="Selecione"
          onChange={e => {
            const atendTopicoClassificador = e ? { id: e.value, nome: e.label } : null;
            setAtendimentoClassificacao({
              ...atendimentoClassificacao,
              atendTopicoClassificador,
              idAtendTopicoClassificador: e?.value || null,
            });
          }}
          value={topicoSelecionado}
          onFilter={async txtFilter => {
            const retorno = await loadTopico(txtFilter);
            return retorno;
          }}
        />
      </div>
      <div className="p-col-12 p-sm-8 p-lg-8 p-fluid">
        <LabelSp>Descrição</LabelSp>
        <InputTextSp
          autoFocus
          value={atendimentoClassificacao.descricao}
          maxLength={100}
          required
          disabled={viewMode()}
          onChange={e => {
            setAtendimentoClassificacao({
              ...atendimentoClassificacao,
              descricao: e.target.value,
            });
          }}
        />
      </div>

      <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Grau Complexidade</LabelSp>
        <InputCurrencySp
          digits={0}
          value={atendimentoClassificacao.grauComplexidade}
          onChange={(e, v) => {
            setAtendimentoClassificacao({
              ...atendimentoClassificacao,
              grauComplexidade: v,
            });
          }}
        />
      </div>

      <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Tempo estimado(Min.)</LabelSp>
        <InputCurrencySp
          digits={0}
          value={atendimentoClassificacao.tempoEstimado}
          onChange={(e, v) => {
            setAtendimentoClassificacao({
              ...atendimentoClassificacao,
              tempoEstimado: v,
            });
          }}
        />
      </div>

      <div className="p-col-6 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Permite Alterar Descrição</LabelSp>
        <DropdownSp
          options={[
            { value: true, label: 'Sim' },
            { value: false, label: 'Não' },
          ]}
          disabled={viewMode()}
          placeholder="Selecione"
          value={atendimentoClassificacao.permiteAlterarDescricao}
          onChange={e => {
            setAtendimentoClassificacao({
              ...atendimentoClassificacao,
              status: e.target.value,
            });
          }}
        />
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            keyAccess="S"
            disabled={
              !validateFields(atendimentoClassificacao, [
                'descricao',
                'atendTopicoClassificador',
              ])
            }
            showConfirmation
            onClick={handleSave}
          />
        ) : null}
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          icon="pi pi-chevron-circle-left"
          keyAccess="V"
          onClick={handleBack}
        />
      </div>
    </>
  );
}

export default AtendimentoClassificacaoCrud;
