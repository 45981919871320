import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class ChamadoService extends BaseService {
  constructor() {
    super(`${ConfigApi.chamadoURL}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.idRevenda = undefined;
    this.idColaborador = undefined;
    this.idCliente = undefined;
    this.dataInicio = addMonths(new Date(), -12);
    this.dataFim = new Date();
    this.listaStatus = '["0", "1"]';

    this.resumo = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new ChamadoService();
