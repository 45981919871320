import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';

import ChaveService from '../../services/ChaveService';
import AuthService from '../../services/AuthService';

import { formatDate, isDate, isScreenMobile, strToDate } from '../../util/functions';

import InputMaskSp from '../../components/InputMaskSp';
import ButtonSp from '../../components/ButtonSp';
import LabelSp from '../../components/LabelSp';

import InputTextSp from '../../components/InputTextSp';
import { store } from '../../store';
import ClienteService from '../../services/ClienteService';
import { showLookup } from '../../store/modules/dialog/actions';
import DropdownSp from '../../components/DropdownSp';
import ChaveAdm from './chaveAdm';

export default function Chave() {
  // useSelectors
  const [chave, setChave] = useState();
  const [contrato, setContrato] = useState('');
  const [nomeRazao, setNomeRazao] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [numeroDispositivos, setNumeroDispositivos] = useState(0);
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [edicao, setEdicao] = useState(0);
  const [contratoSelecionado, setContratoSelecionado] = useState('');
  const [qtdDebitos, setQtdDebitos] = useState(0);
  const [inativo, setInativo] = useState(false);

  const chaveAdm = AuthService.checkRoles(['CHAVE_GERAR']);

  // useCallbacks
  const handleBuscar = useCallback(
    async _contrato => {
      if (chaveAdm) {
        setContratoSelecionado('');
        setTimeout(() => {
          setContratoSelecionado(_contrato);
        }, 300);
      }

      if (!_contrato) {
        return;
      }

      try {
        const result = await ChaveService.findByContrato(_contrato);

        if (isDate(result.vencimento)) {
          result.vencimentoStr = formatDate(result.vencimento, 'dd/MM/yyyy');
          result.vencimento = strToDate(result.vencimento);
        }

        setChave(result);
        setNomeRazao(result.nomeCliente);
        setNomeFantasia(result.nomeFantasiaCliente);
        setNumeroDispositivos(result.numeroDispositivos);
        setCpfCnpj(result.cpfCnpj);
        setEdicao(result.edicao);
        setQtdDebitos(result.qtdDebitos);
        setInativo(result.inativo);
      } catch (err) {
        // errorHandle(err);
        setChave(null);
      }
    },
    [chaveAdm]
  );

  // functions
  function getTitle() {
    const titleDefault = 'Chave';
    const titleAdd = '';

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  // useEffects
  useEffect(() => {
    // desativado para evitar que a cada vez que o chave digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel

  const lookupCliente = () => {
    const { dispatch } = store;
    const lookupConfig = ClienteService.getLookupConfig(objReturn => {
      const { nome, numeroContrato } = objReturn;

      setContrato(`${numeroContrato}`);
      setNomeRazao(nome);
      setNomeFantasia(objReturn.nomeFantasia);

      setQtdDebitos(objReturn.qtdDebitos);
      setInativo(objReturn.inativo);

      handleBuscar(`${numeroContrato}`);
    });

    dispatch(showLookup(lookupConfig));
  };

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <div className="p-col-8">
          <span className="title">{getTitle()}</span>
        </div>
        <div className="p-col-4" style={{ margin: 0, padding: 0, textAlign: 'end' }}>
          <ButtonSp
            type="button"
            icon="pi pi-search"
            className="p-button-secondary buttons"
            title="Buscar Chave"
            onClick={() => handleBuscar(contrato)}
          />
        </div>
        <div className="p-col-12">
          <hr />
        </div>

        <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Nr. Contrato</LabelSp>
          <div className="p-inputgroup">
            <InputMaskSp
              value={contrato}
              mask="999999"
              type="tel"
              onChange={e => {
                setContrato(e.target.value);
              }}
              onComplete={e => {
                handleBuscar(e.value);
              }}
            />
          </div>
        </div>

        <div className="p-col-12 p-md-5 p-lg-5 p-fluid">
          <LabelSp>Cliente</LabelSp>
          <div className="p-inputgroup">
            <ButtonSp
              type="button"
              icon="pi pi-search"
              className="p-button-secondary buttons"
              title="Buscar Cliente"
              onClick={() => lookupCliente()}
            />
            <InputTextSp value={nomeRazao || ''} disabled />
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
          <LabelSp>Nome Fantasia</LabelSp>
          <InputTextSp value={nomeFantasia || ''} disabled />
        </div>

        <div className="p-col-6 p-md-3 p-lg-3 p-fluid">
          <LabelSp>CPF/CNPJ</LabelSp>
          <InputTextSp value={cpfCnpj || ''} disabled />
        </div>

        <div className="p-col-6 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Edição</LabelSp>
          <DropdownSp
            options={[
              { label: 'Business', value: 0 },
              { label: 'Professional', value: 1 },
              { label: 'Lite', value: 2 },
              { label: 'Nfe', value: 3 },
            ]}
            disabled
            required
            value={edicao}
          />
        </div>

        <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
          <LabelSp>Nr. Dispositivos</LabelSp>
          <InputTextSp value={numeroDispositivos || ''} disabled />
        </div>

        <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
          <LabelSp>Nr. Débitos</LabelSp>
          <InputTextSp value={`${qtdDebitos}`} disabled />
        </div>
        <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
          <LabelSp>Inativo</LabelSp>
          <InputTextSp value={inativo ? 'Sim' : 'Não'} readOnly />
        </div>

        <div className="p-col-12 div-result">
          <span className="result">Resultado:</span>
        </div>
        <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
          <LabelSp>Vencimento</LabelSp>
          <InputTextSp value={chave?.vencimentoStr} readOnly />
        </div>

        <div className="p-col-12 p-md-9 p-lg-9 p-fluid">
          <LabelSp>Chave</LabelSp>
          <InputTextSp value={chave?.chaveLiberacao} readOnly />
        </div>
      </div>
      {chaveAdm ? (
        <ChaveAdm
          numeroContrato={contratoSelecionado}
          vencimentoChaveAtual={chave?.vencimento}
          numeroDebitos={qtdDebitos}
          inativo={inativo}
        />
      ) : null}
    </Container>
  );
}
