import React from 'react';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function ImgPdfView(props) {
  ImgPdfView.propTypes = {
    title: PropTypes.string,
    src: PropTypes.string,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
  };

  ImgPdfView.defaultProps = {
    title: '',
  };

  const { title, src, visible, setVisible } = props;

  return (
    <Container>
      <Dialog
        closable
        closeOnEscape
        header={title}
        visible={visible}
        contentStyle={{ margin: 0, padding: 0 }}
        style={{ minWidth: '350px', width: '95%' }}
        modal
        blockScroll
        onHide={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
      >
        <embed
          src={src}
          frameBorder="0"
          scrolling="auto"
          width="100%"
          className="responsive"
        />
      </Dialog>
    </Container>
  );
}
