import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { Column } from 'primereact/column';

import PropTypes from 'prop-types';
import { Container } from './styles';

import LabelSp from '../LabelSp';
import DataTableSp from '../DataTableSp';
import { errorHandle, padLeft } from '../../util/functions';

import InputTextSp from '../InputTextSp';
import DropdownSp from '../DropdownSp';
import AtendTopicoClassificadorService from '../../services/AtendTopicoClassificadorService';
import AtendClassificacaoService from '../../services/AtendClassificacaoService';

export default function LookupClassificacaoAt(props) {
  LookupClassificacaoAt.propTypes = {
    clickOnConfirm: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleConfirm: PropTypes.func,
    lookupVisible: PropTypes.bool,
  };

  LookupClassificacaoAt.defaultProps = {
    clickOnConfirm: false,
  };

  const { clickOnConfirm, handleCancel, handleConfirm, lookupVisible } = props;

  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [dropDownList, setDropDownList] = useState([]);

  const [records, setRecords] = useState([]);

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [funcTimeOut, setFuncTimeOut] = useState();

  const filterService = useMemo(() => AtendClassificacaoService.getFilter(), []);

  const [filter, setFilter] = useState(filterService);

  const inputRef = useMemo(() => {
    return React.createRef();
  }, []);

  function close() {
    if (handleCancel) {
      handleCancel();
    }
  }

  const loadTopicos = useCallback(async () => {
    const result = await AtendTopicoClassificadorService.findAll({});

    const topicos = result.items.map(e => {
      return { value: e.id, label: e.descricao };
    });
    setDropDownList(topicos);
  }, []);

  const handleSearch = useCallback(async (_filterValue, _page, resetPage = true) => {
    try {
      _filterValue.page = _page || 0;
      _filterValue.limit = 10;
      const result = await AtendClassificacaoService.findAll(_filterValue);

      setRecords(result.items);
      setSelectedRecord(null);
      setTotalRecords(result.totalRecords);
      if (resetPage) {
        setFirst(0);
      }
      // inputRef.current.element.select();
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  function onPage(event) {
    const pagina = event.first / event.rows;
    setFirst(event.first);
    handleSearch(filter, pagina, false);
  }

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleSearch(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleSearch]
  );

  // useEffects
  useEffect(() => {
    loadTopicos();

    setRecords([]);
    setSelectedRecord(null);

    handleSearch(filter, 0, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTopicos, lookupVisible]);

  // renders
  const footer = (
    <div style={{ textAlign: 'center' }}>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          close();
        }}
        className="p-button-danger"
      />
      <Button
        label="Confirmar"
        icon="pi pi-check"
        disabled={!selectedRecord}
        onClick={() => {
          handleConfirm(selectedRecord);
        }}
      />
    </div>
  );

  if (!lookupVisible) {
    return null;
  }

  return (
    <Container>
      <Dialog
        closeOnEscape
        closable
        footer={!clickOnConfirm ? footer : null}
        header="Localiza classificação"
        visible={lookupVisible}
        contentStyle={{ minHeight: '400px' }}
        style={{ minWidth: '380px', maxWidth: '1024px', width: '95%' }}
        modal
        onHide={() => {
          close();
        }}
      >
        <form>
          <div className="p-grid">
            <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
              <LabelSp>Tópico</LabelSp>
              <DropdownSp
                placeholder="Todos"
                showClear
                options={dropDownList}
                value={filter.idAtendTopicoClassificador}
                onChange={async e => {
                  setFilterAndSearch({ ...filter, idAtendTopicoClassificador: e.value });
                }}
              />
            </div>
            <div className="p-col-12 p-sm-8 p-lg-8 p-fluid">
              <LabelSp>Descrição</LabelSp>

              <div className="p-inputgroup">
                <InputTextSp
                  ref={inputRef}
                  value={filter.descricao}
                  maxLength={40}
                  onChange={e => {
                    setFilterAndSearch({ ...filter, descricao: e.target.value });
                  }}
                  autoFocus
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      handleSearch(filter);
                    }
                  }}
                />
                <Button
                  className="p-button-secondary buttons"
                  title="Buscar"
                  icon="pi pi-search"
                  type="button"
                  onClick={() => {
                    handleSearch(filter);
                  }}
                />
              </div>
            </div>
            <div className="p-col-12 p-fluid datatable-doc-demo">
              <DataTableSp
                value={records}
                style={{ marginBottom: '2px' }}
                paginator
                rows={10}
                lazy
                selectionMode="single"
                responsive
                selection={selectedRecord}
                onSelectionChange={e => {
                  setSelectedRecord(e.value);
                  if (handleConfirm) {
                    handleConfirm(e.value);
                  }
                }}
                totalRecords={totalRecords}
                first={first}
                onPage={onPage}
              >
                <Column
                  field="id"
                  header="Id"
                  className="grid-col-id-p"
                  body={rowData => padLeft(rowData.id, 5)}
                />
                <Column
                  field="topicoDescricao"
                  header="Tópico"
                  className="grid-col"
                  style={{ width: 250 }}
                />
                <Column field="descricao" header="Descrição" className="grid-col" />
              </DataTableSp>
            </div>
          </div>
        </form>
      </Dialog>
    </Container>
  );
}
