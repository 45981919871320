import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { Container } from './styles';

import LabelSp from '../../components/LabelSp';

import { cfgPtBr } from '../../config/Constantes';
import InputTextareaSp from '../../components/InputTextareaSp';
import CalendarSp from '../../components/CalendarSp';

function Contato(props) {
  const { visible, handleConfirm, handleClose, contato } = props;
  const contatoTemp = contato || { dataContato: new Date(), observacao: '' };
  const [contatoSelecionado, setContatoSelecionado] = useState({ ...contatoTemp });

  // useCalback
  const footer = (
    <div>
      <Button
        label="Confirmar"
        icon="pi pi-check"
        disabled={!contatoSelecionado.dataContato && !contatoSelecionado.observacao}
        onClick={() => {
          if (handleConfirm) {
            handleConfirm(contatoSelecionado);
          }
        }}
        className="p-button-success"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          if (handleClose) {
            handleClose();
          }
        }}
        className="p-button-danger"
      />
    </div>
  );

  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header="Contato"
        visible={visible}
        style={{ minWidth: '350px', maxWidth: '600px', width: '95%' }}
        modal
        onHide={() => {
          if (handleClose) {
            handleClose();
          }
        }}
      >
        <div className="p-col-12 p-fluid" style={{ margin: 0, padding: 0 }}>
          <div className="p-grid" style={{ margin: 0, padding: 0 }}>
            <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
              <LabelSp>Próx. Contato</LabelSp>
              <CalendarSp
                showOnFocus={false}
                showIcon
                required
                locale={cfgPtBr}
                dateFormat="dd/mm/yy"
                value={contatoSelecionado.dataContato}
                appendTo={window.body}
                onChange={e => {
                  setContatoSelecionado({
                    ...contatoSelecionado,
                    dataContato: e.target.value,
                  });
                }}
              />
            </div>

            <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
              <LabelSp>Observação</LabelSp>
              <InputTextareaSp
                required
                autoResize={false}
                maxLength={2000}
                rows={5}
                resize={false}
                value={contatoSelecionado.observacao}
                onChange={e => {
                  setContatoSelecionado({ ...contatoSelecionado, observacao: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Container>
  );
}
export default Contato;
