import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class ClienteBackupService extends BaseService {
  constructor() {
    super(`${ConfigApi.clienteBackupURL}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.idRevenda = undefined;
    this.idMunicipio = undefined;
    this.numeroContrato = 0;
    this.cpfCnpj = '';
    this.inativo = 'false';
    this.diasUltimoBackup = '';

    this.resumo = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new ClienteBackupService();
