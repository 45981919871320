import React, { useEffect, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import PropTypes from 'prop-types';
// import { Container } from './styles';

export default function CalendarSp(props) {
  CalendarSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
  };

  CalendarSp.defaultProps = {
    required: false,
    disabled: false,
  };

  const calendarRef = useRef();

  const { value, required, disabled, autoFocus, className, ...rest } = props;

  useEffect(() => {
    if (
      autoFocus &&
      calendarRef.current &&
      calendarRef.current.inputElement &&
      calendarRef.current.inputElement.focus
    ) {
      setTimeout(() => calendarRef.current.inputElement.focus(), 200);
    }
  }, [autoFocus]);

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return (
    <Calendar
      ref={calendarRef}
      {...rest}
      disabled={disabled}
      value={value || ''}
      className={classNames}
    />
  );
}
