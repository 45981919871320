/**
 * Componente para visualização de um gráfico, com opção de amplicar, e salvar
 * @module GraficoView
 * @category Componentes
 */

import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';

import { Chart } from 'primereact/chart';

import { Container } from './styles';
import { saveGraphic } from '../../util/functions';
import LabelSp from '../LabelSp';
import DropdownSp from '../DropdownSp';
import ButtonSp from '../ButtonSp';

/**
 * @typedef GraficoViewProps
 * @property {any} chartRef Referência do gráfico
 * @property {boolean} visible Flag para mostrar ou não a tela do GraficoView
 * @property {function} setVisible Handle para controle do visible
 * @property {objectt} options Options do chart
 * @property {number} heightGraficoOriginal Tamanho original do gráfico
 */

/**
 * Componente GraficoView
 * @func GraficoView
 * @param {GraficoViewProps} props Propriedades
 */
export default function GraficoView(props) {
  GraficoView.propTypes = {
    chartRef: PropTypes.object.isRequired,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    options: PropTypes.object,
    heightGraficoOriginal: PropTypes.string,
  };

  GraficoView.defaultProps = {
    visible: false,
    heightGraficoOriginal: 180,
  };

  const { chartRef, visible, setVisible, options, heightGraficoOriginal } = props;
  const chartRefLocal = useRef();
  const title = chartRef ? chartRef.current.chart.options.title.text : '';

  const [widthGrafico, setWidthGrafico] = useState();
  const [heightGrafico, setHeightGrafico] = useState();

  const [dimensaoGrafico, setDimensaoGrafico] = useState({
    width: 0,
    height: 100,
  });

  if (options) {
    if (options.title) {
      options.title.display = true;
    }
  }

  useEffect(() => {
    const w = chartRef.current.chart.width;
    setWidthGrafico(w);
    const h = chartRef.current.chart.height;
    setHeightGrafico(h);

    setDimensaoGrafico({
      width: w,
      height: h,
    });
    // executa so uma vez
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // renders

  return (
    <Container>
      <Dialog
        closable
        closeOnEscape
        header={title}
        // footer={footer}
        visible={visible}
        contentStyle={{ minHeight: '400px' }}
        style={{ minWidth: '350px', maxWidth: '95%' }}
        modal
        blockScroll
        onHide={() => {
          if (setVisible) {
            setVisible(false);
          }
        }}
      >
        <div
          style={{
            width: dimensaoGrafico.width,
            height: dimensaoGrafico.height,
          }}
        >
          {chartRef && (
            <>
              <div className="p-grid">
                <div className="p-col-12 p-lg-12" style={{ width: 200 }}>
                  <LabelSp>Tamanho da imagem</LabelSp>
                  <DropdownSp
                    style={{ width: '100%' }}
                    value={dimensaoGrafico}
                    options={[
                      {
                        label: '30% Reduzido',
                        value: {
                          width: widthGrafico * 0.7,
                          height: heightGrafico * 0.7,
                        },
                      },
                      {
                        label: 'Original',
                        value: {
                          width: widthGrafico,
                          height: heightGrafico,
                        },
                      },
                      {
                        label: '30% Apliado',
                        value: {
                          width: widthGrafico * 1.3,
                          height: heightGrafico * 1.3,
                        },
                      },
                      {
                        label: '50% Apliado',
                        value: {
                          width: widthGrafico * 1.5,
                          height: heightGrafico * 1.5,
                        },
                      },
                      {
                        label: '100% Apliado',
                        value: {
                          width: widthGrafico * 2,
                          height: heightGrafico * 2,
                        },
                      },
                    ]}
                    filterInputAutoFocus={false}
                    onChange={e => {
                      setDimensaoGrafico(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <LabelSp>&nbsp;</LabelSp>
                  <ButtonSp
                    style={{ marginTop: 3 }}
                    title="Salvar"
                    icon="pi pi-save"
                    onClick={() => {
                      saveGraphic(chartRefLocal, chartRef.current.props.id);
                    }}
                  />
                </div>
              </div>
              <div>
                <Chart
                  ref={chartRefLocal}
                  type={chartRef.current.chart.config.type}
                  data={chartRef.current.chart.config.data}
                  options={options}
                  height={String(Number(heightGraficoOriginal) + 16)}
                />
              </div>
            </>
          )}
        </div>
      </Dialog>
    </Container>
  );
}
