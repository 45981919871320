import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class ModulosService extends BaseService {
  constructor() {
    super(`${ConfigApi.moduloURL}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.limit = 11;
    this.page = 0;
  }
}
export default new ModulosService();
