import React, { useEffect, useMemo } from 'react';
import { InputMask } from 'primereact/inputmask';
import PropTypes from 'prop-types';
// import { Container } from './styles';

const InputMaskSp = React.forwardRef((props, ref) => {
  InputMaskSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onInputBlur: PropTypes.func,
  };

  InputMaskSp.defaultProps = {
    required: false,
    className: '',
    disabled: false,
  };

  const { value, required, disabled, className, onBlur, ...rest } = props;

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';

  const inputRef = useMemo(() => {
    if (ref) {
      return ref;
    }
    return React.createRef();
  }, [ref]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.onBlur = onBlur;
    }
  }, [inputRef, onBlur]);

  return (
    <InputMask
      ref={inputRef}
      autoComplete="none"
      {...rest}
      disabled={disabled}
      value={value || ''}
      className={classNames}
    />
  );
});

export default InputMaskSp;
