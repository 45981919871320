class TipoColaborador {
  constructor() {
    this.PROGRAMADOR = 0;
    this.SUPORTE = 1;
    this.VENDEDOR = 2;
  }

  get(value) {
    switch (value) {
      case 0:
        return 'PROGRAMADOR';
      case 1:
        return 'SUPORTE';
      case 2:
        return 'VENDEDOR';
      default:
        return '';
    }
  }

  getLabel(value) {
    switch (value) {
      case 'PROGRAMADOR':
        return 'Programador';
      case 'SUPORTE':
        return 'Suporte';
      case 'Vendedor':
        return 'VENDEDOR';
      default:
        return '';
    }
  }

  getTiposDropdown() {
    return [
      { value: 'PROGRAMADOR', label: 'Programador' },
      { value: 'SUPORTE', label: 'Suporte' },
      { value: 'VENDEDOR', label: 'Vendedor' },
    ];
  }

  getTiposDropdownConsulta() {
    const lista = this.getTiposDropdown();

    return [{ value: null, label: 'Todos' }, ...lista];
  }
}

export default new TipoColaborador();
