import React from 'react';

import PropTypes from 'prop-types';
import { ListBox } from 'primereact/listbox';
// import { Container } from './styles';

const ListBoxSp = props => {
  ListBoxSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  ListBoxSp.defaultProps = {
    required: false,
    autoFocus: false,
  };

  const { required, disabled, className, ...rest } = props;

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return <ListBox {...rest} disabled={disabled} className={classNames} />;
};

export default ListBoxSp;
