/* eslint-disable jsx-a11y/no-access-key */
import React, { useEffect, useMemo } from 'react';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { showMessage } from '../MessageDialog';

const ButtonSp = React.forwardRef((props, ref) => {
  ButtonSp.propTypes = {
    onClick: PropTypes.func,
    messageConfirmation: PropTypes.string,
    showConfirmation: PropTypes.bool,
    keyAccess: PropTypes.string,
  };

  ButtonSp.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const btnRef = useMemo(() => {
    if (ref) {
      return ref;
    }
    return React.createRef();
  }, [ref]);

  const { messageConfirmation, showConfirmation, onClick, keyAccess, label, ...rest } = props;

  function handleClick() {
    if (showConfirmation) {
      showMessage('Confirmação', messageConfirmation || 'Confirma os dados?', idx => {
        if (idx === 1) {
          onClick();
        }
      });
    } else {
      onClick();
    }
  }

  useEffect(() => {
    if (
      btnRef.current &&
      btnRef.current.element &&
      keyAccess &&
      label &&
      label.toLowerCase().includes(keyAccess.toLowerCase())
    ) {
      const idx = label.toLowerCase().indexOf(keyAccess.toLowerCase());
      const ele = btnRef.current.element.getElementsByClassName('p-button-text p-c')[0];
      if (ele) {
        // desmembrando texto
        const text1 = label.substring(0, idx + 1);
        const text2 = label.substring(idx + 1, label.length);

        // criando elementos
        const elementP = document.createElement('u');
        const textFirt = document.createTextNode(text1);
        const textLast = document.createTextNode(text2);

        ele.removeChild(ele.firstChild);
        elementP.appendChild(textFirt);
        ele.appendChild(elementP);
        ele.appendChild(textLast);
      }
    }
  }, [btnRef, keyAccess, label]);

  return (
    <Button
      ref={btnRef}
      {...rest}
      accessKey={keyAccess}
      label={label}
      onClick={() => {
        if (onClick) {
          handleClick();
        }
      }}
    />
  );
});

export default ButtonSp;
