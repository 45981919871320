import React, { useState } from 'react';

import { Container } from './styles';

export default function SelectButtons(props) {
  const buttons = props.buttons || ['Button1', 'Button2'];
  const { widthButton } = props;

  const [selectedItem, setSelectedItem] = useState(props.selectedIndex);

  const { onClick, style, className } = props;

  return (
    <Container className={className || {}} style={{ ...style }}>
      {buttons.map((e, idx) => montaBotao(e, idx))}
    </Container>
  );

  function montaBotao(elemet, idx) {
    const styleLocal = {};
    if (widthButton) {
      styleLocal.width = widthButton;
    } else {
      styleLocal.flex = 1;
    }

    if (idx === selectedItem) {
      return (
        <button
          key={idx}
          id={idx}
          className="button-unselected"
          style={{ ...styleLocal }}
          onClick={() => {
            setSelectedItem(idx);
            if (onClick) {
              onClick(idx);
            }
          }}
        >
          {elemet}
        </button>
      );
    }
    return (
      <button
        key={idx}
        id={idx}
        className="button-selected"
        style={{ ...styleLocal }}
        onClick={() => {
          setSelectedItem(idx);
          if (onClick) {
            onClick(idx);
          }
        }}
      >
        {elemet}
      </button>
    );
  }
}
