/* eslint-disable jsx-a11y/no-access-key */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Column } from 'primereact/column';
import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import AcompanhamentoVendaService from '../../services/AcompanhamentoVendaService';
import { AcompanhamentoVendaModel } from '../../util/Models';

import {
  cloneObj,
  errorHandle,
  formatDate,
  isDate,
  newDateBr,
  strToDate,
  validateFields,
} from '../../util/functions';

import { StateScreen } from '../constants';
import DropdownSp from '../../components/DropdownSp';

import DropDownLazy from '../../components/DropdownLazy';
import CalendarSp from '../../components/CalendarSp';
import AuthService from '../../services/AuthService';
import RevendaService from '../../services/RevendaService';

import ColaboradorService from '../../services/ColaboradorService';
import CampanhaService from '../../services/CampanhaService';
import { cfgPtBr, listaStatus, listaUfs } from '../../config/Constantes';

import InputMaskSp from '../../components/InputMaskSp';
import MunicipioService from '../../services/MunicipioService';
import Telefones from './telefones';
import Contato from './contato';
import DataTableSp from '../../components/DataTableSp';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';

function AcompanhamentoVendaCrud(props) {
  AcompanhamentoVendaCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  AcompanhamentoVendaCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  const revendaUsuario = AuthService.getUsuario().revenda
    ? {
        label: AuthService.getUsuario().revenda.nome,
        value: AuthService.getUsuario().revenda.id,
      }
    : {
        label: 'Speedy',
        value: 1,
      };

  const colaboradorUsuario = AuthService.getUsuario().colaborador
    ? {
        label: AuthService.getUsuario().colaborador.nome,
        value: AuthService.getUsuario().colaborador.id,
      }
    : null;

  // states
  const [acompanhamentoVenda, setAcompanhamentoVenda] = useState(
    new AcompanhamentoVendaModel()
  );
  const [revendaSelecionada, setRevendaSelecionada] = useState(revendaUsuario);
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [campanhas, setCampanhas] = useState([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState();
  const [classificacoesSelecionadas, setClassificacoesSelecionadas] = useState([]);
  const [ufSelecionada, setUfSelecionada] = useState('MG');
  const [municipioSelecionado, setMunicipioSelecionado] = useState();
  const [showTelefones, setShowTelefones] = useState(false);
  const [showContato, setShowContato] = useState(false);
  const [contatoSelecionado, setContatoSelecionado] = useState();

  // useCallbacks

  const salvarContato = useCallback(
    contato => {
      if (contatoSelecionado) {
        contatoSelecionado.dataContato = contato.dataContato;
        contatoSelecionado.observacao = contato.observacao;
      } else {
        acompanhamentoVenda.acompanhamentoVendaHistorico.push(contato);
      }
      setAcompanhamentoVenda({
        ...acompanhamentoVenda,
        acompanhamentoVendaHistorico: [...acompanhamentoVenda.acompanhamentoVendaHistorico],
      });
    },
    [acompanhamentoVenda, contatoSelecionado]
  );

  const excluirContato = useCallback(
    rowData => {
      const idx = acompanhamentoVenda.acompanhamentoVendaHistorico.findIndex(
        e => e === rowData
      );
      if (idx) {
        acompanhamentoVenda.acompanhamentoVendaHistorico.splice(idx, 1);
        setAcompanhamentoVenda({
          ...acompanhamentoVenda,
          acompanhamentoVendaHistorico: [...acompanhamentoVenda.acompanhamentoVendaHistorico],
        });
      }
    },
    [acompanhamentoVenda]
  );

  const salvaListaTelefones = useCallback(
    lista => {
      let acompanhamentoVendaTelefoneTemp = acompanhamentoVenda.acompanhamentoVendaTelefone;
      acompanhamentoVendaTelefoneTemp = lista.map(e => {
        return {
          id: e.id,
          idAcompanhamentoVenda: acompanhamentoVenda.id,
          telefone: e.telefone,
        };
      });

      setAcompanhamentoVenda({
        ...acompanhamentoVenda,
        acompanhamentoVendaTelefone: acompanhamentoVendaTelefoneTemp,
      });
    },
    [acompanhamentoVenda]
  );

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await AcompanhamentoVendaService.findById(_id);
      retorno.dataProximoContato = newDateBr(retorno.dataProximoContato);

      const revenda = retorno.revenda
        ? {
            label: retorno.revenda.nome,
            value: retorno.revenda.id,
          }
        : null;

      const colaborador = retorno.colaborador
        ? {
            label: retorno.colaborador.nome,
            value: retorno.colaborador.id,
          }
        : null;

      if (isDate(retorno.dataLancamento)) {
        retorno.horaLancamento = strToDate(
          `${retorno.dataLancamento} ${retorno.horaLancamento}`,
          'yyyy-MM-dd HH:mm:ss'
        );

        retorno.dataLancamento = strToDate(retorno.dataLancamento);
      }

      if (isDate(retorno.dataFechamento)) {
        retorno.horaFechamento = strToDate(
          `${retorno.dataFechamento} ${retorno.horaFechamento}`,
          'yyyy-MM-dd HH:mm:ss'
        );

        retorno.dataFechamento = strToDate(retorno.dataFechamento);
      }

      if (isDate(retorno.dataFimContrato)) {
        retorno.dataFimContrato = strToDate(retorno.dataFimContrato);
      }

      const municipio = retorno.municipio
        ? {
            label: retorno.municipio.nome,
            value: retorno.municipio.id,
          }
        : null;

      setRevendaSelecionada(revenda);
      setAcompanhamentoVenda(retorno);
      setColaboradorSelecionado(colaborador);
      setMunicipioSelecionado(municipio);

      // classificação
      const { acompanhamentoVendaClassificacao } = retorno;
      if (Array.isArray(acompanhamentoVendaClassificacao)) {
        const classificacoes = acompanhamentoVendaClassificacao.map(e => {
          return {
            id: e.idAtendClassificacao,
            descricao: e.descricao,
          };
        });
        setClassificacoesSelecionadas([...classificacoes]);
      }

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadMunicipio = useCallback(async (_nome, uf) => {
    if (_nome !== undefined) {
      const r = await MunicipioService.findAll({ nome: _nome, uf, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRevenda = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RevendaService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadCampanha = useCallback(async () => {
    const r = await CampanhaService.findAll({ limit: 999 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e,
      };
    });

    setCampanhas(retorno);
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function bloqueiaRevenda() {
    return AuthService.getUsuario().idRevenda && !AuthService.isPerfilGerente();
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    if (
      acompanhamentoVenda.status === 0 &&
      acompanhamentoVenda.acompanhamentoVendaHistorico.length > 0
    ) {
      toast.error(
        'Status não pode ser definido como "Aguardando contato" se já existe contato registrado.'
      );
      return;
    }
    try {
      let retorno;
      const acompanhamentoVendaSalvar = cloneObj(acompanhamentoVenda);
      if (acompanhamentoVendaSalvar.horaFechamento) {
        acompanhamentoVendaSalvar.horaFechamento = formatDate(
          acompanhamentoVendaSalvar.horaFechamento,
          'HH:mm'
        );
      }
      if (acompanhamentoVendaSalvar.horaLancamento) {
        acompanhamentoVendaSalvar.horaLancamento = formatDate(
          acompanhamentoVendaSalvar.horaLancamento,
          'HH:mm'
        );
      }

      acompanhamentoVendaSalvar.acompanhamentoVendaClassificacao = [];
      if (classificacoesSelecionadas.length > 0) {
        classificacoesSelecionadas.forEach(e => {
          const acompanhamentoVendaClassificacaoExists = (
            acompanhamentoVenda.acompanhamentoVendaClassificacao || []
          ).find(e1 => e1.idAtendClassificacao === e.id);
          acompanhamentoVendaSalvar.acompanhamentoVendaClassificacao.push({
            id: acompanhamentoVendaClassificacaoExists?.id || undefined,
            idAcompanhamentoVenda: idSelected || undefined,
            idAtendClassificacao: e.id,
            descricao: e.descricao,
          });
        });
      }
      if (stateScreen === StateScreen.stInsert) {
        retorno = await AcompanhamentoVendaService.insert(acompanhamentoVendaSalvar);
      } else {
        retorno = await AcompanhamentoVendaService.update(acompanhamentoVendaSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const newAcompanhamentoVenda = async () => {
        const novo = new AcompanhamentoVendaModel();

        if (colaboradorUsuario) {
          novo.idColaborador = colaboradorUsuario.value;
          novo.colaborador = colaboradorUsuario;
          setColaboradorSelecionado({
            value: colaboradorUsuario.value,
            label: colaboradorUsuario.label,
          });
        }

        novo.idRevenda = revendaUsuario.value;
        novo.revenda = { id: revendaUsuario.value, nome: revendaUsuario.label };

        setRevendaSelecionada(revendaUsuario);

        setAcompanhamentoVenda(novo);
      };
      newAcompanhamentoVenda();
    }
    loadCampanha();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRecord, idSelected, stateScreen, loadCampanha]);

  let telefones = '';
  let arrayTelefones = [];

  if (Array.isArray(acompanhamentoVenda?.acompanhamentoVendaTelefone)) {
    // eslint-disable-next-line no-unused-expressions
    arrayTelefones = acompanhamentoVenda?.acompanhamentoVendaTelefone.map(e => {
      return e.telefone;
    });
  }
  telefones = arrayTelefones.join(', ');

  // render principal
  return (
    <>
      {showTelefones ? (
        <Telefones
          visible={showTelefones}
          handleConfirm={lista => {
            setShowTelefones(false);
            salvaListaTelefones(lista);
          }}
          handleClose={() => {
            setShowTelefones(false);
          }}
          telefones={acompanhamentoVenda.acompanhamentoVendaTelefone}
        />
      ) : null}

      {showContato ? (
        <Contato
          visible={showContato}
          handleConfirm={contato => {
            setShowContato(false);
            salvarContato(contato);
          }}
          handleClose={() => {
            setShowContato(false);
            setContatoSelecionado(undefined);
          }}
          contato={contatoSelecionado}
        />
      ) : null}
      <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Status</LabelSp>
        <DropdownSp
          options={listaStatus}
          disabled={viewMode()}
          placeholder="Selecione"
          required
          value={acompanhamentoVenda.status}
          onChange={e => {
            setAcompanhamentoVenda({
              ...acompanhamentoVenda,
              status: e.target.value,
            });
          }}
        />
      </div>

      <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Colaborador</LabelSp>
        <DropDownLazy
          autoLoad
          required
          disabled={viewMode() || (colaboradorUsuario && !AuthService.isPerfilGerente())}
          placeholder="Selecione"
          onChange={e => {
            const colaborador = e ? { id: e.value, nome: e.label } : null;
            setColaboradorSelecionado(e);
            setAcompanhamentoVenda({
              ...acompanhamentoVenda,
              colaborador,
              idColaborador: e?.value,
            });
          }}
          value={colaboradorSelecionado}
          onFilter={async txtFilter => {
            const retorno = await loadColaborador(txtFilter);
            return retorno;
          }}
        />
      </div>

      <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Revenda</LabelSp>
        <DropDownLazy
          autoLoad
          placeholder="Selecione"
          required
          value={revendaSelecionada}
          onChange={e => {
            const revenda = e ? { id: e.value, nome: e.label } : null;
            setAcompanhamentoVenda({
              ...acompanhamentoVenda,
              revenda,
              idRevenda: e?.value,
            });
          }}
          onFilter={async txtFilter => {
            const retorno = await loadRevenda(txtFilter);
            return retorno;
          }}
          disabled={viewMode() || bloqueiaRevenda()}
        />
      </div>

      <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Campanha</LabelSp>
        <DropdownSp
          options={campanhas}
          disabled={viewMode()}
          placeholder="Selecione"
          required
          value={acompanhamentoVenda.campanha}
          onChange={e => {
            setAcompanhamentoVenda({
              ...acompanhamentoVenda,

              idCampanha: e.target.value.id,
              campanha: e.target.value,
            });
          }}
        />
      </div>

      <div className="p-col-12 p-md-2 p-lg-2 p-fluid">
        <LabelSp>CNPJ</LabelSp>
        <InputMaskSp
          mask="99.999.999/9999-99"
          value={acompanhamentoVenda.cnpj}
          disabled={viewMode()}
          onChange={e => {
            setAcompanhamentoVenda({ ...acompanhamentoVenda, cnpj: e.target.value });
          }}
        />
      </div>

      <div className="p-col-12 p-md-5 p-lg-5 p-fluid">
        <LabelSp>Razão Social</LabelSp>
        <InputTextSp
          value={acompanhamentoVenda.razaoSocial}
          disabled={viewMode()}
          maxLength={100}
          required
          onChange={e => {
            setAcompanhamentoVenda({ ...acompanhamentoVenda, razaoSocial: e.target.value });
          }}
        />
      </div>

      <div className="p-col-12 p-md-5 p-lg-5 p-fluid">
        <LabelSp>Logradouro</LabelSp>
        <InputTextSp
          value={acompanhamentoVenda.logradouro}
          disabled={viewMode()}
          maxLength={100}
          onChange={e => {
            setAcompanhamentoVenda({ ...acompanhamentoVenda, logradouro: e.target.value });
          }}
        />
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Bairro</LabelSp>
        <InputTextSp
          value={acompanhamentoVenda.bairro}
          disabled={viewMode()}
          maxLength={100}
          onChange={e => {
            setAcompanhamentoVenda({ ...acompanhamentoVenda, bairro: e.target.value });
          }}
        />
      </div>

      <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
        <LabelSp>CEP</LabelSp>
        <InputMaskSp
          name="txtCep"
          mask="99999-999"
          disabled={viewMode()}
          value={acompanhamentoVenda.cep}
          onChange={e => {
            setAcompanhamentoVenda({
              ...acompanhamentoVenda,
              cep: e.target.value,
            });
          }}
        />
      </div>

      <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
        <LabelSp>UF</LabelSp>
        <DropdownSp
          options={listaUfs}
          disabled={viewMode()}
          value={ufSelecionada}
          onChange={e => {
            setUfSelecionada(e.value);
          }}
        />
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Município</LabelSp>
        <DropDownLazy
          autoLoad
          placeholder="Selecione"
          required
          value={municipioSelecionado}
          onChange={e => {
            const municipio = e ? { id: e.value, nome: e.label } : null;

            setMunicipioSelecionado(municipioSelecionado);
            setAcompanhamentoVenda({
              ...acompanhamentoVenda,
              idMunicipio: e?.value,
              municipio,
            });
          }}
          onFilter={async txtFilter => {
            const retorno = await loadMunicipio(txtFilter, ufSelecionada);
            return retorno;
          }}
          disabled={viewMode()}
        />
      </div>

      <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Pessoa do Contato</LabelSp>
        <InputTextSp
          value={acompanhamentoVenda.pessoaContato}
          disabled={viewMode()}
          maxLength={100}
          onChange={e => {
            setAcompanhamentoVenda({ ...acompanhamentoVenda, pessoaContato: e.target.value });
          }}
        />
      </div>

      <div className="p-col-12 p-md-5 p-lg-5 p-fluid">
        <LabelSp>e-mail</LabelSp>
        <InputTextSp
          name="txtEmail"
          value={acompanhamentoVenda.email}
          onChange={e => {
            setAcompanhamentoVenda({ ...acompanhamentoVenda, email: e.target.value });
          }}
          disabled={viewMode()}
        />
      </div>

      <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Próx. Contato</LabelSp>
        <CalendarSp
          showOnFocus={false}
          showIcon
          required
          locale={cfgPtBr}
          dateFormat="dd/mm/yy"
          disabled={viewMode()}
          value={acompanhamentoVenda.dataProximoContato}
          appendTo={window.body}
          onChange={e => {
            setAcompanhamentoVenda({
              ...acompanhamentoVenda,
              dataProximoContato: e.target.value,
            });
          }}
        />
      </div>

      <div className="p-col-12 p-md-9 p-lg-9 p-fluid">
        <LabelSp>Telefones</LabelSp>
        <InputTextSp
          value={telefones}
          style={{ cursor: 'pointer' }}
          readOnly
          required
          disabled={viewMode()}
          onClick={() => setShowTelefones(true)}
        />
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        <ButtonSp
          className="p-button-primary"
          label="Inserir Contrato"
          icon="pi pi-plus-circle"
          disabled={viewMode()}
          onClick={() => {
            setContatoSelecionado(undefined);
            setShowContato(true);
          }}
        />
      </div>

      <div className="p-col-12 p-fluid">
        <DataTableSp
          value={acompanhamentoVenda.acompanhamentoVendaHistorico}
          style={{ marginBottom: '2px' }}
          responsive
        >
          <Column
            className="grid-col-data-hora grid-col-center"
            header="Data Contato"
            body={rowData => formatDate(rowData.dataContato, 'dd/MM/yyyy')}
          />
          <Column
            className="grid-col"
            header="Observação"
            body={rowData => rowData.observacao}
          />
          <Column
            className="gid-col-acoes-35"
            bodyStyle={{ textAlign: 'end' }}
            body={renderButtonOpContato}
          />
        </DataTableSp>
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            keyAccess="S"
            disabled={
              !validateFields(acompanhamentoVenda, [
                'dataProximoContato',
                'razaoSocial',
                'idRevenda',
                'idCampanha',
                'idMunicipio',
                'idColaborador',
              ]) ||
              acompanhamentoVenda.acompanhamentoVendaTelefone.length === 0 ||
              (acompanhamentoVenda.status !== 0 &&
                acompanhamentoVenda.acompanhamentoVendaHistorico.length === 0)
            }
            showConfirmation
            onClick={handleSave}
          />
        ) : null}
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          keyAccess="V"
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );

  function renderButtonOpContato(rowData) {
    return (
      <BotaoMenuGrid
        disabled={viewMode()}
        handles={[
          () => {
            rowData.dataContato = newDateBr(rowData.dataContato);
            setContatoSelecionado(rowData);
            setShowContato(true);
          },
          () => {
            excluirContato(rowData);
          },
        ]}
        labels={['Alterar', 'Excluir']}
        icons={['pi pi-pencil', 'pi pi-trash']}
      />
    );
  }
}

export default AcompanhamentoVendaCrud;
