import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';
import DropdownSp from '../../components/DropdownSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import ColaboradorService from '../../services/ColaboradorService';
import RevendaService from '../../services/RevendaService';

import { errorHandle, validateFields } from '../../util/functions';
import { ColaboradorModel } from '../../util/Models';
import { StateScreen } from '../constants';
import TipoColaborador from '../../util/classes/TipoColaborador';

function ColaboradorCrud(props) {
  ColaboradorCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  ColaboradorCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [colaborador, setColaborador] = useState(new ColaboradorModel());
  const [revendaSelecionado, setRevendaSelecionado] = useState(null);

  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  const tipos = TipoColaborador.getTiposDropdown();

  // useCallbacks
  const loadRevenda = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RevendaService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await ColaboradorService.findById(_id);
      let revenda;

      setColaborador(retorno);
      if (revenda) {
        revenda = {
          label: retorno.revenda.nome,
          value: retorno.revenda.id,
        };
      }

      setRevendaSelecionado(revenda);

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await ColaboradorService.insert(colaborador);
      } else {
        retorno = await ColaboradorService.update(colaborador);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new ColaboradorModel();
      setRevendaSelecionado(null);

      setColaborador(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            autoFocus
            value={colaborador.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setColaborador({ ...colaborador, nome: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Perfil</LabelSp>
          <DropdownSp
            placeholder="Selecione"
            required
            disabled={viewMode()}
            value={colaborador.tipo}
            options={tipos}
            onChange={e => {
              setColaborador({ ...colaborador, tipo: e.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Revenda</LabelSp>
          <DropDownLazy
            showClear
            autoLoad
            disabled={viewMode()}
            placeholder="Não definida"
            onChange={e => {
              const revenda = e ? { id: e.value, nome: e.label } : null;
              setColaborador({
                ...colaborador,
                revenda,
                idRevenda: e?.value || null,
              });
            }}
            value={revendaSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadRevenda(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              keyAccess="S"
              disabled={!validateFields(colaborador, ['nome', 'tipo'])}
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            keyAccess="V"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default ColaboradorCrud;
