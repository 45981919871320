const getBaseURL = () => {
  if (window.location.hostname !== '') {
    const apiPort = process.env.NODE_ENV === 'development' ? 3333 : 4950;
    return `${window.location.protocol}//${window.location.hostname}:${apiPort}`;
  }
  return '';
};

export const ConfigApi = {
  baseURL: getBaseURL(),

  // URLs
  municipioURL: 'municipios',
  chavesURL: 'chaves',
  usuarioURL: 'usuarios',
  moduloURL: 'modulos',
  clienteURL: 'clientes',
  clienteBackupURL: 'clientesBackup',
  colaboradorURL: 'colaboradores',
  sistemaURL: 'sistemas',
  revendaURL: 'revendas',
  setoreURL: 'setores',
  familiaGrupoURL: 'familiaGrupos',
  produtoURL: 'produtos',
  familiaURL: 'familias',
  familiaProdutoURL: 'familiaProdutos',
  agendaURL: 'agendas',
  chamadoTipoURL: 'chamadoTipos',
  chamadoURL: 'chamados',
  desenvolvimentoURL: 'desenvolvimentos',
  funilVendaURL: 'funilVendas',
  apiInfoURL: 'apiInfo',
  clienteVersaoInfoURL: 'clientesVersaoInfo',
  chaveURL: 'chaves',
  desenvolvimentoTipoURL: 'desenvolvimentosTipo',
  desenvolvimentoArquivoURL: 'desenvolvimentoArquivos',
  desenvolvimentoTesteURL: 'desenvolvimentoTestes',
  chaveAdmURL: 'chaveAdm',
  dispositivoURL: 'dispositivos',
  dashboardURL: 'dashboard',
  atendTopicoClassificadorURL: 'atendTopicoClassificadores',
  atendClassificacaoURL: 'atendClassificacoes',
  campanhaURL: 'campanhas',
  acompanhamentoVendaURL: 'acompanhamentoVendas',
  auditoriaURL: 'auditoria',
  usuarioPerfil: 'usuarioPerfils',
  funcaoURL: 'funcoes',
};

export const filterType = {
  INTEGER: 1,
  DECIMAL: 2,
  TEXT: 0,
  DATE: 3,
};

export const listaStatus = [
  { label: 'Aguardando Contato', value: 0 },
  { label: 'Contato Realizado', value: 1 },
  { label: 'Em Negociação', value: 2 },
  { label: 'Contato Fechado', value: 3 },
  { label: 'Negociação Perdida', value: 4 },
];

export const listaStatusCheckList = [
  { label: 'Aguardando Contato', value: '0' },
  { label: 'Contato Realizado', value: '1' },
  { label: 'Em Negociação', value: '2' },
  { label: 'Contato Fechado', value: '3' },
  { label: 'Negociação Perdida', value: '4' },
];

export const cfgPtBr = {
  firstDayOfWeek: 0,
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNamesMin: ['Do', 'Seg', 'Te', 'Qua', 'Qui', 'Sex', 'Sa'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  today: 'Hoje',
  clear: 'Limpa',
};

export const listaUfs = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AP', value: 'AP' },
  { label: 'AM', value: 'AM' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RS', value: 'RS' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SP', value: 'SP' },
  { label: 'SE', value: 'SE' },
  { label: 'TO', value: 'TO' },
];
