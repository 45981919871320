import React, { useEffect, useMemo } from 'react';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
// import { Container } from './styles';

const InputTextSp = React.forwardRef((props, ref) => {
  InputTextSp.propTypes = {
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
  };

  InputTextSp.defaultProps = {
    required: false,
    autoFocus: false,
  };

  const { value, required, disabled, className, autoFocus, ...rest } = props;

  const inputRef = useMemo(() => {
    if (ref) {
      return ref;
    }
    return React.createRef();
  }, [ref]);

  useEffect(() => {
    if (
      inputRef &&
      inputRef.current &&
      inputRef.current.element &&
      inputRef.current.element.focus &&
      autoFocus
    ) {
      setTimeout(() => inputRef.current.element.focus(), 200);
    }
  }, [autoFocus, inputRef]);

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return (
    <InputText
      ref={inputRef}
      autoComplete="none"
      {...rest}
      disabled={disabled}
      value={value || ''}
      className={classNames}
    />
  );
});

export default InputTextSp;
