import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { Container } from './styles';

import ChamadoService from '../../services/ChamadoService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  padLeft,
  formatDate,
  isNumber,
} from '../../util/functions';

import { showMessage } from '../../components/MessageDialog';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import ChamadoCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import LabelSp from '../../components/LabelSp';

import DropDownLazy from '../../components/DropdownLazy';
import RevendaService from '../../services/RevendaService';

import { store } from '../../store';
import ClienteService from '../../services/ClienteService';
import { showLookup } from '../../store/modules/dialog/actions';
import ButtonSp from '../../components/ButtonSp';
import InputTextSp from '../../components/InputTextSp';
import ColaboradorService from '../../services/ColaboradorService';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';
import DropdownSp from '../../components/DropdownSp';

export default function Chamado(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  const colaboradorUsuario =
    !AuthService.isPerfilGerente() && AuthService.getUsuario().colaborador
      ? {
          label: AuthService.getUsuario().colaborador.nome,
          value: AuthService.getUsuario().colaborador.id,
        }
      : null;

  const filterService = useMemo(() => {
    const f = ChamadoService.getFilter();
    f.idRevenda = AuthService.getUsuario().idRevenda;
    if (colaboradorUsuario) {
      f.idColaborador = colaboradorUsuario.value;
    }
    return f;
  }, [colaboradorUsuario]);

  const toBack = pageParams.toBack || '/chamados';

  // useStates
  const [filter, setFilter] = useState(filterService);
  const [chamados, setChamadoes] = useState([]);

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [revendas, setRevendas] = useState();
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(colaboradorUsuario);
  const [funcTimeOut, setFuncTimeOut] = useState();
  const [listaStatusSelecionado, setListaStatusSelecionado] = useState(
    JSON.parse(filter.listaStatus)
  );

  const listaStatus = [
    { label: 'Aberto', value: '0' },
    { label: 'Em Andamento', value: '1' },
    { label: 'Fechado', value: '2' },
    { label: 'Cancelado', value: '3' },
  ];

  // useCallbacks

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(7, 10, 16);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const loadRevenda = useCallback(async () => {
    const r = await RevendaService.findAll({
      limit: 999,
    });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e.id,
      };
    });

    setRevendas(retorno);
  }, []);

  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();

      try {
        const result = await ChamadoService.findAll({ ..._filter, resumo: 'resumo' });
        setChamadoes(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await ChamadoService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  // functions
  function getTitle() {
    const titleDefault = 'Chamado';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  function bloqueiaRevenda() {
    return (
      AuthService.getUsuario() &&
      AuthService.getUsuario().idRevenda &&
      !AuthService.isPerfilGerente()
    );
  }

  function corStatus(status) {
    switch (status) {
      case 0:
        return '#ee3902';
      case 1:
        return '#ebd300';
      case 2:
        return '#007ad9';
      case 3:
        return '#ff854a';
      default:
        return ' ';
    }
  }

  const confirmaExclusao = useCallback(
    id => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    loadRevenda();
    // desativado para evitar que a cada vez que o chamado digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  const lookupCliente = () => {
    const { dispatch } = store;
    const lookupConfig = ClienteService.getLookupConfig(objReturn => {
      const { id, nome } = objReturn;
      const cliente = {
        id,
        nome,
      };
      setFilterAndSearch({ ...filter, cliente, idCliente: cliente.id });
    });

    dispatch(showLookup(lookupConfig));
  };

  // renders
  return (
    <Container className="container-page page-enter-tab">
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={!AuthService.checkRoles('CHAMADO_INSERIR')}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          showButtonSearch
          titleFilterDefault="Buscar por Descrição"
          handleFilterDefault={text => {
            filter.descricao = text;
            setFilter({ ...filter, descricao: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              descricao: '',
              idRevenda: null,
              cliente: null,
              idCliente: 0,
              listaStatus: '["0", "1"]',
            });
            setListaStatusSelecionado(JSON.parse('["0", "1"]'));
          }}
          handleButtonInsert={() => history.push('/chamados/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div
            className="p-grid p-col-12 p-md-5 p-lg-5 p-fluid"
            style={{ margin: 0, padding: 0 }}
          >
            <div className="p-col-6 p-fluid">
              <LabelSp htmlFor="dataInicial">De: </LabelSp>
              <CalendarSp
                showIcon
                showOnFocus={false}
                appendTo={document.body}
                dateFormat="dd/mm/yy"
                yearNavigator
                locale={cfgPtBr}
                id="dataInicial"
                value={filter.dataInicio}
                yearRange="2010:2040"
                onChange={e => {
                  setFilterAndSearch({ ...filter, dataInicio: e.value });
                }}
              />
            </div>

            <div className="p-col-6 p-fluid">
              <LabelSp htmlFor="dataFinal">Até: </LabelSp>
              <CalendarSp
                showIcon
                showOnFocus={false}
                appendTo={document.body}
                locale={cfgPtBr}
                id="dataFinal"
                dateFormat="dd/mm/yy"
                value={filter.dataFim}
                yearNavigator
                yearRange="2010:2040"
                onChange={e => setFilterAndSearch({ ...filter, dataFim: e.value })}
              />
            </div>
          </div>

          <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
            <LabelSp>Revenda</LabelSp>
            <DropdownSp
              placeholder="Todos"
              showClear
              disabled={bloqueiaRevenda()}
              onChange={e => {
                setFilterAndSearch({ ...filter, idRevenda: e?.value });
              }}
              options={revendas}
              value={filter.idRevenda}
            />
          </div>

          <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
            <LabelSp>Responsável</LabelSp>
            <DropDownLazy
              autoLoad
              placeholder="Todos"
              showClear
              onChange={e => {
                setColaboradorSelecionado(e);
                setFilterAndSearch({ ...filter, idColaborador: e?.value });
              }}
              value={colaboradorSelecionado}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="p-col-12 p-md-7 p-lg-7 p-fluid">
            <LabelSp>Cliente</LabelSp>
            <div className="p-inputgroup">
              <ButtonSp
                type="button"
                icon="pi pi-search"
                className="p-button-secondary buttons"
                title="Buscar Cliente"
                onClick={() => lookupCliente()}
              />
              <ButtonSp
                type="button"
                icon="pi pi-trash"
                className="p-button-warning buttons"
                title="Limpar Cliente"
                style={{ marginLeft: 1 }}
                onClick={() => {
                  setFilterAndSearch({ ...filter, idCliente: 0, cliente: null });
                }}
              />

              <InputTextSp value={filter.cliente?.nome || 'Todos os Clientes'} disabled />
            </div>
          </div>

          <div className="p-col-12 p-md-5 p-lg-5 p-fluid">
            <LabelSp>Contato</LabelSp>
            <InputTextSp
              maxLength={50}
              value={filter.contato}
              onChange={e => {
                setFilterAndSearch({ ...filter, contato: e?.target.value });
              }}
            />
          </div>

          <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
            <LabelSp>Status</LabelSp>
            <MultiSelect
              value={listaStatusSelecionado}
              options={listaStatus}
              selectedItemTemplate={selectedCarTemplate}
              maxSelectedLabels={4}
              onChange={e => {
                setListaStatusSelecionado(e.value);
                setFilterAndSearch({ ...filter, listaStatus: JSON.stringify(e.value) });
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid datatable-doc-demo">
          <DataTableSp
            value={chamados}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              header=" "
              style={{ width: 30 }}
              body={rowData => {
                const cor = corStatus(rowData.status);
                return (
                  <div
                    className="div-status"
                    style={{
                      background: cor,
                      color: cor,
                    }}
                  >
                    A
                  </div>
                );
              }}
            />
            <Column
              field="id"
              header="Id"
              className="grid-col-id-p"
              body={rowData => padLeft(rowData.id, 5)}
            />
            <Column
              field="dataLancamento"
              header="D/H. Lançto"
              className="grid-col-data-hora-p"
              body={rowData =>
                formatDate(
                  `${rowData.dataLancamento} ${rowData.horaLancamento}`,
                  'dd/MM/yy HH:mm'
                )
              }
            />

            <Column
              field="dataLancamento"
              header="D/H. Fech."
              className="grid-col-data-hora-p"
              body={rowData =>
                rowData.dataFechamento && rowData.horaFechamento
                  ? formatDate(
                      `${rowData.dataFechamento} ${rowData.horaFechamento}`,
                      'dd/MM/yy HH:mm'
                    )
                  : ''
              }
            />

            <Column
              field="usuario.login"
              className="grid-col grid-col-nowrap"
              style={{ width: 120 }}
              header="Usuário"
            />
            <Column
              field="colaborador.nome"
              className="grid-col p-p-4 grid-col-nowrap"
              style={{ width: 120 }}
              header="Responsável"
            />
            <Column
              field="cliente.nome"
              className="grid-col grid-col-nowrap"
              header="Cliente"
            />
            <Column
              field="descricao"
              className="grid-col grid-col-nowrap"
              header="Descrição"
            />

            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/chamados/${rowData.id}?view`),
          () => history.push(`/chamados/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('CHAMADO_ALTERAR'),
          !AuthService.checkRoles('CHAMADO_EXCLUIR'),
        ]}
      />
    );
  }

  function renderCrud() {
    return (
      <ChamadoCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        onClose={() => {
          history.push(toBack);
          handleBuscar(filter, pageSelected, false);
        }}
      />
    );
  }

  function selectedCarTemplate(option) {
    if (isNumber(option)) {
      const cor = corStatus(Number(option));
      const item = listaStatus[option];
      return (
        <div className="multiselected-item">
          <div
            className="div-status"
            style={{
              marginRight: 3,
              background: cor,
              color: cor,
            }}
          >
            A
          </div>
          <span>{item.label}</span>
        </div>
      );
    }

    return <span>Todos</span>;
  }
}
