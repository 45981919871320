import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';

import ButtonSp from '../../components/ButtonSp';
import {
  calcNaxItemsPage,
  errorHandle,
  extensaoFile,
  formatDate,
  padLeft,
  validateFields,
} from '../../util/functions';
import { DesenvolvimentoArquivo } from '../../util/Models';
import DesenvolvimentoArquivoService from '../../services/DesenvolvimentoArquivoService';

import LabelSp from '../../components/LabelSp';
import ImgPdfView from '../../components/ImgPdfView';
import DataTableSp from '../../components/DataTableSp';
import AuthService from '../../services/AuthService';
import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';

function CrudAnexo(props) {
  CrudAnexo.propTCpes = {
    viewMode: PropTypes.bool.isRequired,
    handleBack: PropTypes.func.isRequired,
    idSelected: PropTypes.number.isRequired,
  };

  const [showImgPdfView, setShowImgPdfView] = useState(false);
  const [imgPdfSrc, setImgPdfSrc] = useState('');
  const [fileName, setFileName] = useState();
  const [desenvolvimentoArquivo, setDesenvolvimentoArquivo] = useState(
    new DesenvolvimentoArquivo()
  );
  const [arquivos, setArquivos] = useState([]);
  const [cadastroArquivoVisivel, setCadastroArquivoVisivel] = useState(false);
  const pageLimit = calcNaxItemsPage(6, 10, 16);

  const { idSelected, viewMode, handleBack } = props;
  // useCallbacks
  const handleBuscarArquivos = useCallback(async idDesenvolvimento => {
    const r = await DesenvolvimentoArquivoService.findAll({ idDesenvolvimento, limit: 200 });
    setArquivos(r.items);
  }, []);

  // functions
  async function handleSalvarArquivo() {
    if (desenvolvimentoArquivo.file) {
      if (desenvolvimentoArquivo.file.size > 10000001) {
        toast.warn('Arquivo nao pode ter tamanho superior a 10M');
        return;
      }
    }

    try {
      const { data, file, nome } = desenvolvimentoArquivo;
      const objEnviar = { nome, data, file, idDesenvolvimento: idSelected };
      await DesenvolvimentoArquivoService.insert(objEnviar);
      setCadastroArquivoVisivel(false);
      handleBuscarArquivos(idSelected);
    } catch (err) {
      errorHandle(err);
    }
  }

  async function handleExcluirArquivo(id) {
    showMessage('Confirmação', 'Confirma a exclusão do arquivo?', async idx => {
      if (idx === 1) {
        await DesenvolvimentoArquivoService.delete(id);
        handleBuscarArquivos(idSelected);
      }
    });
  }

  async function getFile(tipoArquivo, id) {
    if ('PDF'.includes(tipoArquivo)) {
      try {
        const fileBase64 = await DesenvolvimentoArquivoService.getFileBase64(id);

        setImgPdfSrc(fileBase64);
        setShowImgPdfView(true);
      } catch (err) {
        errorHandle(err);
      }
    } else if ('PNG JPG'.includes(tipoArquivo)) {
      const image = new Image();
      const fileBase64 = await DesenvolvimentoArquivoService.getFileBase64(id);
      image.src = fileBase64;

      const w = window.open('');
      w.document.write(image.outerHTML);
    } else {
      showMessage('Confirmação', 'Confirma o download?', async idx => {
        if (idx === 1) {
          let file;
          let nomeArquivo = 'arquivo.file';
          try {
            file = await DesenvolvimentoArquivoService.getFile(id);

            const { type } = file;
            nomeArquivo = `arquivo_desenvolvimento_${id}.${extensaoFile(type)}`;
          } catch (err) {
            errorHandle(err);
          }
          if (file) {
            const blob = new Blob([file]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nomeArquivo);
            document.body.appendChild(link);
            link.click();
          }
        }
      });
    }
  }

  // useEffects
  useEffect(() => {
    handleBuscarArquivos(idSelected);
  }, [handleBuscarArquivos, idSelected]);

  // renders
  function renderCadastroArquivo() {
    function renderFooter() {
      return (
        <div>
          <ButtonSp
            label="Confirmar"
            icon="pi pi-check"
            keyAccess="C"
            disabled={!validateFields(desenvolvimentoArquivo, ['nome', 'file'])}
            onClick={() => {
              handleSalvarArquivo();
            }}
          />
          <ButtonSp
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => setCadastroArquivoVisivel(false)}
            className="p-button-secondary"
          />
        </div>
      );
    }

    return (
      <Dialog
        closable={false}
        header="Upload de Arquivo"
        footer={renderFooter()}
        visible={cadastroArquivoVisivel}
        style={{ minWidth: '350px', maxWidth: '600px', width: '95%' }}
        modal
        onHide={() => {
          setCadastroArquivoVisivel(false);
        }}
      >
        <div className="p-grid">
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <InputTextSp
              type="file"
              required
              accept=".png, .jpg, .rar, .pdf, .7z, .zip"
              value={fileName}
              onChange={e => {
                const nome = e.target.files[0].name || '';

                const file = e.target.files && e.target.files[0] ? e.target.files[0] : null;
                setFileName(e.target.value);
                setDesenvolvimentoArquivo({
                  ...desenvolvimentoArquivo,
                  nome,
                  file,
                });
              }}
            />
            <span>Tamanho máximo permitido para cada arquivo é de 10Mb</span>
          </div>
          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp>Nome</LabelSp>
            <InputTextSp
              value={desenvolvimentoArquivo.nome}
              maxLength={100}
              required
              onChange={e => {
                setDesenvolvimentoArquivo({
                  ...desenvolvimentoArquivo,
                  nome: e?.target.value,
                });
              }}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <>
      {showImgPdfView ? (
        <ImgPdfView
          title="Prontuário"
          src={imgPdfSrc}
          visible={showImgPdfView}
          setVisible={() => {
            setImgPdfSrc('');
            setShowImgPdfView(false);
          }}
        />
      ) : null}

      {renderCadastroArquivo()}

      <div className="p-col-12 p-fluid">
        <DataTableSp
          value={arquivos}
          style={{ marginBottom: '2px' }}
          paginator
          rows={pageLimit}
          responsive
        >
          <Column
            field="id"
            body={rowData => padLeft(rowData.id, 6)}
            header="Id"
            className="grid-col-id"
          />
          <Column
            field="tipo"
            header="Tipo Arquivo"
            className="grid-col grid-col-center"
            style={{ width: 150 }}
            body={rowData => rowData.tipoArquivo}
          />
          <Column
            field="data"
            className="grid-col-data-hora grid-col-center"
            header="Data Upload"
            body={rowData => formatDate(rowData.data, 'dd/MM/yyyy')}
          />
          <Column field="nome" className="grid-col" header="Nome" />
          <Column
            className="gid-col-acoes-70"
            bodyStyle={{ textAlign: 'end' }}
            body={renderButtonOp}
          />
        </DataTableSp>
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        <ButtonSp
          className="buttons"
          title="Inserir Arquivo"
          label="Inserir"
          keyAccess="I"
          icon="pi pi-plus-circle"
          type="button"
          onClick={() => {
            setDesenvolvimentoArquivo(new DesenvolvimentoArquivo());
            setFileName(undefined);
            setCadastroArquivoVisivel(true);
          }}
        />
        <ButtonSp
          className={viewMode() ? 'p-button-secondary' : 'p-button-danger'}
          label={viewMode() ? 'Voltar' : 'Cancelar'}
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
  function renderButtonOp(rowData) {
    let icone = 'pi pi-download';
    let label = 'Download';
    let classButton = 'botao-pequeno p-button-success buttons';
    switch (rowData.tipoArquivo) {
      case 'PDF':
        icone = 'pi pi-file-pdf';
        label = 'Visualizar PDF';
        classButton = 'botao-pequeno p-button-success buttons';
        break;
      case 'JPG':
      case 'PNG':
        icone = 'pi pi-image';
        label = 'Visualizar Imagem';
        classButton = 'botao-pequeno p-button-success buttons';
        break;
      default:
        break;
    }

    return (
      <>
        <ButtonSp
          className={classButton}
          title={label}
          icon={icone}
          onClick={() => getFile(rowData.tipoArquivo, rowData.id)}
        />
        <ButtonSp
          className="botao-pequeno p-button-danger buttons"
          title="Excluir"
          icon="pi pi-trash"
          disabled={!AuthService.checkRoles('DESENVOLVIMENTO_ALTERAR')}
          onClick={() => handleExcluirArquivo(rowData.id)}
        />
      </>
    );
  }
}

export default CrudAnexo;
