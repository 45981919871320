/* eslint-disable no-useless-escape */
import React, { useCallback, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { Container } from './styles';
import DataTableSp from '../../components/DataTableSp';
import LabelSp from '../../components/LabelSp';

import ButtonSp from '../../components/ButtonSp';
import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import InputTextSp from '../../components/InputTextSp';

function Telefones(props) {
  const { visible, handleConfirm, handleClose, telefones } = props;
  const list = telefones || [];

  const [telefoneSelecionado, setTelefoneSelecionado] = useState('');
  const [listaTelefones, setListaTelefones] = useState([...list]);
  const [indexSelecionado, setIndexSelecionado] = useState(-1);

  // useCalback
  const salvarTelefone = useCallback(
    telefone => {
      const tel = (telefone || '')
        .replace(/\-/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '');

      if (tel.length !== 10 && tel.length !== 11) {
        toast.error(
          'Telefone/celular inválido. Utilize o padrão (99)99999-9999 ou (99)9999-9999'
        );
        return;
      }

      const idxExists = listaTelefones.findIndex(e => e.telefone === telefone);
      if (idxExists >= 0 && idxExists !== indexSelecionado) {
        toast.error('Telefone já existe');
        return;
      }

      if (indexSelecionado >= 0) {
        listaTelefones[indexSelecionado].telefone = telefone;
      } else {
        listaTelefones.push({ telefone });
      }

      setListaTelefones([...listaTelefones]);
      setTelefoneSelecionado('');
      setIndexSelecionado(-1);
    },
    [indexSelecionado, listaTelefones]
  );

  function novoTelefone() {
    setTelefoneSelecionado('');
    setIndexSelecionado(-1);
  }

  function alterarTelefone(rowData) {
    const idx = listaTelefones.findIndex(e => e.telefone === rowData.telefone);
    setIndexSelecionado(idx);

    ajustaMascara(rowData.telefone);
  }

  function excluirSocio(rowData) {
    const idx = listaTelefones.findIndex(e => e.telefone === rowData.telefone);
    setIndexSelecionado(-1);
    setTelefoneSelecionado('');
    if (idx >= 0) {
      listaTelefones.splice(idx, 1);
    }
    setListaTelefones([...listaTelefones]);
  }

  const ajustaMascara = value => {
    const textoAtual = value
      .replace(/\-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '');

    if (textoAtual.length === 11) {
      const parte1 = textoAtual.slice(0, 2);
      const parte2 = textoAtual.slice(2, 7);
      const parte3 = textoAtual.slice(7, 11);
      setTelefoneSelecionado(`(${parte1})${parte2}-${parte3}`);
    } else if (textoAtual.length === 10) {
      const parte1 = textoAtual.slice(0, 2);
      const parte2 = textoAtual.slice(2, 6);
      const parte3 = textoAtual.slice(6, 10);
      setTelefoneSelecionado(`(${parte1})${parte2}-${parte3}`);
    }
  };

  const removeMascara = value => {
    let texto = value;

    texto = texto
      .replace(/\-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '');

    setTelefoneSelecionado(texto);
  };

  const footer = (
    <div>
      <Button
        label="Confirmar"
        icon="pi pi-check"
        onClick={() => {
          if (handleConfirm) {
            handleConfirm(listaTelefones);
          }
        }}
        className="p-button-success"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          if (handleClose) {
            handleClose();
          }
        }}
        className="p-button-danger"
      />
    </div>
  );

  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header="Telefones"
        visible={visible}
        style={{ minWidth: '350px', maxWidth: '600px', width: '95%' }}
        modal
        onHide={() => {
          if (handleClose) {
            handleClose();
          }
        }}
      >
        <div className="p-col-12 p-fluid" style={{ margin: 0, padding: 0 }}>
          <div className="p-grid" style={{ margin: 0, padding: 0 }}>
            {/* <div className="p-col-7 p-md-7 p-lg-7 p-fluid">
              <LabelSp>Telefone</LabelSp>
              <InputMaskSp
                mask="(99)*9999-9999"
                value={telefoneSelecionado}
                onChange={e => {
                  setTelefoneSelecionado(e.target.value);
                }}
              />
            </div> */}

            <div className="p-col-7 p-md-7 p-lg-7 p-fluid">
              <LabelSp>Telefone</LabelSp>
              <InputTextSp
                type="text"
                maxLength={11}
                value={telefoneSelecionado}
                placeholder="(99)99999-9999 ou (99)9999-9999"
                keyfilter="int"
                onFocus={e => {
                  removeMascara(e.target.value);
                }}
                onBlur={e => {
                  ajustaMascara(e.target.value);
                }}
                onChange={e => {
                  setTelefoneSelecionado(e.target.value);
                }}
              />
            </div>

            <div className="p-col-5 p-md-3 p-lg-3" style={{ textAlign: 'start' }}>
              <LabelSp style={{ display: 'block' }}>&#160;</LabelSp>
              <ButtonSp
                icon="pi pi-save"
                title="Salvar"
                onClick={() => {
                  salvarTelefone(telefoneSelecionado);
                }}
                type="button"
                className="p-button-success"
              />

              <ButtonSp
                onClick={() => novoTelefone()}
                type="button"
                icon="pi pi-file"
                title="Novo"
              />
            </div>
          </div>

          <div className="p-col-12 p-fluid">
            <DataTableSp value={listaTelefones} style={{ marginBottom: '2px' }} responsive>
              <Column
                className="grid-col"
                header="Telefone"
                body={rowData => rowData.telefone}
              />
              <Column
                className="gid-col-acoes-35"
                bodyStyle={{ textAlign: 'end' }}
                body={renderButtonOpSocio}
              />
            </DataTableSp>
          </div>
        </div>
      </Dialog>
    </Container>
  );

  function renderButtonOpSocio(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            alterarTelefone(rowData);
          },
          () => {
            excluirSocio(rowData);
          },
        ]}
        labels={['Alterar', 'Excluir']}
        icons={['pi pi-pencil', 'pi pi-trash']}
      />
    );
  }
}

export default Telefones;
