/* eslint-disable no-nested-ternary */
import React from 'react';

// import PropTypes from 'prop-types';

import Panel from '../../components/Panel';

function Page2(props) {
  Page2.propTypes = {
    // dataGraficoAtMeses: PropTypes.object,
  };

  const { clientesSemAt } = props;

  Page2.defaultProps = {
    required: false,
    autoFocus: false,
  };

  const pCol = 'p-col-12 p-lg-9 p-xl-9';

  const strQtdClienteSemAt =
    Array.isArray(clientesSemAt) && clientesSemAt.length > 0
      ? `(${clientesSemAt.length})`
      : '';

  const renderClienteSemAt = () => {
    if (Array.isArray(clientesSemAt)) {
      return clientesSemAt.map((e, idx) => {
        return (
          <div
            className="div-listagem"
            key={e.id}
            style={{ background: idx % 2 === 0 ? '#edf0f5' : undefined }}
          >
            <span
              className="grid-col-nowrap "
              style={{
                fontSize: 14,
                display: 'block',
                fontStyle: 'bold',
                fontColor: '#333333',
                flex: 1,
              }}
            >
              {`${e.numeroContrato}  ${e.nome}`}
            </span>

            <div style={{ minWidth: 102 }}>
              <span
                className="grid-col-nowrap "
                style={{
                  fontSize: 14,
                  display: 'block',
                  fontStyle: 'bold',
                  fontColor: '#333333',
                }}
              >
                {e.fone || e.celular}
              </span>
            </div>
          </div>
        );
      });
    }
    return null;
  };

  return (
    <>
      <div className={pCol}>
        <Panel
          header={`Clientes sem Atendimento nos últimos 90 dias${strQtdClienteSemAt}`}
          style={{ height: '100%' }}
        >
          <div className="div-listagem titulo-listagem">
            <span>Cliente</span>
          </div>
          {renderClienteSemAt()}
        </Panel>
      </div>
    </>
  );
}

export default Page2;
