/**
 * Serviço que gerencia dashboard
 * @module Dashboard
 * @category Serviços
 */

import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { firstDayMonth, firstDayYear, geraFiltroHttp, lastDayMonth } from '../util/functions';

class DashboardService extends BaseService {
  constructor() {
    super(ConfigApi.dashboardURL);
  }

  /**
   * Retorna o objeto filter específico do serviço
   * @returns {Filter}
   */
  getFilter() {
    return new Filter();
  }

  /**
   * Busca dados para dashboard
   * @param {Filter} filter Filtros usados para buscar dados
   * @returns {Promise<any>} Dados necessários para montar todos os gráficos
   */
  async getDashBoard(filter) {
    this.setLoading(true);
    try {
      const filterGet = geraFiltroHttp(filter);
      const response = await this.api.get(`${this.url}${filterGet}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.mesAnoApuracao = firstDayYear(new Date());
    this.dataInicio = firstDayMonth(new Date());
    this.dataFim = lastDayMonth(new Date());
    this.idUsuarioResponsavel = undefined;
    this.idRevenda = undefined;
    this.tipoPeriodo = 1; // 1-ano, //2 periodo
    this.limit = 0;
    this.page = 0;
  }
}
export default new DashboardService();
