import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  height: 33px;
  border-style: solid;
  border-color: #2e282e2c;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;

  .button-selected {
    padding: 4px;
    height: 27px;
    margin: 2px;
    background: #0388e5;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 3px;

    /* flex: 1 - Alterado em tempo de execução no index.js */

    transition: background 0.2s;

    &:hover {
      background: ${darken(0.05, '#0388e5')};
    }
  }

  .button-unselected {
    padding: 4px;
    height: 27px;
    margin: 2px;
    background: #505050;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 3px;

    /* flex: 1 - Alterado em tempo de execução no index.js */

    transition: background 0.2s;

    &:hover {
      background: ${darken(0.05, '#505050')};
    }
  }
`;
