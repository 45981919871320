import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';

import { toast } from 'react-toastify';
import ButtonSp from '../../components/ButtonSp';
import {
  calcNaxItemsPage,
  errorHandle,
  formatDate,
  padLeft,
  strToDate,
  validateFields,
} from '../../util/functions';
import { DesenvolvimentoTeste } from '../../util/Models';
import DesenvolvimentoTesteService from '../../services/DesenvolvimentoTesteService';

import LabelSp from '../../components/LabelSp';
import DataTableSp from '../../components/DataTableSp';
import AuthService from '../../services/AuthService';
import { showMessage } from '../../components/MessageDialog';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import InputTextareaSp from '../../components/InputTextareaSp';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';
import DropdownSp from '../../components/DropdownSp';
import { StateScreen } from '../constants';
import DropDownLazy from '../../components/DropdownLazy';

function CrudTeste(props) {
  CrudTeste.propTCpes = {
    viewMode: PropTypes.bool.isRequired,
    handleBack: PropTypes.func.isRequired,
    idSelected: PropTypes.number.isRequired,
    loadColaborador: PropTypes.func.isRequired,
    colaboradorResponsavelUsuario: PropTypes.object,
    permiteLancarTestes: PropTypes.bool,
  };

  const {
    idSelected,
    viewMode,
    handleBack,
    loadColaborador,
    colaboradorResponsavelUsuario,
    permiteLancarTestes,
  } = props;

  const [desenvolvimentoTeste, setDesenvolvimentoTeste] = useState(new DesenvolvimentoTeste());
  const [testes, setTestes] = useState([]);
  const [cadastroTesteVisivel, setCadastroTesteVisivel] = useState(false);
  const [stateScreen, setStateScreen] = useState();
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState(
    colaboradorResponsavelUsuario
  );

  const pageLimit = calcNaxItemsPage(6, 10, 16);

  // useCallbacks
  const handleBuscarTestes = useCallback(async idDesenvolvimento => {
    const r = await DesenvolvimentoTesteService.findAll({ idDesenvolvimento, limit: 200 });
    setTestes(r.items);
  }, []);

  // functions
  async function handleSalvarTeste() {
    showMessage('Confirmação', 'Confirma os dados?', async idx => {
      if (idx === 1) {
        try {
          desenvolvimentoTeste.idDesenvolvimento = idSelected;

          if (stateScreen === StateScreen.stInsert) {
            await DesenvolvimentoTesteService.insert(desenvolvimentoTeste);
          } else {
            await DesenvolvimentoTesteService.update(desenvolvimentoTeste);
          }
          setCadastroTesteVisivel(false);
          handleBuscarTestes(idSelected);
          toast.success('Registro salvo com sucesso.');
        } catch (err) {
          errorHandle(err);
        }
      }
    });
  }
  function novoDesenvolvimentoTeste() {
    const devTeste = new DesenvolvimentoTeste();
    devTeste.idColaborador = colaboradorSelecionado.value;
    devTeste.colaborador = {
      id: colaboradorSelecionado.value,
      nome: colaboradorSelecionado.label,
    };

    devTeste.idDesenvolvimento = idSelected;
    return devTeste;
  }
  function bloqueiaResponsavel() {
    return !AuthService.isPerfilGerente();
  }

  async function handleExcluirTeste(id) {
    showMessage('Confirmação', 'Confirma a exclusão do teste?', async idx => {
      if (idx === 1) {
        try {
          await DesenvolvimentoTesteService.delete(id);
          handleBuscarTestes(idSelected);
          toast.success('Registro excluído com sucesso.');
        } catch (err) {
          errorHandle(err);
        }
      }
    });
  }

  // useEffects
  useEffect(() => {
    handleBuscarTestes(idSelected);
  }, [handleBuscarTestes, idSelected]);

  // renders
  function renderCadastroTeste() {
    function renderFooter() {
      return (
        <div>
          <ButtonSp
            label="Confirmar"
            icon="pi pi-check"
            keyAccess="C"
            disabled={
              !validateFields(desenvolvimentoTeste, ['descricao', 'idColaborador', 'data'])
            }
            onClick={() => {
              handleSalvarTeste();
            }}
          />
          <ButtonSp
            label="Cancelar"
            icon="pi pi-times"
            onClick={() => setCadastroTesteVisivel(false)}
            className="p-button-secondary"
          />
        </div>
      );
    }
    let titulo = 'Novo Registro';
    if (stateScreen === StateScreen.stView) {
      titulo = 'Visualizando Registro';
    } else if (stateScreen === StateScreen.stUpdate) {
      titulo = 'Alterando Registro';
    }
    return (
      <Dialog
        closable={false}
        header={titulo}
        footer={renderFooter()}
        visible={cadastroTesteVisivel}
        style={{ minWidth: '350px', maxWidth: '800px', width: '95%' }}
        modal
        onHide={() => {
          setCadastroTesteVisivel(false);
        }}
      >
        <div className="p-grid">
          <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
            <LabelSp>Responsável</LabelSp>
            <DropDownLazy
              autoLoad
              required
              disabled={stateScreen === StateScreen.stView || bloqueiaResponsavel()}
              placeholder="Selecione"
              onChange={e => {
                const colab = e ? { id: e.value, nome: e.label } : null;
                setColaboradorSelecionado(e);
                setDesenvolvimentoTeste({
                  ...desenvolvimentoTeste,
                  colaborador: colab,
                  idColaborador: e?.value,
                });
              }}
              value={colaboradorSelecionado}
              onFilter={async txtFilter => {
                const retorno = await loadColaborador(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-6 p-fluid">
            <LabelSp>Data</LabelSp>
            <CalendarSp
              showOnFocus={false}
              showIcon
              required
              locale={cfgPtBr}
              dateFormat="dd/mm/yy"
              value={desenvolvimentoTeste.data}
              appendTo={document.body}
              disabled={stateScreen === StateScreen.stView}
              onChange={e => {
                setDesenvolvimentoTeste({
                  ...desenvolvimentoTeste,
                  data: e.target.value,
                });
              }}
            />
          </div>

          <div className="p-col-6 p-fluid">
            <LabelSp>Situação</LabelSp>
            <DropdownSp
              options={[
                { label: 'Pendente', value: 0 },
                { label: 'Concluído com encontrado', value: 1 },
                { label: 'Concluído sem problema', value: 2 },
              ]}
              placeholder="selecione"
              required
              disabled={stateScreen === StateScreen.stView}
              value={desenvolvimentoTeste.situacao}
              onChange={e => {
                setDesenvolvimentoTeste({
                  ...desenvolvimentoTeste,
                  situacao: e.target?.value,
                });
              }}
            />
          </div>

          <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp>Descrição</LabelSp>
            <InputTextareaSp
              value={desenvolvimentoTeste.descricao}
              autoResize={false}
              maxLength={2000}
              rows={10}
              resize={false}
              required
              disabled={stateScreen === StateScreen.stView}
              onChange={e => {
                setDesenvolvimentoTeste({
                  ...desenvolvimentoTeste,
                  descricao: e?.target.value,
                });
              }}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <>
      {renderCadastroTeste()}

      <div className="p-col-12 p-fluid">
        <DataTableSp
          value={testes}
          style={{ marginBottom: '2px' }}
          paginator
          rows={pageLimit}
          responsive
        >
          <Column
            field="id"
            body={rowData => padLeft(rowData.id, 6)}
            header="Id"
            className="grid-col-id"
          />

          <Column
            field="data"
            className="grid-col-data grid-col-center"
            header="Data"
            body={rowData => formatDate(rowData.data, 'dd/MM/yyyy')}
          />
          <Column
            field="situacaoDescricao"
            header="Situação"
            className="grid-col"
            style={{ width: 200 }}
          />
          <Column field="descricao" className="grid-col" header="Descrição do Teste" />
          <Column
            className="gid-col-acoes-35"
            bodyStyle={{ textAlign: 'end' }}
            body={renderButtonOp}
          />
        </DataTableSp>
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        <ButtonSp
          className="buttons"
          title="Inserir Teste"
          label="Inserir"
          keyAccess="I"
          icon="pi pi-plus-circle"
          type="button"
          disabled={!permiteLancarTestes}
          onClick={() => {
            setDesenvolvimentoTeste(novoDesenvolvimentoTeste());
            setColaboradorSelecionado(colaboradorResponsavelUsuario);

            setStateScreen(StateScreen.stInsert);
            setCadastroTesteVisivel(true);
          }}
        />
        <ButtonSp
          className={viewMode() ? 'p-button-secondary' : 'p-button-danger'}
          label={viewMode() ? 'Voltar' : 'Cancelar'}
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => {
            const devTeste = novoDesenvolvimentoTeste();
            devTeste.id = rowData.id;

            devTeste.data = strToDate(rowData.data);
            devTeste.situacao = rowData.situacao;
            devTeste.descricao = rowData.descricao;
            devTeste.colaborador = {
              id: rowData.idColaborador,
              nome: rowData.nomeColaborador,
            };

            setDesenvolvimentoTeste(devTeste);
            setColaboradorSelecionado({
              value: rowData.idColaborador,
              label: rowData.nomeColaborador,
            });
            setStateScreen(StateScreen.stView);
            setCadastroTesteVisivel(true);
          },
          () => {
            const devTeste = novoDesenvolvimentoTeste();
            devTeste.id = rowData.id;

            devTeste.data = strToDate(rowData.data);
            devTeste.situacao = rowData.situacao;
            devTeste.descricao = rowData.descricao;
            devTeste.colaborador = {
              id: rowData.idColaborador,
              nome: rowData.nomeColaborador,
            };

            setColaboradorSelecionado({
              value: rowData.idColaborador,
              label: rowData.nomeColaborador,
            });
            setDesenvolvimentoTeste(devTeste);
            setStateScreen(StateScreen.stUpdate);
            setCadastroTesteVisivel(true);
          },
          () => handleExcluirTeste(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('DESENVOLVIMENTO_ALTERAR'),
          !AuthService.checkRoles('DESENVOLVIMENTO_EXCLUIR'),
        ]}
      />
    );
  }
}

export default CrudTeste;
