import React, { useCallback } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import PropTypes from 'prop-types';
import { montaMenuGrid } from '../utils/utils';

export default function BotaoMenuGrid(props) {
  BotaoMenuGrid.propTypes = {
    handles: PropTypes.arrayOf(PropTypes.func),
    labels: PropTypes.arrayOf(PropTypes.string),
    icons: PropTypes.arrayOf(PropTypes.string),
    disableds: PropTypes.arrayOf(PropTypes.bool),
    rowData: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
  };

  const { handles, labels, icons, disableds, disabled } = props;

  const montaMenuItem = useCallback(() => {
    return montaMenuGrid(handles, disableds, labels, icons);
  }, [disableds, handles, icons, labels]);

  function renderButtonOp() {
    let menu = null;
    return (
      <>
        <Button
          type="button"
          title="Opções"
          className="botao-pequeno ui-button-primary"
          icon="pi pi-cog"
          disabled={disabled}
          onClick={event => {
            menu.toggle(event);
          }}
        />
        <Menu
          model={montaMenuItem()}
          popup
          ref={el => (menu = el)}
          id="popup_menu"
          appendTo={document.body}
        />
      </>
    );
  }

  return renderButtonOp();
}
