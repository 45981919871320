export class UsuarioModel {
  constructor() {
    this.id = undefined;
    this.idColaborador = undefined;
    this.idCliente = undefined;
    this.idUsuarioPerfil = undefined;
    this.login = '';
    this.senha = '';
    this.inativo = false;
  }
}

export class MunicipioModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.uf = '';
    this.codigoIbge = 0;
  }
}

export class RevendaModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class ChamadoTipoModel {
  constructor() {
    this.id = undefined;
    this.tipo = 0;
    this.nome = '';
    this.prazoDias = 1;
  }
}

export class ColaboradorModel {
  constructor() {
    this.id = undefined;
    this.idRevenda = undefined;
    this.tipo = 0;
    this.nome = '';
  }
}

export class AtendimentoTopicoClassificadorModel {
  constructor() {
    this.id = undefined;
    this.descricao = '';
  }
}
export class AtendimentoClassificacaoModel {
  constructor() {
    this.id = undefined;
    this.descricao = '';
    this.idAtendTopicoClassificador = undefined;
    this.grauComplexidade = 0;
    this.tempoEstimado = 0;
    this.permiteAlterarDescricao = false;
    this.atendTopicoClassificador = undefined;
  }
}

export class ChamadoModel {
  constructor() {
    this.id = undefined;
    this.idUsuario = undefined;
    this.idRevenda = undefined;
    this.idCliente = undefined;
    this.idChamadoTipo = 1;
    this.idColaborador = undefined;
    this.status = 0;
    this.dataLancamento = new Date();
    this.horaLancamento = new Date();
    this.prazoDias = 1;
    this.descricao = '';
    this.observacaoAtendente = '';
    this.dataFechamento = null;
    this.horaFechamento = null;
    this.contato = '';
    this.solicitadoPor = 1;
    this.chamadoClassificacao = [];
  }
}

export class SistemaModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.descricao = '';
  }
}

export class DesenvolvimentoModel {
  constructor() {
    this.id = undefined;
    this.idCliente = undefined;
    this.idColaboradorResponsavel = undefined;
    this.idSistema = undefined;
    this.idColaboradorProgramador = undefined;
    this.cliente = new ClienteModel();
    this.colaboradorResponsavel = new ColaboradorModel();
    this.sistema = new SistemaModel();
    this.colaboradorProgramador = new ColaboradorModel();
    this.dataLancamento = new Date();
    this.dataPrevisao = null;
    this.dataInicioDesenv = null;
    this.dataConclusao = null;
    this.status = 0;
    this.descricaoSolicitacao = '';
    this.descricaoDesenvolvimento = '';
    this.historicoVersao = '';
    this.versaoLiberacao = '';
    this.prioridade = 2;
    this.descricaoResumida = '';
    this.testado = false;
    this.roteiroTestes = '';
  }
}

export class ClienteContatoEndereco {
  constructor() {
    this.id = undefined;
    this.idCliente = undefined;
    this.idMunicipio = undefined;
    this.nomeContato = '';
    this.cep = '';
    this.logradouro = '';
    this.numero = '';
    this.complemento = '';
    this.bairro = '';
    this.fone = '';
    this.celular = '';
    this.email = '';
  }
}

export class DesenvolvimentoArquivo {
  constructor() {
    this.id = undefined;
    this.idDesenvolvimento = undefined;
    this.nome = '';
    this.file = undefined;
    this.data = new Date();
  }
}

export class DesenvolvimentoTeste {
  constructor() {
    this.id = undefined;
    this.idDesenvolvimento = undefined;
    this.idColaborador = undefined;
    this.colaborador = new ColaboradorModel();
    this.data = new Date();
    this.situacao = 0;
    this.descricao = '';
  }
}

export class ClienteModel {
  constructor() {
    this.id = undefined;
    this.numeroContrato = null;
    this.nome = '';
    this.versaoSistema = null;
    this.numeroDispositivos = null;
    this.nomeFantasia = '';
    this.edicao = null;
    this.cpfCnpj = '';
    this.tipoPessoa = 'J';
    this.inscricaoEstadual = '';
    this.diaVencimento = null;
    this.percentualSalario = null;
    this.valorImplantacao = null;
    this.observacao = '';
    this.nomeContato = '';
    this.dataInicioContrato = null;
    this.dataFimContrato = null;
    this.inativo = false;
    this.idRevenda = null;
    this.valorSalarioBase = 0;
    this.valorMensal = 0;
    this.revenda = new RevendaModel();
    this.dataInclusao = new Date();

    this.chave = new ChaveModel();
    this.socios = [];
    this.modulos = [];

    this.clienteContatoEndereco = new ClienteContatoEndereco();
  }
}

export class ChaveVencimento {
  constructor() {
    this.numeroContrato = '';
    this.vencimento = undefined;
    this.edicao = '0';
    this.qtdDispositivos = 1;
    this.chaveLiberacao = '';
    this.modulos = {};
    this.justificativa = ''; // campo apenas para auditoria
  }
}

export class ChaveDispositivo {
  constructor() {
    this.chaveDispositivo = '';
    this.processador = '';
    this.numeroContrato = '';
    this.hd = '';
    this.contraChaveDispositivo = '';
    this.md5 = '';
    this.justificativa = ''; // campo apenas para auditoria
  }
}

export class ClienteModuloModel {
  constructor() {
    this.id = undefined;
    this.idCliente = null;
    this.idModulo = null;
    this.quantidade = 0;
  }
}

export class ClienteSocioModel {
  constructor() {
    this.id = undefined;
    this.idCliente = null;
    this.nome = '';
    this.cpf = '';
    this.email = '';
    this.celular1 = '';
    this.celular2 = '';
  }
}

export class ChaveModel {
  constructor() {
    this.id = undefined;
    this.numeroContrato = undefined;
    this.chaveLiberacao = '';
    this.vencimento = null;
  }
}

export class CampanhaModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.descricao = '';
    this.inativo = false;
  }
}

export class AcompanhamentoVendaModel {
  constructor() {
    this.id = undefined;
    this.idColaborador = undefined;
    this.idCampanha = undefined;
    this.idMunicipio = undefined;
    this.dataLancamento = new Date();
    this.dataContato = null;
    this.status = 0;
    this.pessoaContato = '';
    this.razaoSocial = '';
    this.dataProximoContato = new Date();
    this.email = '';
    this.logradouro = '';
    this.bairro = '';
    this.cnpj = '';
    this.cep = '';
    this.idRevenda = undefined;
    this.colaborador = undefined;
    this.revenda = undefined;
    this.acompanhamentoVendaHistorico = [];
    this.acompanhamentoVendaTelefone = [];
  }
}

export class AuditoriaModel {
  constructor() {
    this.id = undefined;
    this.idUsuario = null;
    this.dataRegistro = null;
    this.horaRegistro = null;
    this.acao = '';
    this.conteudo = '';
    this.pagina = '';
    this.usuario = new UsuarioModel();
  }
}

export class FuncaoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.nomeAgrupamento = '';
    this.descricao = '';
  }
}

export class UsuarioPerfilFuncaoModel {
  constructor() {
    this.id = undefined;
    this.idUsuarioPerfil = null;
    this.idFuncao = null;
    this.ativo = false;
    this.funcao = new FuncaoModel();
  }
}

export class UsuarioPerfilModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.usuarioPerfilDetalhe = [];
  }
}
