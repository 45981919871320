import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class ChaveService extends BaseService {
  constructor() {
    super(`${ConfigApi.chaveURL}`);
  }

  async sincronizaChave(body) {
    delete body.id;
    this.setLoading(true);
    try {
      const response = await this.api.post(`${this.url}/sincroniza`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async findByContrato(numeroContrato) {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/contrato/${numeroContrato}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.limit = 11;
    this.page = 0;
  }
}
export default new ChaveService();
