import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { geraFiltroHttp } from '../util/functions';

class DesenvolvimentoService extends BaseService {
  constructor() {
    super(`${ConfigApi.desenvolvimentoURL}`);
  }

  getFilter() {
    return new Filter();
  }

  async updateTeste(id, testado) {
    try {
      const response = await this.api.put(`${this.url}/${id}/testado`, { testado });
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async getReport(filter) {
    const url = filter.tipoRelatorio === 1 ? 'completo' : 'simples';
    this.setLoading(true);
    const filterGet = geraFiltroHttp(filter);
    try {
      const response = await this.api.get(`${this.url}/report/${url}${filterGet}`, {
        responseType: 'blob',
      });

      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async getHistoricoReport(idSistema) {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/historicoVersao/${idSistema}`, {
        responseType: 'blob',
      });

      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.descricaoResumida = '';
    this.id = 0;
    this.idSistema = undefined;
    this.idColaboradorResponsavel = undefined;
    this.idColaboradorProgramador = undefined;
    this.idCliente = undefined;
    this.dataInicio = addMonths(new Date(), -12);
    this.dataFim = new Date();
    this.listaStatusDesenvolvimento = '["0", "1", "2", "3"]';
    this.prioridade = '';

    this.limit = 11;
    this.page = 0;
  }
}
export default new DesenvolvimentoService();
