import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class AcompanhamentoVendaService extends BaseService {
  constructor() {
    super(`${ConfigApi.acompanhamentoVendaURL}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.idRevenda = undefined;
    this.idColaborador = undefined;
    this.idCliente = undefined;
    this.dataInicio = addMonths(new Date(), -12);
    this.dataFim = new Date();
    this.tipoPeriodo = 1; // 0-Data lançamento, 1-data próximo contrato, 2-data contato
    this.listaStatus = '["0", "1", "2"]';

    this.resumo = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new AcompanhamentoVendaService();
