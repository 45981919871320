import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi, filterType } from '../config/Constantes';
import { padLeft } from '../util/functions';

class ClienteService extends BaseService {
  constructor() {
    super(`${ConfigApi.clienteURL}`);
    this.lookupConfig = new LookupConfig();
    this.lookupConfig.serviceInstance = this;
  }

  getFilter() {
    return new Filter();
  }

  getLookupConfig(handleConfirm, handleCancel = undefined) {
    this.lookupConfig.handleConfirm = handleConfirm;
    this.lookupConfig.handleCancel = handleCancel;
    return this.lookupConfig;
  }

  async findByContrato(numeroContrato) {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/contrato/${numeroContrato}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.idRevenda = undefined;
    this.numeroContrato = 0;
    this.cpfCnpj = '';
    this.tipoInativo = 0;
    this.dataInicio = addMonths(new Date(), -12 * 10);
    this.dataFim = addMonths(new Date(), 3);

    this.resumo = '';
    this.limit = 11;
    this.page = 0;
  }
}

class LookupConfig {
  constructor() {
    this.serviceInstance = null;
    this.title = 'Localiza';
    this.defaultFilter = 'nome';
    this.handleConfirm = _record => {};
    this.filters = [
      {
        name: 'id',
        caption: 'Id',
        type: filterType.INTEGER,
      },
      {
        name: 'nome',
        caption: 'Nome',
        type: filterType.TEXT,
      },
      {
        name: 'Fantasia',
        caption: 'Fantasia',
        type: filterType.TEXT,
      },
    ];
    this.columns = [
      {
        name: 'id',
        caption: 'Id',
        handleFormatValue: val => {
          return padLeft(val, 6);
        },
        width: undefined,
        cssClassName: 'grid-col-id',
      },
      {
        name: 'nome',
        caption: 'Nome',
      },
      {
        name: 'nomeFantasia',
        caption: 'Fantasia',
      },
      {
        name: 'numeroContrato',
        caption: 'Contrato',
        width: 100,
      },
    ];
  }
}

export default new ClienteService();
