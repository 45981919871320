/* eslint-disable jsx-a11y/no-access-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import ChamadoService from '../../services/ChamadoService';
import { ChamadoModel } from '../../util/Models';

import {
  cloneObj,
  errorHandle,
  formatDate,
  isDate,
  strToDate,
  validateFields,
} from '../../util/functions';

import { StateScreen } from '../constants';
import DropdownSp from '../../components/DropdownSp';

import InputTextareaSp from '../../components/InputTextareaSp';

import DropDownLazy from '../../components/DropdownLazy';
import CalendarSp from '../../components/CalendarSp';
import AuthService from '../../services/AuthService';
import RevendaService from '../../services/RevendaService';
import ClienteService from '../../services/ClienteService';
import { store } from '../../store';
import { showLookup } from '../../store/modules/dialog/actions';

import ColaboradorService from '../../services/ColaboradorService';
import ChamadoTipoService from '../../services/ChamadoTipoService';
import { cfgPtBr } from '../../config/Constantes';
import ListBoxSp from '../../components/ListBoxSp';
import LookupClassificacaoAt from '../../components/LookupClassificacaoAt';
import AtendClassificacaoService from '../../services/AtendClassificacaoService';
import MessageInputText from '../../components/MessageInputText';

function ChamadoCrud(props) {
  ChamadoCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  ChamadoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };
  const txtContatoRef = useRef();
  const txtButtonRef = useRef();

  const { stateScreen, idSelected, onClose } = props;

  const revendaUsuario = AuthService.getUsuario().revenda
    ? {
        label: AuthService.getUsuario().revenda.nome,
        value: AuthService.getUsuario().revenda.id,
      }
    : {
        label: 'Speedy',
        value: 1,
      };

  const colaboradorUsuario = AuthService.getUsuario().colaborador
    ? {
        label: AuthService.getUsuario().colaborador.nome,
        value: AuthService.getUsuario().colaborador.id,
      }
    : null;

  // states
  const [chamado, setChamado] = useState(new ChamadoModel());
  const [revendaSelecionada, setRevendaSelecionada] = useState(revendaUsuario);
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [chamadoTipos, setChamadoTipos] = useState([]);

  const [colaboradorSelecionado, setColaboradorSelecionado] = useState();
  const [classificacaoSelecionada, setClassificacaoSelecionada] = useState();
  const [classificacoesSelecionadas, setClassificacoesSelecionadas] = useState([]);

  const [lookupTopicoVisible, setLookupTopicoVisible] = useState(false);
  const [messageVisibleAlterarDescricao, setMessageVisibleAlterarDescricao] = useState(false);

  // useCallbacks

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await ChamadoService.findById(_id);

      const revenda = retorno.revenda
        ? {
            label: retorno.revenda.nome,
            value: retorno.revenda.id,
          }
        : null;

      const colaborador = retorno.colaborador
        ? {
            label: retorno.colaborador.nome,
            value: retorno.colaborador.id,
          }
        : null;

      if (isDate(retorno.dataLancamento)) {
        retorno.horaLancamento = strToDate(
          `${retorno.dataLancamento} ${retorno.horaLancamento}`,
          'yyyy-MM-dd HH:mm:ss'
        );

        retorno.dataLancamento = strToDate(retorno.dataLancamento);
      }

      if (isDate(retorno.dataFechamento)) {
        retorno.horaFechamento = strToDate(
          `${retorno.dataFechamento} ${retorno.horaFechamento}`,
          'yyyy-MM-dd HH:mm:ss'
        );

        retorno.dataFechamento = strToDate(retorno.dataFechamento);
      }

      if (isDate(retorno.dataFimContrato)) {
        retorno.dataFimContrato = strToDate(retorno.dataFimContrato);
      }

      setRevendaSelecionada(revenda);
      setChamado(retorno);
      setColaboradorSelecionado(colaborador);

      // classificação
      const { chamadoClassificacao } = retorno;
      if (Array.isArray(chamadoClassificacao)) {
        const classificacoes = chamadoClassificacao.map(e => {
          return {
            id: e.idAtendClassificacao,
            descricao: e.descricao,
          };
        });
        setClassificacoesSelecionadas([...classificacoes]);
      }

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  const loadColaborador = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const lookupCliente = () => {
    const { dispatch } = store;
    const lookupConfig = ClienteService.getLookupConfig(
      objReturn => {
        const { id, nome } = objReturn;
        const cliente = {
          id,
          nome,
        };
        setChamado({ ...chamado, cliente, idCliente: cliente.id });
        if (
          txtContatoRef.current &&
          txtContatoRef.current.element &&
          txtContatoRef.current.element.focus
        ) {
          setTimeout(() => txtContatoRef.current.element.focus(), 200);
        }
      },
      () => {
        if (
          txtButtonRef.current &&
          txtButtonRef.current.element &&
          txtButtonRef.current.element.focus
        ) {
          setTimeout(() => txtButtonRef.current.element.focus(), 200);
        }
      }
    );

    dispatch(showLookup(lookupConfig));
  };

  const loadRevenda = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await RevendaService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadChamadoTipo = useCallback(async () => {
    const r = await ChamadoTipoService.findAll({ limit: 999 });

    const retorno = r.items.map(e => {
      return {
        label: e.nome,
        value: e,
      };
    });

    setChamadoTipos(retorno);
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function bloqueiaRevenda() {
    return AuthService.getUsuario().idRevenda && !AuthService.isPerfilGerente();
  }

  const salvaClassificacao = useCallback(
    reg => {
      if (classificacoesSelecionadas && classificacoesSelecionadas.length > 0) {
        const classificacaoExist = classificacoesSelecionadas.find(e => e.id === reg.id);

        if (classificacaoExist) {
          toast.warn('Classificação já existe');
          return;
        }
      }
      setClassificacoesSelecionadas([
        ...classificacoesSelecionadas,
        { descricao: reg.descricao, id: reg.id },
      ]);
    },
    [classificacoesSelecionadas]
  );

  const alteraDescricao = useCallback(async () => {
    if (classificacoesSelecionadas && classificacoesSelecionadas.length > 0) {
      const classificacaoExist = classificacoesSelecionadas.find(
        e => e.id === classificacaoSelecionada
      );

      if (!classificacaoExist) {
        toast.warn('Classificação não encontrada');
        return;
      }

      // verifica se a classificacao permite alterar descricao
      const ret = await AtendClassificacaoService.findById(classificacaoSelecionada);
      if (ret.permiteAlterarDescricao) {
        setMessageVisibleAlterarDescricao(true);
      } else {
        toast.warn('Classificação não permite alterar descricao');
      }
    }
    // setClassificacoesSelecionadas([...classificacoesSelecionadas]);
  }, [classificacaoSelecionada, classificacoesSelecionadas]);

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      const chamadoSalvar = cloneObj(chamado);
      if (chamadoSalvar.horaFechamento) {
        chamadoSalvar.horaFechamento = formatDate(chamadoSalvar.horaFechamento, 'HH:mm');
      }
      if (chamadoSalvar.horaLancamento) {
        chamadoSalvar.horaLancamento = formatDate(chamadoSalvar.horaLancamento, 'HH:mm');
      }

      chamadoSalvar.chamadoClassificacao = [];
      if (classificacoesSelecionadas.length > 0) {
        classificacoesSelecionadas.forEach(e => {
          const chamadoClassificacaoExists = (chamado.chamadoClassificacao || []).find(
            e1 => e1.idAtendClassificacao === e.id
          );
          chamadoSalvar.chamadoClassificacao.push({
            id: chamadoClassificacaoExists?.id || undefined,
            idChamado: idSelected || undefined,
            idAtendClassificacao: e.id,
            descricao: e.descricao,
          });
        });
      }
      if (stateScreen === StateScreen.stInsert) {
        retorno = await ChamadoService.insert(chamadoSalvar);
      } else {
        retorno = await ChamadoService.update(chamadoSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const newChamado = async () => {
        const chamadoTipoSuporte = await ChamadoTipoService.findById(1);
        const novo = new ChamadoModel();
        novo.idUsuario = AuthService.getUsuario().id;
        novo.usuario = AuthService.getUsuario();

        novo.idRevenda = revendaUsuario.value;
        novo.revenda = { id: revendaUsuario.value, nome: revendaUsuario.label };

        novo.chamadoTipo = chamadoTipoSuporte;
        novo.idChamadoTipo = chamadoTipoSuporte.id;
        novo.prazoDias = chamadoTipoSuporte.prazoDias;

        setRevendaSelecionada(revendaUsuario);

        setChamado(novo);
      };
      newChamado();
    }
    loadChamadoTipo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRecord, idSelected, stateScreen, loadChamadoTipo]);

  // render principal
  return (
    <>
      {lookupTopicoVisible ? (
        <LookupClassificacaoAt
          clickOnConfirm
          handleCancel={setLookupTopicoVisible}
          handleConfirm={ret => {
            salvaClassificacao(ret);
            setLookupTopicoVisible(false);
          }}
          lookupVisible={lookupTopicoVisible}
        />
      ) : null}
      {messageVisibleAlterarDescricao ? (
        <MessageInputText
          title="Informe a descrição"
          caption="Descrição"
          messageVisible={messageVisibleAlterarDescricao}
          handleOk={texto => {
            const classificacaoExist = classificacoesSelecionadas.find(
              e => e.id === classificacaoSelecionada
            );

            if (!classificacaoExist) {
              toast.warn('Classificação não encontrada');
              return;
            }
            classificacaoExist.descricao = texto;

            setClassificacoesSelecionadas([...classificacoesSelecionadas]);
            setMessageVisibleAlterarDescricao(false);
          }}
          handleClose={() => setMessageVisibleAlterarDescricao(false)}
        />
      ) : null}

      <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
        <LabelSp>Data</LabelSp>
        <CalendarSp
          showOnFocus={false}
          showIcon
          required
          locale={cfgPtBr}
          dateFormat="dd/mm/yy"
          disabled={viewMode()}
          value={chamado.dataLancamento}
          appendTo={window.body}
          onChange={e => {
            setChamado({
              ...chamado,
              dataLancamento: e.target.value,
            });
          }}
        />
      </div>
      <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
        <LabelSp>Hora</LabelSp>
        <CalendarSp
          showOnFocus={false}
          showIcon
          required
          timeOnly
          hourFormat="24"
          disabled={viewMode()}
          value={chamado.horaLancamento}
          appendTo={window.body}
          onChange={e => {
            setChamado({
              ...chamado,
              horaLancamento: e.target.value,
            });
          }}
        />
      </div>
      <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Usuário</LabelSp>
        <InputTextSp value={chamado.usuario?.login.toUpperCase() || ''} disabled />
      </div>
      <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Revenda</LabelSp>
        <DropDownLazy
          autoLoad
          placeholder="Selecione"
          required
          value={revendaSelecionada}
          onChange={e => {
            const revenda = e ? { id: e.value, nome: e.label } : null;
            setChamado({
              ...chamado,
              revenda,
              idRevenda: e?.value,
            });
          }}
          onFilter={async txtFilter => {
            const retorno = await loadRevenda(txtFilter);
            return retorno;
          }}
          disabled={viewMode() || bloqueiaRevenda()}
        />
      </div>
      <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Solicitado por</LabelSp>
        <DropdownSp
          options={[
            { value: 1, label: 'Cliente' },
            { value: 2, label: 'Revenda' },
            { value: 3, label: 'Contabilidade' },
            { value: 4, label: 'Consultor' },
            { value: 99, label: 'Outros' },
          ]}
          disabled={viewMode()}
          placeholder="Selecione"
          required
          value={chamado.solicitadoPor}
          onChange={e => {
            setChamado({
              ...chamado,
              solicitadoPor: e.target.value,
            });
          }}
        />
      </div>
      <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Tipo Chamado</LabelSp>
        <DropdownSp
          options={chamadoTipos}
          disabled={viewMode()}
          placeholder="Selecione"
          required
          value={chamado.chamadoTipo}
          onChange={e => {
            const { prazoDias } = e.target.value;
            setChamado({
              ...chamado,
              prazoDias,
              idChamadoTipo: e.target.value.id,
              chamadoTipo: e.target.value,
            });
          }}
        />
      </div>
      <div className="p-col-12 p-md-2 p-lg-2 p-fluid">
        <LabelSp>Prazo(dias)</LabelSp>
        <InputTextSp value={chamado.prazoDias} disabled />
      </div>
      <div className="p-col-12 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Status</LabelSp>
        <DropdownSp
          options={[
            { value: 0, label: 'Aberto' },
            { value: 1, label: 'Em Andamento' },
            { value: 2, label: 'Fechado' },
            { value: 3, label: 'Cancelado' },
          ]}
          disabled={viewMode()}
          placeholder="Selecione"
          required
          value={chamado.status}
          onChange={e => {
            let {
              horaFechamento,
              dataFechamento,
              observacaoAtendente,
              idColaborador,
              colaborador,
            } = chamado;

            if (e.target.value !== 2) {
              horaFechamento = null;
              dataFechamento = null;
              if (e.target.value === 0) {
                observacaoAtendente = '';
              }
            } else {
              if (!horaFechamento) {
                horaFechamento = new Date();
              }
              if (!dataFechamento) {
                dataFechamento = new Date();
              }
            }

            if (e.target.value === 0) {
              idColaborador = null;
              colaborador = null;
              setColaboradorSelecionado(null);
            } else if (colaboradorUsuario) {
              idColaborador = colaboradorUsuario.value;
              colaborador = { id: colaboradorUsuario.id, nome: colaboradorUsuario.nome };
              setColaboradorSelecionado(colaboradorUsuario);
            }

            setChamado({
              ...chamado,
              horaFechamento,
              dataFechamento,
              observacaoAtendente,
              idColaborador,
              colaborador,
              status: e.target.value,
            });
          }}
        />
      </div>
      <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
        <LabelSp>Fechamento</LabelSp>
        <CalendarSp
          showOnFocus={false}
          showIcon
          required
          locale={cfgPtBr}
          dateFormat="dd/mm/yy"
          disabled={viewMode() || chamado.status !== 2}
          value={chamado.dataFechamento}
          appendTo={window.body}
          onChange={e => {
            setChamado({
              ...chamado,
              dataFechamento: e.target.value,
            });
          }}
        />
      </div>
      <div className="p-col-6 p-md-2 p-lg-2 p-fluid">
        <LabelSp>Hora</LabelSp>
        <CalendarSp
          showOnFocus={false}
          showIcon
          required
          timeOnly
          hourFormat="24"
          disabled={viewMode() || chamado.status !== 2}
          value={chamado.horaFechamento}
          appendTo={window.body}
          onChange={e => {
            setChamado({
              ...chamado,
              horaFechamento: e.target.value,
            });
          }}
        />
      </div>
      <div className="p-col-6 p-md-3 p-lg-3 p-fluid">
        <LabelSp>Responsável</LabelSp>
        <DropDownLazy
          autoLoad
          required
          disabled={viewMode() || chamado.status === 0}
          placeholder="Selecione"
          onChange={e => {
            const colaborador = e ? { id: e.value, nome: e.label } : null;
            setColaboradorSelecionado(e);
            setChamado({ ...chamado, colaborador, idColaborador: e?.value });
          }}
          value={colaboradorSelecionado}
          onFilter={async txtFilter => {
            const retorno = await loadColaborador(txtFilter);
            return retorno;
          }}
        />
      </div>
      <div className="p-col-12 p-md-5 p-lg-5 p-fluid">
        <LabelSp>Cliente</LabelSp>
        <div className="p-inputgroup">
          <ButtonSp
            ref={txtButtonRef}
            autoFocus
            type="button"
            icon="pi pi-search"
            className="p-button-secondary buttons"
            title="Buscar Cliente"
            onClick={() => lookupCliente()}
            disabled={viewMode()}
          />

          <InputTextSp
            className={!viewMode() ? 'p-error' : ''}
            value={chamado.cliente?.nome || 'Selecione'}
            disabled
          />
        </div>
      </div>
      <div className="p-col-12 p-md-4 p-lg-4 p-fluid">
        <LabelSp>Contato</LabelSp>
        <InputTextSp
          ref={txtContatoRef}
          value={chamado.contato}
          disabled={viewMode()}
          maxLength={50}
          required
          onChange={e => {
            setChamado({ ...chamado, contato: e.target.value });
          }}
        />
      </div>
      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Classificação do Chamado</LabelSp>
        <div style={{ display: 'flex' }}>
          <ListBoxSp
            options={classificacoesSelecionadas}
            listStyle={{ maxHeight: '150px' }}
            required
            onChange={e => setClassificacaoSelecionada(e.value)}
            value={classificacaoSelecionada}
            optionLabel="descricao"
            optionValue="id"
            itemTemplate={option => {
              return (
                <div>
                  <div className="marcador" />
                  <span>{option.descricao}</span>
                </div>
              );
            }}
          />
          <div
            style={{
              marginLeft: 5,
              flex: 1,
            }}
          >
            <ButtonSp
              className="buttons"
              label=""
              icon="pi pi-plus-circle"
              title="Inserir"
              style={{ marginRight: 0 }}
              onClick={() => {
                setLookupTopicoVisible(true);
              }}
            />
            <ButtonSp
              className="buttons p-button-warning"
              label=""
              icon="pi pi pi-pencil"
              title="Inserir"
              style={{ marginTop: 5, marginRight: 0 }}
              disabled={!classificacaoSelecionada}
              onClick={() => {
                alteraDescricao();
              }}
            />
            <ButtonSp
              className="p-button-danger"
              label=""
              title="Excluir"
              icon="pi pi-trash"
              style={{ marginTop: 5, marginRight: 0 }}
              disabled={!classificacaoSelecionada}
              showConfirmation
              onClick={() => {
                const indexOf = classificacoesSelecionadas.findIndex(
                  e => e.id === classificacaoSelecionada
                );

                if (indexOf >= 0) {
                  classificacoesSelecionadas.splice(indexOf, 1);
                  setClassificacoesSelecionadas([...classificacoesSelecionadas]);
                  setClassificacaoSelecionada(undefined);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Descrição da Solicitação</LabelSp>
        <InputTextareaSp
          required
          autoResize={false}
          value={chamado.descricao}
          maxLength={2000}
          rows={3}
          resize={false}
          onChange={e => {
            setChamado({
              ...chamado,
              descricao: e.target.value,
            });
          }}
          disabled={viewMode()}
        />
      </div>
      <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
        <LabelSp>Observação do Atendente</LabelSp>
        <InputTextareaSp
          required={chamado.status === 2 || chamado.status === 3}
          autoResize={false}
          value={chamado.observacaoAtendente}
          maxLength={2000}
          rows={3}
          resize={false}
          onChange={e => {
            setChamado({
              ...chamado,
              observacaoAtendente: e.target.value,
            });
          }}
          disabled={viewMode() || chamado.status === 0}
        />
      </div>
      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            keyAccess="S"
            disabled={
              !validateFields(chamado, [
                'dataLancamento',
                'horaLancamento',
                'idRevenda',
                'idChamadoTipo',
                'descricao',
                'idCliente',
                chamado.status !== 0 ? 'idColaborador' : '',
                chamado.status === 2 ? 'horaFechamento' : '',
                chamado.status === 2 ? 'dataFechamento' : '',
                chamado.status === 2 || chamado.status === 3 ? 'observacaoAtendente' : '',
              ]) || classificacoesSelecionadas.length === 0
            }
            showConfirmation
            onClick={handleSave}
          />
        ) : null}
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          keyAccess="V"
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
}

export default ChamadoCrud;
