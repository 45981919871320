import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;

  .p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.129em 0.857em;
    /* padding: 0.429em 0.857em; */
  }
  .div-status {
    display: inline-block;
    width: 20px;
    border-radius: 3px;
  }

  .multiselected-item {
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 3px;
  }

  .marcador {
    background-color: #333333;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 5px;

    border-width: 1px;
    border-style: solid;
    display: inline-block;
    text-align: center;
  }
`;
