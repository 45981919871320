import styled from 'styled-components';

export const Container = styled.div`
  .titulo {
    height: 34px;
    padding-right: 3px !important;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
