export const StateScreen = {
  stInsert: 'INSER',
  stUpdate: 'UPDATE',
  stView: 'VIEW',
  stSearch: 'SEARCH',
};

export const PerfilUsuario = {
  administrador: 1,
  gerente: 2,
};
