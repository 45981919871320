/**
 * Serviço que gerencia a auditoria
 * @module Auditoria
 * @category Serviços
 */

import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import { geraFiltroHttp } from '../util/functions';

class AuditoriaService extends BaseService {
  constructor() {
    super(ConfigApi.auditoriaURL);
  }

  /**
   * Retorna o objeto filter específico do serviço
   * @returns {Filter}
   */
  getFilter() {
    return new Filter();
  }

  /**
   * Retorna a lista de todas as ações possíveis da auditoria
   * @async
   * @returns {Promise<any>} Lista contendo as ações
   */
  async findAllAcoes() {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/acoes`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Retorna a lista de todas as páginas possíveis da auditoria
   * @async
   * @returns {Promise<any>} Lista contendo as paginas
   */
  async findAllPaginas() {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}/paginas`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Busca da api um arquivo CSV contendo dados da auditoria
   * @async
   * @param {Filter} filter Filtro usado para selecionar dados
   * @returns {Promise<any>} Retorna dados em formato arraybuffer
   */
  async exportCSV(filter) {
    this.setLoading(true);
    try {
      const filterGet = geraFiltroHttp(filter);
      const response = await this.api.get(`${this.url}/export/csv${filterGet}`, {
        responseType: 'arraybuffer',
      });

      const path = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = path;
      link.setAttribute('download', 'auditoria.csv');
      document.body.appendChild(link);
      link.click();
    } finally {
      this.setLoading(false);
    }
  }
}

/**
 * Objeto filtro do serviço de auditoria
 * @class
 * @property {string} acao Ação realizada
 * @property {string} conteudo Conteúdo da auditoria
 * @property {Date} dataInicio Data inicio usado em período
 * @property {Date} dataFIm Data fim usado em período
 * @property {number} limit Tamanho máximo da página
 * @property {number} page Número da página
 */
class Filter {
  constructor() {
    this.acao = '';
    this.conteudo = '';
    this.pagina = '';

    this.dataInicio = addMonths(new Date(), -1);
    this.dataFim = new Date();
    this.limit = 0;
    this.page = 0;
  }
}
export default new AuditoriaService();
